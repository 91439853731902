import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import { CallbackFunction } from "../modules/common";
import { CureValueService } from "../../helpers/ServiceWrapper";
import { getUserInfoFromGoogle } from "../modules/auth";

interface LoginButtonProps {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: CallbackFunction<any>;
  onError: CallbackFunction<any>;
  isDisabled: boolean;
}
const LoginButton = ({
  onError,
  onSuccess,
  setIsLoading,
  isDisabled,
}: LoginButtonProps) => {
  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleSuccess(tokenResponse),
    onError: (data) => onError(data),
    onNonOAuthError: (error) => {
      onError(error);
    },
    //flow: "auth-code",
  });
  const handleSuccess = async (tokenResponse: TokenResponse) => {
    const { data, error } = await CureValueService(
      getUserInfoFromGoogle,
      tokenResponse
    );
    if (data) {
      onSuccess(data);
    } else {
      onError(error);
    }
  };
  return (
    <button
      disabled={isDisabled}
      onClick={() => {
        setIsLoading(true);
        login();
      }}
      className="text-lightblack rounded-full w-[100%] py-[8px] text-center flex items-center justify-center text-pfont gap-[8px] border border-gray-500"
    >
      <FcGoogle className="text-lightblack text-[18px] lg:text-[24px]" />
      Continue with Google
    </button>
  );
};
export default LoginButton;
