import { AxiosResponse } from "axios";
import { APIStatus, BaseResponse, CureValueError } from "../../common";
import { TreatmentContextModel } from "./_models";

const TREATMENT_CONTEXT_LOCAL_STORAGE_KEY = "cv-treatment-app";
const getTreatment = (): TreatmentContextModel | undefined => {
  if (!localStorage) {
    return;
  }
  const lsValue: string | null = localStorage.getItem(
    TREATMENT_CONTEXT_LOCAL_STORAGE_KEY
  );
  if (!lsValue) {
    return;
  }

  try {
    const auth: TreatmentContextModel = JSON.parse(
      lsValue
    ) as TreatmentContextModel;
    if (auth) {
      // You can easily check auth_token expiration also
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setTreatment = (data: TreatmentContextModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify(data);
    localStorage.setItem(TREATMENT_CONTEXT_LOCAL_STORAGE_KEY, lsValue);
  } catch (error) {
    console.error("TREATMENT CONTEXT SAVE ERROR", error);
  }
};

const removeTreatment = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(TREATMENT_CONTEXT_LOCAL_STORAGE_KEY);
  } catch (error) {
    console.error("TREATMENT CONTEXT REMOVE ERROR", error);
  }
};

export {
  getTreatment,
  setTreatment,
  TREATMENT_CONTEXT_LOCAL_STORAGE_KEY,
  removeTreatment,
};
