import React, { useEffect, useState } from "react";
import { IoClose, IoRestaurantSharp, IoSearch } from "react-icons/io5";
import cardimg from "../../../assets/images/destination-img1.png";
import cityImage from "../../../assets/images/city-default.png";
import { HiMiniInboxStack } from "react-icons/hi2";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { City } from "../../modules/city/core/_models";
import { FaBusAlt, FaCity, FaClock, FaLanguage, FaWifi } from "react-icons/fa";
import { TiWeatherSunny } from "react-icons/ti";
import { BsCashCoin } from "react-icons/bs";
import {
  MdLocalHotel,
  MdOutlineArrowOutward,
  MdOutlineLtePlusMobiledata,
  MdSavings,
} from "react-icons/md";
import { TbLicense } from "react-icons/tb";
import CVPopup from "../shared/CVPopup";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import {
  Treatment,
  getTreatmentByCityAndCountryName,
  useTreatment,
} from "../../modules/treatment";
import treatmentColourMap from "../../modules/common/core/treatmentColourMap.json";
import { useNavigate } from "react-router-dom";
import { getURLFromString } from "../../../helpers/CVHelper";
import { CITY_IMAGE_CDN } from "../shared/urlConstants";

interface CityPopupProps {
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  city?: City | null;
}

const CityPopup = ({ setIsOpenModal, city }: CityPopupProps) => {
  const [treatment, setTreatment] = useState<Treatment[]>([]);
  const { selectedTreatment, setSelectedTreatment } = useTreatment();
  const navigate = useNavigate();
  useEffect(() => {
    getTreatmentsForCity();
  }, []);
  const getTreatmentColour = (treatment: string) => {
    const colour = JSON.parse(JSON.stringify(treatmentColourMap))[treatment];

    return colour;
  };

  const toggleModal = () => {
    // e.preventDefault();
    setIsOpenModal(false);
  };
  const getTreatmentsForCity = async () => {
    if (city) {
      const { data, error } = await CureValueService(
        getTreatmentByCityAndCountryName,
        { city_name: city?.city_name, country_name: city?.country_name }
      );
      if (data) {
        setTreatment(data);
      }
    }
  };
  const getAffordableText = () => {
    if (city?.affordability_index) {
      const affordabilityIndex = parseFloat(city?.affordability_index);
      if (affordabilityIndex < 54) {
        return "Very affordable";
      } else if (affordabilityIndex < 86) {
        return "Affordable";
      } else if (affordabilityIndex < 118) {
        return "Moderate";
      } else if (affordabilityIndex < 151) {
        return "Expensive";
      }
    }
  };
  const getCurrentTimeZoneTime = () => {
    // let utcDate = new Date();
    // utcDate.setHours(0, 0, 0, 0);
    // let strDateLA = utcDate.toLocaleString("en-US", {
    //   timeZone: `${city?.country_name}/${city?.city_name}`,
    // });
    // return strDateLA;
    return city?.timezone;
  };
  const handleTreatmentClick = (treatment: Treatment) => {
    setSelectedTreatment({
      treatment: treatment.name,
      treatmentId: treatment._id,
      subTreatment: "",
      subTreatmentId: "",
    });
    if (city) {
      navigate(
        `/treatment/${getURLFromString(
          treatment.name
        )}/city-filter/${getURLFromString(city.city_name)}`
      );
    }
  };

  return (
    <CVPopup
      isClosable={true}
      onClose={() => {
        toggleModal();
      }}
    >
      <div className="flex flex-col lg:flex-row  gap-[30px] lg:gap-[80px] pb-[20px] lg:pb-[35px] items-start">
        <div className="flex-shrink-0">
          <img
            src={
              city?.image_name
                ? `${CITY_IMAGE_CDN + city.image_name}`
                : cityImage
            }
            alt=""
            //className="w-[80%] lg:w-[100%]"
            className="w-[400px] h-[250px] m-auto   rounded-[25px] object-cover"
          />
        </div>
        <div className="self-baseline flex-grow-1">
          <h2 className="text-h3font/[26px] lg:text-[38px]/[40px] text-sc font-semibold pb-[3px] w-[80%]">
            {city?.city_name},{" "}
            <span className="text-h4font lg:text-[29px] text-sc font-medium">
              {city?.country_name}
            </span>
          </h2>
          <div className="flex items-center gap-[10px] pb-[20px]">
            {/* <p className="text-h5font lg:text-[28px] font-bold  py-[2px] text-sc"> */}
            <p className="bg-[green] border-hoverclr border-1 border-solid text-white text-[15px] lg:text-h4font px-[15px] py-[5px] rounded-[50%]  w-[60px] h-[60px] font-semibold flex justify-center items-center">
              {city?.affordability_index &&
                Math.round(100 - parseFloat(city.affordability_index))}
              %
            </p>
            <span className=" text-h4font lg:text-h3font text-popupclr pl-2">
              Savings on average
            </span>
          </div>
          <div>
            Click below to view facilities here
            {/* <strong>
              {city?.city_name}, {city?.country_name}
            </strong> */}
          </div>
          <div className="flex flex-wrap items-start gap-[20px] pt-[11px] lg:pt-[9px] pb-[12px]">
            {treatment.map((treatment: Treatment) => {
              return (
                <button
                  className={`text-[10px] xl:text-anchortext font-medium px-[10px] py-[10px] cursor-pointer rounded-full border-pc flex items-center gap-[10px] ${getTreatmentColour(
                    treatment.name
                  )}`}
                  key={Math.random()}
                  onClick={() => handleTreatmentClick(treatment)}
                >
                  {treatment.name}
                  <IoSearch />
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <div className="flex flex-col">
        {city?.curevalue_overview && (
          // <FaCity />
          // <h3 className="text-[20px] font-semibold text-black mb-[22px]">
          //   About the City
          // </h3>
          // <p className="mb-[22px] text-anchortext lg:text-pfont">
          //   {city?.curevalue_overview}
          // </p>
          <div className="flex flex-col pb-5">
            <div className="flex items-center gap-[5px]">
              <FaCity className="text-[24px] text-cardpara" />
              <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                About the City
              </h6>
            </div>
            <p className="font-semibold text-popupclr pt-2 pr-2 overflow-y-auto  text-justify">
              {city?.curevalue_overview}
            </p>
          </div>
        )}
        <div className="flex flex-col pb-5">
          <div className="flex items-center gap-[5px]">
            <TiWeatherSunny className="text-[24px] text-cardpara" />
            <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
              Weather
            </h6>
          </div>
          <p className="font-semibold text-popupclr pt-2 pr-2 text-justify">
            {city?.average_weather}
          </p>
        </div>
        <div className="flex flex-col pb-5">
          <div className="flex items-center gap-[5px]">
            <MdLocalHotel className="text-[24px] text-cardpara" />
            <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
              Accommodations
            </h6>
          </div>

          <p className="font-semibold text-popupclr pt-2 pr-2  text-justify">
            {city?.accommodations}
          </p>
        </div>
        <div className="flex flex-col pb-5">
          <div className="flex items-center gap-[5px]">
            <IoRestaurantSharp className="text-[24px] text-cardpara" />
            <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
              Cuisine
            </h6>
          </div>

          <p className="font-semibold text-popupclr pt-2 pr-2 text-justify">
            {city?.cuisine}
          </p>
        </div>
        <div className="flex gap-[20px] mb-[20px] flex-wrap">
          <div className="flex flex-col w-[30%]">
            <div className="flex items-center gap-[5px]">
              <TbLicense className="text-[24px] text-cardpara" />
              <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                Visas
              </h6>
            </div>

            <p className="font-semibold text-popupclr">
              {city?.visa_required == "1" ? "Visa Required" : "Visa Free"}
            </p>
          </div>
          <div className="flex flex-col w-[30%]">
            <div className="flex items-center gap-[5px]">
              <MdSavings className="text-[24px] text-cardpara" />
              <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                Affordability
              </h6>
            </div>
            <p className="font-semibold text-popupclr">
              {" "}
              {getAffordableText()}
            </p>
          </div>
          <div className="flex flex-col w-[30%]">
            <div className="flex items-center gap-[5px]">
              <FaClock className="text-[24px] text-cardpara" />
              <h6 className="text-anchortext lg:text-h3font font-semibold text-cardpara">
                Time zone
              </h6>
            </div>
            <p className="font-semibold text-popupclr">
              {getCurrentTimeZoneTime()}
            </p>
          </div>

          <div className="flex flex-col w-[30%]">
            <div className="flex items-center gap-[5px]">
              <FaLanguage className="text-[24px] text-cardpara" />
              <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                Language
              </h6>
            </div>
            <p className="font-semibold text-popupclr">English, Spanish</p>
          </div>

          <div className="flex flex-col w-[30%]">
            <div className="flex items-center gap-[5px]">
              <FaWifi className="text-[24px] text-cardpara" />
              <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                Internet
              </h6>
            </div>
            <p className="font-semibold text-popupclr">Available</p>
          </div>
          <div className="flex flex-col w-[30%]">
            <div className="flex items-center gap-[5px]">
              <BsCashCoin className="text-[24px] text-cardpara" />
              <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                Currency
              </h6>
            </div>
            <p className="font-semibold text-popupclr">{city?.exchange_rate}</p>
          </div>
        </div>

        {/* <div className="flex flex-col w-[200px]">
                  <div className="flex items-center gap-[5px]">
                    <TiWeatherSunny className="text-[24px] text-cardpara" />
                    <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                      Weather
                    </h6>
                  </div>
                  <p className="font-semibold text-popupclr">Sunny 33° C</p>
                </div> */}
        {/* <div className="grid grid-cols-2 lg:grid-cols-4 items-start gap-[20px] lg:gap-[100px] pb-[20px] lg:pb-[46px]">
                <div>
                  <div className="flex items-center gap-[5px]">
                    <HiMiniInboxStack className="text-[24px] text-cardpara" />
                    <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                      Visas
                    </h6>
                  </div>
                  <p className="font-semibold text-popupclr">
                    {" "}
                    {city?.visa_required == "1" ? "Visa Required" : "Visa Free"}
                  </p>
                </div>
                <div>
                  <div className="flex items-center gap-[5px]">
                    <HiMiniInboxStack className="text-[24px] text-cardpara" />
                    <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                      Affordability
                    </h6>
                  </div>
                  <p className="font-semibold text-popupclr">Very affordable</p>
                </div>
                <div>
                  <div className="flex items-center gap-[5px]">
                    <HiMiniInboxStack className="text-[24px] text-cardpara" />
                    <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                      Transport
                    </h6>
                  </div>
                  <p className="font-semibold text-popupclr">Uber, Bus</p>
                </div>
                <div>
                  <div className="flex items-center gap-[5px]">
                    <HiMiniInboxStack className="text-[24px] text-cardpara" />
                    <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                      Weather
                    </h6>
                  </div>
                  <p className="font-semibold text-popupclr">Sunny 33° C</p>
                </div>
              </div>

              <div className="grid grid-cols-2 lg:grid-cols-4 items-start gap-[20px] lg:gap-[100px] pb-[20px] lg:pb-[46px]">
                <div>
                  <div className="flex items-center gap-[5px]">
                    <HiMiniInboxStack className="text-[24px] text-cardpara" />
                    <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                      Language
                    </h6>
                    <IoMdInformationCircleOutline className="text-blueclr2 text-pfont" />
                  </div>
                  <p className="font-semibold text-popupclr">
                    English, Spanish
                  </p>
                </div>
                <div>
                  <div className="flex items-center gap-[5px]">
                    <HiMiniInboxStack className="text-[24px] text-cardpara" />
                    <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                      Internet
                    </h6>
                    <IoMdInformationCircleOutline className="text-blueclr2 text-pfont" />
                  </div>
                  <p className="font-semibold text-popupclr">Available</p>
                </div>
                <div>
                  <div className="flex items-center gap-[5px]">
                    <HiMiniInboxStack className="text-[24px] text-cardpara" />
                    <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                      Currency
                    </h6>
                    <IoMdInformationCircleOutline className="text-blueclr2 text-pfont" />
                  </div>
                  <p className="font-semibold text-popupclr">Uber, Bus</p>
                </div>
              </div> */}
        {/* <div className="grid lg:grid-cols-2 items-start gap-[20px] lg:gap-[100px]">
                <div>
                  <div className="flex items-center gap-[5px]">
                    <HiMiniInboxStack className="text-[24px] text-cardpara" />
                    <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                      Accommodations
                    </h6>
                  </div>
                  <p className="font-semibold text-popupclr">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua
                  </p>
                </div>
                <div>
                  <div className="flex items-center gap-[5px]">
                    <HiMiniInboxStack className="text-[24px] text-cardpara" />
                    <h6 className="capitalize text-anchortext lg:text-h3font font-semibold text-cardpara">
                      Cuisine
                    </h6>
                  </div>
                  <p className="font-semibold text-popupclr">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua
                  </p>
                </div>
              </div> */}
      </div>
    </CVPopup>
  );
};

export default CityPopup;
