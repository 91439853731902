import { useEffect, useMemo, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { INotification, useCvNotiication } from "./eventEmmiter";
import { useIntl } from "react-intl";

const MessageContainer = () => {
  const [notification, setNotification] = useState<INotification>();
  const intl = useIntl();

  useEffect(() => {}, []);
  useCvNotiication((e: INotification) => {
    setNotification(e);
    setTimeout(() => {
      setNotification(undefined);
    }, 5000);
  });

  return (
    <>
      <div
        className={
          "flex w-full fixed z-40 h-[40px] items-center justify-between text-white font-bold px-[24px] drop-shadow-lg transition-visibility duration-100 " +
          (notification?.type == "WARNING"
            ? "bg-hoverclr"
            : notification?.type == "ERROR"
            ? "bg-danger"
            : notification?.type == "INFO"
            ? "bg-pc"
            : "") +
          (notification ? " visible" : " invisible")
        }
      >
        <span className="">
          {/* {intl.formatMessage({
              id: notification.message,
              defaultMessage: "Application Error",
            })} */}
          {notification?.message}
        </span>
        <span
          className="text-3xl cursor-pointer"
          onClick={() => {
            setNotification(undefined);
          }}
        >
          <IoIosClose />
        </span>
      </div>
    </>
  );
};
export default MessageContainer;
