import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import { Treatment, TreatmentContextModel } from "./_models";
import * as treatmentHelper from "./TreatmentHelpers";
import { CureValueService } from "../../../../helpers/ServiceWrapper";
import { getAllTreatments } from "./treatmentService";

type treatmentContextProps = {
  selectedTreatment: TreatmentContextModel | undefined;
  setSelectedTreatment: (auth: TreatmentContextModel | undefined) => void;
  treatments: Treatment[];
};

const initTreatmentContextPropsState: treatmentContextProps = {
  selectedTreatment: treatmentHelper.getTreatment(),
  setSelectedTreatment: () => {},
  treatments: [],
};

const TreatmentContext = createContext<treatmentContextProps>(
  initTreatmentContextPropsState
);

const useTreatment = () => {
  return useContext(TreatmentContext);
};

const TreatmentProvider = ({ children }: any) => {
  const [selectedTreatment, setSelectedTreatment] = useState<
    TreatmentContextModel | undefined
  >(treatmentHelper.getTreatment());
  const [treatments, setTreatments] = useState<Treatment[]>([]);
  useEffect(() => {
    getAllTreatment();
  }, []);

  const saveTreatment = (treatment: TreatmentContextModel | undefined) => {
    const prevTreatment = treatmentHelper.getTreatment();
    const curSelectedTreatment = treatment;

    if (prevTreatment?.treatmentId != curSelectedTreatment?.treatmentId) {
      console.log("selected treatment is different than previous");
      for (const key in localStorage) {
        if (key.startsWith("selectedCities_")) {
          localStorage.removeItem(key);
        }
      }
    }
    if (treatment) {
      treatmentHelper.setTreatment(treatment);
      setSelectedTreatment(treatment);
    } else {
      treatmentHelper.removeTreatment();
    }
  };
  const getAllTreatment = async () => {
    const { data, error } = await CureValueService(getAllTreatments, null);
    if (data) {
      setTreatments(data);
    }
  };

  return (
    <TreatmentContext.Provider
      value={{
        selectedTreatment,
        setSelectedTreatment: saveTreatment,
        treatments,
      }}
    >
      {children}
    </TreatmentContext.Provider>
  );
};

const TreatmentInit = ({ children }: any) => {
  const { selectedTreatment, setSelectedTreatment } = useTreatment();

  useEffect(() => {}, []);

  // const userData = useAuth()
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    setSelectedTreatment(treatmentHelper.getTreatment());
  }, []);

  return <>{children}</>;
};

export { TreatmentProvider, TreatmentInit, useTreatment };
