import { ReactNode } from "react";

export enum APIStatus {
  SUCCESS = "SUCESS",
  FAILED = "FAILED",
}
export interface BaseResponse<T> {
  status: APIStatus;
  data: T;
  errors: string[];
}
export interface ListResponse<T> {
  items: T[];
  totalCount: number;
}
export class CureValueError extends Error {
  errorMessages: string[] = [];
  constructor(err: string[] | string) {
    super();
    if (Array.isArray(err)) {
      this.errorMessages = err;
    } else {
      this.errorMessages.push(err);
    }
  }
}
export type CVRegions =
  | "US &Canada"
  | "Latin America and Caribbean"
  | "Middle East"
  | "Europe"
  | "South East Asia"
  | undefined;
export type CallbackFunction<Data> = (data: Data) => void;
export interface CMSContentProps {
  contentId: string;
}
export interface ContentProps<T> {
  content: T;
}
export type CVPlans = "BASIC" | "GOLD" | "PLATINUM";
export type CVPlanBillingDutation = "MONTHLY" | "YEARLY";
