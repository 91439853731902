import axios from "axios";
import { BaseResponse } from "../../common";
import {
  API_REQUEST_CALLBACK,
  API_USER_AUTH_URL,
  API_USER_URL,
} from "../../../components/shared/urlConstants";

export function registerUser(payload: {}) {
  return axios
    .post<any, BaseResponse<[]>>(`${API_USER_AUTH_URL}register`, payload)
    .then((response) => {
      return response.data;
    });
}

export function CheckEmailExists(payload: {}) {
  return axios
    .post<any, BaseResponse<[]>>(
      `${API_USER_AUTH_URL}checkEmailExists`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}

export function verifyUserEmail(verificationToken: {}) {
  return axios
    .get<any, BaseResponse<[]>>(`${API_USER_URL}verify`, {
      params: { token: verificationToken },
    })
    .then((response) => {
      return response.data;
    });
}
export const getUserByToken = (token: string) => {
  const headers = {
    ctk: `${token}`,
  };
  return axios
    .get<any, BaseResponse<any>>(`${API_USER_AUTH_URL}getUserByToken`, {
      headers,
    })
    .then((response) => {
      return response.data;
    });
};
export function loginUser(payload: {}) {
  return axios
    .post<any, BaseResponse<any>>(
      `${process.env.REACT_APP_BACKEND_BASEURL}/user/auth/login`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}
export const postSocialMediaData = (payload: any) => {
  return axios
    .post<any, BaseResponse<any>>(`${API_USER_AUTH_URL}socialMedia`, payload)
    .then((response) => {
      return response.data;
    });
};
export const getUserInfoFromGoogle = (tokenResponse: any) => {
  return axios
    .get("https://www.googleapis.com/oauth2/v3/userinfo", {
      headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
    })
    .then((res) => res);
};
export const forgetPasswordInit = (payload: any) => {
  return axios
    .post<any, BaseResponse<any>>(`${API_USER_AUTH_URL}forgetPassword`, payload)
    .then((response) => {
      return response.data;
    });
};
export function setpassword(payload: { authToken: string; password: string }) {
  return axios
    .post<any, BaseResponse<[]>>(`${API_USER_URL}setpassword`, payload)
    .then((response) => {
      return response.data;
    });
}
export function requestCallback(payload: any) {
  return axios
    .post<any, BaseResponse<[]>>(`${API_REQUEST_CALLBACK}create`, payload)
    .then((response) => {
      return response.data;
    });
}
