import { FC, useEffect } from "react";
import { WithChildren } from "../../../helpers";
import { IoClose } from "react-icons/io5";

export interface CVPopupProps extends WithChildren {
  onClose?: () => void;
  isClosable?: boolean;
  size?: "sm" | "lg" | "xl";
}
const CVPopup: FC<CVPopupProps> = ({ children, onClose, isClosable }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);
  return (
    <div className="z-[200] fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 ">
      <div className=" bg-white rounded-[24px] p-[20px] lg:py-[50px] lg:pl-[50px] w-[95%] lg:w-[80%]  relative">
        {isClosable != undefined && isClosable == true && (
          <div className="absolute right-0 top-0 lg:right-[5%] lg:top-[5%]">
            <span
              className="close cursor-pointer"
              onClick={() => {
                if (onClose) {
                  onClose();
                }
              }}
            >
              <IoClose size={"40px"} className="text-black" />
            </span>
          </div>
        )}

        <div className="max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
};
export default CVPopup;
