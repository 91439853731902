import { toast } from "react-toastify";

import React from "react";
import { CureValueError } from "../app/modules/common";
import { useEventEmmiter } from "../app/components/shared/eventEmmiter";

export type ServiceFunction<Req = unknown, Res = unknown> = (
  request: Req
) => Promise<Res>;
export type CallbackFunction<Data> = (data: Data) => void;
export interface CureValueServiceConfig<T> {
  handleError?: boolean;
  onError?: CallbackFunction<CureValueError>;
  onSuccess?: CallbackFunction<T>;
  setData?: React.Dispatch<React.SetStateAction<T | null>>;
  setError?: React.Dispatch<React.SetStateAction<CureValueError | null>>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsError?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsSuccess?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CureValueService = async <Entity, Req>(
  func: ServiceFunction<Req, Entity>,
  request: Req,
  config: CureValueServiceConfig<Entity> = {}
): Promise<{ data?: Entity; error?: CureValueError }> => {
  config.setIsLoading && config.setIsLoading(true);
  let data = await func(request)
    .then((response: Entity) => {
      config.onSuccess && config.onSuccess(response);
      config.setData && config.setData(response);
      config.setIsError && config.setIsError(false);
      config.setIsSuccess && config.setIsSuccess(true);
      return response;
    })
    .catch((error: CureValueError) => {
      config.onError && config.onError(error);
      config.setError && config.setError(error);
      config.setIsError && config.setIsError(true);
      config.setIsSuccess && config.setIsSuccess(false);
      return ErrorHandler(
        error,
        config.handleError !== undefined ? config.handleError : true
      );
    });
  config.setIsLoading && config.setIsLoading(false);
  if (data instanceof CureValueError) {
    return { error: data };
  }
  return { data };
};

const ErrorHandler = (err: CureValueError, handleError = true) => {
  const eventEmmiter = useEventEmmiter();
  if (handleError) {
    err.errorMessages.forEach((element) => {
      eventEmmiter.cvNotify({ message: element, type: "ERROR" });
    });
  }
  return err;
};
