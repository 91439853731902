import { FaBookmark, FaCopy, FaFacebookSquare } from "react-icons/fa";
import { BsShareFill } from "react-icons/bs";
import { MouseEvent, useEffect, useState } from "react";
import { useEventEmmiter } from "../shared/eventEmmiter";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { useAuth } from "../../modules/auth";
import { AddRemoveFavoriteRequest } from "../../modules/favorite/core/_models";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import {
  addToFavorite,
  removeFromFavorite,
} from "../../modules/favorite/core/FavoriteService";
import CVPopup from "../shared/CVPopup";
import { IoClose, IoLogoWhatsapp } from "react-icons/io5";
import { FiShare } from "react-icons/fi";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { useSearchParams } from "react-router-dom";
import { PiMedalFill } from "react-icons/pi";
import { MdEmail } from "react-icons/md";

const FacilityTitle = ({
  facility,
  selectedTreatment,
  setFacility,
  handleShowSignUpPopup,
}: any) => {
  const { currentUser } = useAuth();
  const [isFixed, setIsFixed] = useState(false);
  const [showSignuptooltip, setShowSignuptooltip] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [shareUrl, setShareUrl] = useState<any>(null);
  const eventEmmiter = useEventEmmiter();
  const [searchParams] = useSearchParams();
  const isClaimPage = searchParams.get("isclaim");

  useEffect(() => {
    const textToCopy =
      process.env.REACT_APP_BASEURL +
      "/location/" +
      facility.country_name +
      "/" +
      facility.city_name +
      "/" +
      facility.facility_name +
      "?service=" +
      selectedTreatment;

    setShareUrl(textToCopy);
  }, [selectedTreatment]);

  const favoriteClickHandle = (e: any) => {
    e.stopPropagation();
    if (currentUser) {
      changeFavorite();
    } else {
      // setShowSignuptooltip(true);
      handleShowSignUpPopup();
    }
  };
  const handleClaimClick = (e: MouseEvent) => {
    //https://www.cognitoforms.com/FarooqZama/FacilityIntakeForm?entry={facilityName:%22xfsd%22}
    // window.open(
    //   `https://www.cognitoforms.com/FarooqZama/FacilityIntakeForm?entry={facilityName:"${facility.facility_name}"}`
    // );

    window.open(
      `https://www.cognitoforms.com/CureValue1/FacilityIntakeForm?entry={facilityName:"${facility.facility_name}"}`
    );
  };

  const changeFavorite = async () => {
    let payload: AddRemoveFavoriteRequest = {
      favourite_type: "facility",
      facility_id: facility?._id,
    };
    if (
      facility?.is_favourite == false ||
      facility?.is_favourite == undefined
    ) {
      const { data, error } = await CureValueService(addToFavorite, payload);
      if (data) {
        if (facility) {
          setFacility({ ...facility, is_favourite: true });
        }
      }
    } else {
      const { data, error } = await CureValueService(
        removeFromFavorite,
        payload
      );
      if (data) {
        if (facility) {
          setFacility({ ...facility, is_favourite: false });
        }
      }
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const FacilityElement = document.getElementById("facility-title");
      if (!FacilityElement) return; // Null check
      const facilityOffset = FacilityElement.offsetTop;
      const scrollTop = window.pageYOffset;

      setIsFixed(scrollTop > facilityOffset);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleShareButton = () => {
    const textToCopy =
      process.env.REACT_APP_BASEURL +
      "/location/" +
      facility.country_name +
      "/" +
      facility.city_name +
      "/" +
      facility.facility_name +
      "?service=" +
      selectedTreatment;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        eventEmmiter.cvNotify({
          message: "Link copied to clipboard successfully",
          type: "INFO",
        });
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const toggleSharePopup = (e: any) => {
    e.preventDefault();
    setIsOpenModal(!isOpenModal);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        eventEmmiter.cvNotify({
          message: "Link copied to clipboard successfully",
          type: "INFO",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <div
      id="facility-title"
      className={`w-[100%] h-[140px] items-center transition-all z-10 ${
        isFixed
          ? "fixed top-0 bg-[#fcfcfc] shadow-sm lg:shadow-0 lg:bg-pc ease-in-out duration-300"
          : "lg:bg-white"
      }`}
    >
      <div className="container flex h-[100%] items-center py-[17px]  justify-between">
        <div className="lg:max-w-[80%] lg:min-w-[70%] grow">
          <h1
            className={`text-[20px] lg:text-[35px]  font-bold ${
              isFixed
                ? "text-pc lg:text-white ease-in-out duration-300"
                : "text-pc lg:text-iconclr"
            }`}
          >
            {`${facility.facility_name} - ${facility.city_name}, ${facility.country_name}`}

            {/* {facility.facility_name} {" - "} {facility.city_name}
            {", "}
            {facility.country_name} */}
            {/* <span className="font-medium"> {facility.facility_location}</span> */}
          </h1>
        </div>
        <div className="hidden lg:flex items-center gap-[22px]  flex-wrap justify-center ">
          {isClaimPage && (
            <button
              onClick={handleClaimClick}
              className={`w-full btn border rounded-full font-anchor font-anchortext lg:py-[5px] px-[27px]  hover:bg-white hover:text-pc flex items-center flex-grow text-center justify-center ${
                isFixed
                  ? `bg-white border-white text-pc`
                  : ` border-pc bg-pc text-white`
              }`}
            >
              Claim this page
            </button>
          )}
          <div
            className="flex items-center gap-[5px] cursor-pointer"
            onClick={(e) => {
              toggleSharePopup(e);
            }}
          >
            <FiShare
              size={"40px"}
              className={`cursor-pointer ${
                isFixed ? "text-white ease-in-out duration-300" : ""
              }`}
            />
            {/* <p className="text-white lg:font-bold">Share</p> */}
            <h2
              className={`text-h3font font-semibold lg:text-[15px] lg:font-bold ${
                isFixed ? "text-white" : "text-sc"
              }`}
            >
              Share
            </h2>
          </div>

          <div
            className="flex items-center gap-[5px] cursor-pointer"
            onClick={(e) => favoriteClickHandle(e)}
          >
            <div className="text-hoverclr cursor-pointer">
              {facility?.is_favourite == false ||
              facility?.is_favourite == undefined ? (
                <IoMdHeartEmpty size={"40px"} />
              ) : (
                <IoMdHeart size={"40px"} />
              )}
            </div>

            <h2
              className={`text-h3font font-semibold lg:text-[15px] lg:font-bold ${
                isFixed ? "text-white" : "text-sc"
              }`}
            >
              Favorite
            </h2>
          </div>

          {/* <IoMdHeartEmpty
            size={"30px"}
            className={`cursor-pointer ${
              isFixed ? "text-white ease-in-out duration-300" : ""
            }`}
          /> */}
        </div>
      </div>

      {isOpenModal && (
        <div
          className="z-[200] fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50"
          onClick={(e) => {
            toggleSharePopup(e);
          }}
        >
          <div className="bg-white rounded-[24px] p-[50px] lg:py-[50px] lg:pl-[50px] w-[95%] lg:w-[30%] relative">
            <div className="absolute right-0 top-0 lg:right-[5%] lg:top-[5%]">
              <span
                className="close cursor-pointer"
                onClick={(e) => {
                  toggleSharePopup(e);
                }}
              >
                <IoClose size={"30px"} className="text-black" />
              </span>
            </div>

            <div className="max-h-[80vh] lg:max-h-[70vh] overflow-y-auto flex flex-col">
              <div className="lg:max-w-[100%] modal-hospital">
                <h6 className="capitalize text-[20px] font-semibold text-cardpara pt-[5px] pb-[25px]">
                  Share
                </h6>
                <div className="grid lg:grid-cols-2 items-start gap-[20px] lg:gap-[40px]">
                  <div
                    className="flex items-center justify-center gap-[10px] border border-pc rounded-[10px] cursor-pointer"
                    onClick={copyToClipboard}
                  >
                    <FaCopy size={20} className="text-pc" />
                    <h6 className="capitalize text-anchortext font-semibold text-cardpara  text-center pt-[10px] pb-[10px]">
                      Copy Link
                    </h6>
                  </div>
                  <EmailShareButton
                    url={shareUrl}
                    subject={facility.facility_name}
                  >
                    <div className="flex items-center justify-center gap-[10px] border border-pc rounded-[10px] cursor-pointer">
                      <MdEmail size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[10px] pb-[10px]">
                        Email
                      </h6>
                    </div>
                  </EmailShareButton>
                  <FacebookShareButton url={shareUrl}>
                    <div className="flex items-center justify-center gap-[10px] border border-pc rounded-[10px] cursor-pointer">
                      <FaFacebookSquare size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[10px] pb-[10px]">
                        Facebook
                      </h6>
                    </div>
                  </FacebookShareButton>
                  <WhatsappShareButton
                    url={shareUrl}
                    title={facility.facility_name}
                  >
                    <div className="flex items-center justify-center gap-[10px] border border-pc rounded-[10px] cursor-pointer">
                      <IoLogoWhatsapp size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[10px] pb-[10px]">
                        WhatsApp
                      </h6>
                    </div>
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FacilityTitle;
