import { Treatment } from "../app/modules/treatment";

export const getRandomAlphaString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const getRandomDigitString = (length: number) => {
  let result = "";
  const digits = "0123456789";
  const digitsLength = digits.length;
  let counter = 0;
  while (counter < length) {
    result += digits.charAt(Math.floor(Math.random() * digitsLength));
    counter += 1;
  }
  return result;
};
export const constructURLSegment=(value:string)=>{
  return encodeURI((value.toLocaleLowerCase().replaceAll(" ","-")).replaceAll("/","-or-")).replaceAll("&","%26")
}
export const deConstructURLSegment=(value:string)=>{
  return decodeURI((value.replaceAll("-or-","/")).replaceAll("-"," "))
}

export const getRandomCanvasImageId = () => {
  return getRandomAlphaString(5);
};
export const getRandomComponentRefID = () => {
  return getRandomDigitString(4);
};
export const getURLFromString = (value: string) => {
  return encodeURI((value.toLocaleLowerCase().replaceAll(" ","-")).replaceAll("/","-or-")).replaceAll("&","%26")
};
export const getTreatmentFromCMSTreatment=(treatmentName: string,treatments:Treatment[])=>{
  var treatment: Treatment[] = [];
  if (
    treatmentName != "Dental" &&
    treatmentName != "Orthopedics" &&
    treatmentName != "Fertility" &&
    treatmentName != "Bariatrics" &&
    treatmentName != "Dermatology" &&
    treatmentName != "Opthalmology" &&
    treatmentName != "Oncology" &&
    treatmentName != "Neurosurgery"
  ) {
    treatment = treatments.filter(
      (treatment) => treatment.name == treatmentName
    );
  } else if (treatmentName == "Dental") {
    treatment = treatments.filter(
      (treatment) => treatment.name == "Dentistry"
    );
  } else if (treatmentName == "Orthopedics") {
    treatment = treatments.filter(
      (treatment) => treatment.name == "Orthopedic/Knee Surgery"
    );
  } else if (treatmentName == "Fertility") {
    treatment = treatments.filter(
      (treatment) => treatment.name == "Fertility Treatment"
    );
  } else if (treatmentName == "Bariatrics") {
    treatment = treatments.filter(
      (treatment) => treatment.name == "Bariatric Surgery"
    );
  } else if (treatmentName == "Dermatology") {
    treatment = treatments.filter(
      (treatment) => treatment.name == "Skin Care"
    );
  } else if (treatmentName == "Opthalmology") {
    treatment = treatments.filter(
      (treatment) => treatment.name == "Eye Care"
    );
  } else if (treatmentName == "Oncology") {
    treatment = treatments.filter(
      (treatment) => treatment.name == "Cancer Treatment"
    );
  } else if (treatmentName == "Neurosurgery") {
    treatment = treatments.filter(
      (treatment) => treatment.name == "Neurology"
    );
  }
  return treatment

}
