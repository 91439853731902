import React from "react";

const TermsOfService = () => {
  return (
    <div className="relative overflow-hidden bg-[#F5F7FA] pt-[70px] lg:pt-[80px]">
      Terms and service Comming soon
    </div>
  );
};

export default TermsOfService;
