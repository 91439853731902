import { useRef, useState } from "react";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import contentIdMap from "../../assets/content/contentful.json";
import Banner from "../components/Banner";
import BuildingCommunity from "../components/BuildingCommunity";
import PopularTreatments from "../components/Cards/PopularTreatments";
import ClientLogos from "../components/ClientLogos";
import CustomerStories from "../components/CustomerStories";
import CityCarousel from "../components/city/CityCarousel";
import FacilityCarousel from "../components/facility/FacilityCarousel";
import TreatmentSearchPopup from "../components/shared/TreatmentSearchPopup";
import CityCompareCard from "../components/city/CityCompareCard";
import FacilityCompareCard from "../components/facility/FacilityCompareCard";
import CompareSlider from "../components/shared/CompareSlider";
import CVPopup from "../components/shared/CVPopup";
import SignUpPopup from "../components/auth/SignUpPopup";
import { Helmet } from "react-helmet";

const Homepage = () => {
  const topRatedDestinationsContentId =
    contentIdMap.homePage.topRatedDestinations;
  const topRatedFacilitiesContentId = contentIdMap.homePage.topRatedFacilities;
  const [showTreatmentPopup, setShowTreatmentPopup] = useState(true);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);

  const handleShowSignUpPopup = () => {
    setShowSignUpPopup(true);
  };

  const handleShowLoginPopup = () => {
    setShowSignUpPopup(false);
  };

  const handleClosePopup = () => {
    setShowSignUpPopup(false);
  };
  let jsonLD = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Cure Value",
    url: `${window.location.origin}`,
    logo: `${window.location.origin}/media/images/CV-Logo.png`,
    description:
      "Cure Value is a leading organization dedicated to providing valuable resources and information on healthcare, wellness, and medical advancements.",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+1-800-123-4567",
      contactType: "Customer Service",
      email: "info@curevalue.org",
    },
    address: {
      "@type": "PostalAddress",
      streetAddress: "5678 Health Street",
      addressLocality: "San Francisco",
      addressRegion: "CA",
      postalCode: "94103",
      addressCountry: "USA",
    },
    sameAs: [
      "https://www.facebook.com/curevalue",
      "https://www.instagram.com/curevalue",
      "https://twitter.com/curevalue",
      "https://www.linkedin.com/company/curevalue",
    ],
  };

  return (
    <>
      {showSignUpPopup && (
        <SignUpPopup
          handleShowSignUpPopup={handleShowSignUpPopup}
          showSignUpPopup={showSignUpPopup}
          handleShowLoginPopup={handleShowLoginPopup}
          handleClosePopup={handleClosePopup}
          //popupDetailMainText={"to save your favorite"}
          // popupDetailSubText={
          //   "Save your favorite and compare them side by side"
          // }
        />
      )}

      <Helmet>
        {/* <title>CureValue - The leading healthcare tourism platform</title>
        <meta
          name="description"
          content="CureValue - The leading healthcare tourism platform"
        />
        <meta
          property="og:title"
          content="Best Medical Tourism Destinations | CureValue"
        />
        <meta
          property="og:description"
          content="Discover the top destinations for medical tourism and get the best healthcare services abroad."
        />
        <meta
          property="og:image"
          content={`https://dev.curevalue.org/media/images/CV-Logo.png`}
        />
        <meta property="og:url" content={`${window.location.origin}`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Best Medical Tourism Destinations | CureValue"
        />
        <meta
          name="twitter:description"
          content="Discover the top destinations for medical tourism and get the best healthcare services abroad."
        />
        <meta
          name="twitter:image"
          content={`${window.location.origin}/media/images/CV-Logo.png`}
        /> */}
        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      <Banner />
      <div
        className=" pt-[30px] pb-[130px] lg:pb-[70px]"
        id="servicesandtreatments"
      >
        <PopularTreatments />
      </div>
      <div className="relative cityselection-cards">
        <div className="citysliders relative">
          {/* <img
            src={vector3}
            className="absolute w-[130px] top-[-12%] left-[0] lg:top-[-30%] lg:left-[0] lg:w-[320px] z-0 stathoscope1"
          /> */}
          {/* {topCities.map((city: City) => {
            return <div>{city.city_name}</div>;
          })} */}
          <div className="overflow-hidden ">
            <div
              className="py-[60px] px-[24px] lg:px-[107px] dest-card min-h-[850px] lg:min-h-[690px]"
              id="bestdestinations"
            >
              <CityCarousel
                contentId={topRatedDestinationsContentId}
                handleShowSignUpPopup={handleShowSignUpPopup}
              ></CityCarousel>
            </div>
          </div>
        </div>
        <div className="citysliders relative lg:min-h-[630px]">
          {/* <img
            src={vector1}
            className="absolute w-[300px] top-[-26%] left-[-29%] lg:top-[-36%] lg:left-[0] lg:w-[450px] z-0 stathoscope2 hidden lg:block"
          /> */}
          <div className="overflow-hidden">
            <div
              className="py-[60px] px-[24px] lg:px-[107px] dest-card min-h-[850px] lg:min-h-[690px]"
              id="topfacilities"
            >
              <FacilityCarousel
                contentId={topRatedFacilitiesContentId}
                handleShowSignUpPopup={handleShowSignUpPopup}
              ></FacilityCarousel>
            </div>
          </div>
        </div>
      </div>
      <CustomerStories />
      <ClientLogos />
      <BuildingCommunity />
      {/* <CompareSlider /> */}
      {/* {showTreatmentPopup && (
        <TreatmentSearchPopup
          setIsOpenModal={setShowTreatmentPopup}
        ></TreatmentSearchPopup>
      )} */}
    </>
  );
};

export default Homepage;
