import bannerimg from "../../assets/images/banner-full.png";
import bannermob from "../../assets/images/bannermob-full.png";
import React, { useEffect, useRef, useState } from "react";
import { BiPlusMedical } from "react-icons/bi";
import { FaLocationDot } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import contentIdMap from "../../assets/content/contentful.json";
import { getAsset, getEntry } from "./cms/CMSService";
import { CureValueService } from "../../helpers/ServiceWrapper";
import { useCMS, useGetCMS } from "./cms/CMSContext";
import _ from "lodash";
import { getURLFromAssetsId } from "./cms/CMSHelper";
import { useDebounce } from "use-debounce";
import Loader from "./shared/Loader";
import TreatmentSearch from "./shared/TreatmentSearch";
import {
  TreatmentContextModel,
  findTreatment,
  useTreatment,
} from "../modules/treatment";
import TreatmentForm from "./shared/TreatmentForm";

const Banner = () => {
  const contentId = contentIdMap.homePage.bannerContentId;
  const [openFacilitiesList, setOpenFacilitiesList] = useState(false);
  const navigate = useNavigate();
  const content = useGetCMS(contentId);
  const { setSelectedTreatment: setTreatmentContext, selectedTreatment } =
    useTreatment();

  const handleTreatmentSelectionChange = (treatment: TreatmentContextModel) => {
    setTreatmentContext(treatment);
  };

  return (
    <div className="relative overflow-hidden bg-[#F5F7FA] pt-[70px] lg:pt-[80px]">
      <div className="desktop relative">
        <img
          src={getURLFromAssetsId(content?.bannerBgImage?.sys.id, content)}
          className="w-[100%]  hidden lg:block"
        />
        <img
          src={getURLFromAssetsId(
            content?.bannerBgImageMobile?.sys.id,
            content
          )}
          className="w-[100%] lg:hidden block"
        />
        <div className="text-white absolute  right-[2%]  bottom-[10%] lg:bottom-[52px] lg:left-0 lg:right-0 lg:w-[100%] lg:max-w-[100%] max-w-[50%] banner-text lg:flex lg:flex-col lg:justify-center lg:items-center ">
          <h1 className="font-semibold text-[20px] text-center lg:text-left lg:text-h1font pb-[11px]  bg-[#e4803ce3] p-[5px] rounded-t-lg  w-full lg:w-fit">
            {content?.title}
          </h1>
          <p className="text-anchortext text-center lg:text-[20px] pb-[24px] w-fit bg-[#0e4d92bf]  px-[5px] font-semibold rounded-b-lg rounded-tr-lg lg:rounded-tl-lg lg:w-[50%] lg:text-center">
            {content?.subTitle}
          </p>
        </div>

        <div className="absolute top-[2%] right-0 lg:top-[20%] lg:right-[100px]  flex w-full justify-center lg:justify-end">
          {/* bg-[#ffffff45] */}
          {/* <div className="p-[10px] rounded-[16px] h-fit bg-white"> */}
          <TreatmentForm
            onSelectionChange={handleTreatmentSelectionChange}
            defaultSelection={selectedTreatment}
          ></TreatmentForm>
          {/* </div> */}
        </div>

        {/* <div className="absolute top-[30px] lg:top-[54px] left-0 right-0 mx-auto">
          <div className=" w-[95%] lg:w-[50%] mx-auto ">
            <TreatmentSearch
              onSelectionChange={handleTreatmentSelectionChange}
              defaultSelection={selectedTreatment}
            ></TreatmentSearch>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Banner;
