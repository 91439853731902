import axios from "axios";
import { BaseResponse } from "../../common";
import { API_FAVORITE_URL } from "../../../components/shared/urlConstants";
import { AddRemoveFavoriteRequest, MyFavoriteResponse } from "./_models";

export function addToFavorite(payload: AddRemoveFavoriteRequest) {
  return axios
    .post<any, BaseResponse<any>>(`${API_FAVORITE_URL}addToFavourite`, payload)
    .then((response) => {
      return response.data;
    });
}
export function removeFromFavorite(payload: AddRemoveFavoriteRequest) {
  return axios
    .post<any, BaseResponse<any>>(`${API_FAVORITE_URL}removeFavourite`, payload)
    .then((response) => {
      return response.data;
    });
}
export function getAllFavorites() {
  return axios
    .get<any, BaseResponse<MyFavoriteResponse>>(`${API_FAVORITE_URL}getAll`)
    .then((response) => {
      return response.data;
    });
}
