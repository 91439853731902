import { MdQuestionAnswer } from "react-icons/md";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import img1 from "../../../assets/images/acc-img.png";
import React, { useState } from "react";
import img2 from "../../../assets/images/citypopup.png";

const FaqNew = () => {
  const [isOpenList, setIsOpenList] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [openImageIndex, setOpenImageIndex] = useState(-1);

  const toggleAnswer = (index: number) => {
    // if (isOpenList[index]) {
    //   // If the clicked accordion is already open, return early
    //   return;
    // }
    const updatedIsOpenList = isOpenList.map((value, idx) =>
      idx === index ? !value : false
    );

    setIsOpenList(updatedIsOpenList);
    setOpenImageIndex(index);
  };
  return (
    <div className="pt-[93px] pb-[86px] lg:py-[88px]">
      <div className="container">
        <div className="flex justify-between items-center gap-[38px] border-b border-borderclr lg:border-0 pb-[46px]">
          <div className="flex items-center gap-[5px]">
            <MdQuestionAnswer className="text-cardpara text-h3font lg:text-[35px]" />
            <h2 className="text-cardpara text-h3font font-semibold lg:text-[38px] lg:font-semibold">
              FAQs
            </h2>
          </div>
          {/* <div className="flex items-center justify-end gap-[10px]">
            <span className="text-footerlink text-pc text-h4font">
              Visit our help desk
            </span>
            <a href="#">
              <BsFillArrowRightCircleFill className="text-pc text-[21px] lg:text-pc lg:text-h2font" />
            </a>
          </div> */}
        </div>
        <div className="flex gap-[2%] flex-wrap">
          <div className="flex items-start  accordion-images flex-col w-[100%] lg:w-[49%] pb-[20px]">
            <h2 className="text-pc text-h5font font-semibold lg:text-[18px] lg:font-semibold pb-[20px]">
              General
            </h2>
            {/* <div className="hidden lg:block">
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 0 ? "" : "hidden"
              }`}
            />
            <img
              src={img2}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 1 ? "" : "hidden"
              }`}
            />
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 2 ? "" : "hidden"
              }`}
            />
            <img
              src={img2}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 3 ? "" : "hidden"
              }`}
            />
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 4 ? "" : "hidden"
              }`}
            />
          </div> */}
            <div className="border-t border-borderclr">
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(0)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      What is Cure Value?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[0] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[0]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      CureValue is a medical tourism marketplace that connects
                      American patients with international healthcare
                      facilities. We provide access to over 2500
                      hospitals and clinics in 68 countries and 565 cities.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(1)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      How does CureValue work?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[1] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[1]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      CureValue allows you to search and compare international
                      healthcare facilities based on your medical needs. You can
                      read reviews, compare prices, and book your treatment
                      directly through our platform.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(2)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      Why should I consider medical treatment abroad?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[2] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[2]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      Seeking medical treatment abroad can offer significant
                      cost savings, access to high-quality care, and the
                      opportunity to receive treatments that may not be
                      available or accessible in the US.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start  accordion-images flex-col w-[100%] lg:w-[49%] pb-[20px]">
            <h2 className="text-pc text-h5font font-semibold lg:text-[18px] lg:font-semibold pb-[20px]">
              Services
            </h2>
            <div className="border-t border-borderclr">
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(3)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      What types of treatments can I find on CureValue?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[3] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[3]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      CureValue provides a range of treatments and services in
                      35 different categories, including dental care, cosmetic
                      procedures, hair transplants, and yoga retreats.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(4)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      Are the facilities listed on CureValue accredited?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[4] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[4]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      Accreditations vary from facility to facility. In
                      addition, CureValue uses an advanced methodology to rate
                      facilities around the world based on several factors,
                      including facility ratings by local people, called the
                      CureValue Score System.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(5)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      How do I book a treatment through CureValue?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[5] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[5]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      This feature is coming soon and is not yet available.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start  accordion-images flex-col w-[100%] lg:w-[49%] pb-[20px]">
            <h2 className="text-pc text-h5font font-semibold lg:text-[18px] lg:font-semibold pb-[20px]">
              Costs and Payments
            </h2>
            {/* <div className="hidden lg:block">
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 0 ? "" : "hidden"
              }`}
            />
            <img
              src={img2}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 1 ? "" : "hidden"
              }`}
            />
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 2 ? "" : "hidden"
              }`}
            />
            <img
              src={img2}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 3 ? "" : "hidden"
              }`}
            />
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 4 ? "" : "hidden"
              }`}
            />
          </div> */}
            <div className="border-t border-borderclr">
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(6)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      How much can I save by using CureValue?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[6] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[6]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      Every city and facility are ranked for their
                      affordability, including a side-by-side comparison with
                      the US. Savings depend on various factors, and CureValue
                      has made it easy and efficient to compare by various
                      factors.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(7)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      How do I pay for my treatment?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[7] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[7]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      This feature is coming soon.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(8)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      Are there any hidden costs?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[8] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[8]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      CureValue is transparent about all costs involved. The
                      price you see includes the treatment and any additional
                      fees will be clearly outlined during the booking process.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start accordion-images flex-col w-[100%] lg:w-[49%] pb-[20px]">
            <h2 className="text-pc text-h5font font-semibold lg:text-[18px] lg:font-semibold pb-[20px]">
              Travel and Accommodation
            </h2>
            {/* <div className="hidden lg:block">
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 0 ? "" : "hidden"
              }`}
            />
            <img
              src={img2}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 1 ? "" : "hidden"
              }`}
            />
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 2 ? "" : "hidden"
              }`}
            />
            <img
              src={img2}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 3 ? "" : "hidden"
              }`}
            />
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 4 ? "" : "hidden"
              }`}
            />
          </div> */}
            <div className="border-t border-borderclr">
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(9)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      Does CureValue help with travel arrangements?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[9] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[9]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      This feature is coming soon.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(10)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      Do I need a visa to travel for medical treatment?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[10] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[10]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      Visa requirements vary by country. CureValue provides
                      guidance and support to help you understand and obtain the
                      necessary travel documents.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(11)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      Can I bring a companion with me?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[11] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[11]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      Yes, many patients choose to travel with a companion.
                      CureValue can help arrange accommodations and travel for
                      both you and your companion.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start accordion-images flex-col w-[100%] lg:w-[49%] pb-[20px] ">
            <h2 className="text-pc text-h5font font-semibold lg:text-[18px] lg:font-semibold pb-[20px]">
              Safety and Quality
            </h2>
            {/* <div className="hidden lg:block">
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 0 ? "" : "hidden"
              }`}
            />
            <img
              src={img2}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 1 ? "" : "hidden"
              }`}
            />
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 2 ? "" : "hidden"
              }`}
            />
            <img
              src={img2}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 3 ? "" : "hidden"
              }`}
            />
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 4 ? "" : "hidden"
              }`}
            />
          </div> */}
            <div className="border-t border-borderclr">
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(12)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      How does CureValue ensure the quality of the facilities?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[12] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[12]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      CureValue only partners with facilities that have
                      international accreditations and certifications. We also
                      collect patient reviews and feedback to continuously
                      monitor and ensure the quality of care.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(13)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      Is it safe to get medical treatment abroad?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[13] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[13]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      Yes, medical tourism is a safe option when choosing
                      accredited and reputable facilities. CureValue partners
                      with top-rated hospitals and clinics to ensure your safety
                      and satisfaction.
                    </p>
                  </div>
                </div>
              </div>
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(14)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      What if something goes wrong during my treatment?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[14] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[14]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      Most facilities offer follow-up care and advice on how to
                      deal with situations, which vary from patient to patient.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start accordion-images flex-col w-[100%] lg:w-[49%] pb-[20px]">
            <h2 className="text-pc text-h5font font-semibold lg:text-[18px] lg:font-semibold pb-[20px]">
              Aftercare
            </h2>
            {/* <div className="hidden lg:block">
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 0 ? "" : "hidden"
              }`}
            />
            <img
              src={img2}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 1 ? "" : "hidden"
              }`}
            />
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 2 ? "" : "hidden"
              }`}
            />
            <img
              src={img2}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 3 ? "" : "hidden"
              }`}
            />
            <img
              src={img1}
              className={`max-w-[425px] w-[425px] ${
                openImageIndex === 4 ? "" : "hidden"
              }`}
            />
          </div> */}
            <div className="border-t border-borderclr">
              <div className="border-b border-borderclr">
                <div className="py-[22px] border-b border-white cursor-pointer">
                  <div
                    className="faq-question relative flex items-center justify-between"
                    onClick={() => toggleAnswer(15)}
                  >
                    <p className="text-cardpara font-semibold max-w-[85%]">
                      What kind of aftercare is provided?
                    </p>
                    <IoIosArrowDown
                      size={"15px"}
                      className={`text-cardpara cursor-pointer ${
                        isOpenList[15] ? "rotate-180" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`faq-answer ${
                      isOpenList[15]
                        ? "open opacity-100"
                        : "max-h-0 opacity-0 overflow-hidden"
                    }`}
                  >
                    {/* <img
                    src={img1}
                    className="pt-[15px] w-[100%] max-w-[500px] block lg:hidden"
                  /> */}
                    <p className="text-cardpara font-medium pt-[22px] max-w-[90%] lg:max-w-[75%]">
                      Aftercare options vary by treatment and facility.
                      CureValue ensures that you receive comprehensive aftercare
                      instructions and can help coordinate follow-up
                      appointments as needed.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqNew;
