import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import axios from "axios";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import { getAllEntries } from "./CMSService";
import _, { forEach } from "lodash";
import { forEachChild } from "typescript";
import { boolean } from "yup";
import { sampleContent } from "./SampleContent";

type CMSContextProps = {
  content: any;
  setContent: (auth: any) => void;
};

const initCMSContextPropsState: CMSContextProps = {
  content: null,
  setContent: () => {},
};

const CMSContext = createContext<CMSContextProps>(initCMSContextPropsState);

const useGetCMS = (contentId: string) => {
  const { content } = useContext(CMSContext);
  if (content) {
    return getContentFromAllEntries(content, contentId);
  } else {
    return null;
  }
};
const getContentFromAllEntries = (content: any, contentId: string) => {
  const contentById = _.find(content.items, { sys: { id: contentId } });
  const assets = recursiveSearch(contentById.fields, "linkType");
  const assetsWithLink: any = [];
  const linkedEntries: any = [];
  assets.forEach((asset: any) => {
    if (asset.linkType == "Asset") {
      const assetWithLink = _.find(content.includes.Asset, {
        sys: { id: asset.id },
      });
      if (assetWithLink) {
        assetsWithLink.push(assetWithLink);
      }
    } else {
      const data = getContentFromAllEntries(content, asset.id);

      linkedEntries.push(data);
    }
  });
  return {
    ...contentById.fields,
    id: contentById.sys.id,
    assets: assetsWithLink,
    entries: linkedEntries,
  };
};
const useCMS = () => {
  return useContext(CMSContext);
};
// const useCMSAsset = (contentId: string, content: any) => {
//   // const content = useCMS(contentId);
//   if (content != null) {
//     return recursiveSearch(content.fields, "linkType");
//   }
// };
const recursiveSearch = (obj: any, searchKey: any, results: any = []) => {
  const r = results;
  Object.keys(obj).forEach((key) => {
    const value = obj[key];
    if (key === searchKey && typeof value !== "object") {
      r.push(obj);
    } else if (typeof value === "object") {
      recursiveSearch(value, searchKey, r);
    }
  });
  return r;
};

const CMSProvider = ({ children }: any) => {
  const [content, setContent] = useState<any>(null);

  return (
    <CMSContext.Provider value={{ content, setContent }}>
      {children}
    </CMSContext.Provider>
  );
};
const CMSInit = ({ children }: any) => {
  const { content, setContent } = useCMS();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  useEffect(() => {
    fetchContent();
  }, []);
  const fetchContent = async () => {
    const { data, error } = await CureValueService(getAllEntries, null, {
      handleError: false,
    });
    if (!error) {
      setContent(data);
      setShowSplashScreen(false);
    } else {
      setContent(sampleContent);
    }

    setShowSplashScreen(false);
  };

  return showSplashScreen ? (
    <>
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>Loading...</p>
      </div>
    </>
  ) : (
    <>{children}</>
  );
};

export { CMSProvider, useCMS, useGetCMS, CMSInit };
