import { IoMdLock } from "react-icons/io";
import {
  MdAirlineSeatIndividualSuite,
  MdOutlinePets,
  MdOndemandVideo,
  MdCommute,
} from "react-icons/md";
// import { MdOutlinePets } from "react-icons/md";
const AmenitiesCard = ({
  facility,
  cardFor,
  isBeforeLogin,
  handleShowSignUpPopup,
}: any) => {
  const handleLockBtnClick = () => {
    handleShowSignUpPopup();
  };

  return (
    <div id="amenities">
      <div className="mb-[30px] lg:mb-[52px] pb-[27px] lg:pb-[58px] border-b border-borderclr">
        <div className="flex items-center gap-[5px] pb-[16px]">
          <MdAirlineSeatIndividualSuite className="text-iconclr text-h3font lg:text-[30px]" />
          <h2 className="text-sc text-h3font font-semibold lg:text-[20px] lg:font-bold">
            Amenities
          </h2>
        </div>

        {isBeforeLogin ? (
          <>
            <div>
              <div className="relative top-[300px] left-[80px] lg:top-[120px] lg:left-[350px] z-[5] flex-col items-center">
                <div
                  onClick={handleLockBtnClick}
                  className="flex flex-col items-center text-center w-[180px] lg:w-[200px]"
                >
                  <IoMdLock size={"37px"} className="text-cardpara mb-2" />
                  <p>Create a free account or login to view</p>
                </div>
              </div>
              <p
                className={`text-[19px] lg:text-pfont/[26px] text-cardpara mb-[10px] ${
                  isBeforeLogin ? "blur-text" : ""
                }`}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque
                velit laborum quod deserunt! Molestiae ad aliquam minus! Autem
                nesciunt accusamus fugit voluptatum, adipisci quibusdam a
                deserunt quod sapiente officiis sequi, alias ullam blanditiis.
                Tempore cumque iusto dicta perferendis accusantium ad rem
                asperiores! Libero culpa mollitia nobis alias maxime quos
                impedit aperiam soluta! Voluptatibus, odit fuga. Harum quaerat
                laborum reiciendis, doloribus minima nemo assumenda nisi, eum,
                magni praesentium dolor eaque tempora adipisci natus! Dolorem
                officia, nostrum dignissimos odit magni tempora repudiandae modi
                expedita nobis, porro voluptatibus non ipsam enim quas et eaque
                ipsum praesentium. Quod laborum hic atque debitis doloremque
                dicta.
              </p>
            </div>
          </>
        ) : (
          <>
            <div>
              {facility.amenities && facility.amenities.length > 0 ? (
                <>
                  <div className="grid grid-cols-3 gap-[30px] lg:gap-[70px] md:max-w-[70%]">
                    {facility.amenities.map((amenity: any) => {
                      return (
                        <>
                          {amenity == "WiFi" ? (
                            <>
                              <div className="flex flex-col items-start ">
                                <div className="border border-borderclr bg-[#f8f8f8] w-[90px] h-[90px] lg:w-[140px] lg:h-[140px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
                                  <MdAirlineSeatIndividualSuite className="text-[50px] lg:text-[80px] text-iconclr2  group-hover:text-white" />
                                </div>
                                <p className="text-anchortext lg:text-pfont text-black pt-[5px] lg:whitespace-nowrap">
                                  WiFi
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {amenity == "Virtual visits" ? (
                            <>
                              <div className="flex flex-col items-start">
                                <div className="border border-borderclr bg-[#f8f8f8] w-[90px] h-[90px] lg:w-[140px] lg:h-[140px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
                                  <MdOndemandVideo className="text-[50px] lg:text-[80px] text-iconclr2 group-hover:text-white" />
                                </div>
                                <p className="text-anchortext lg:text-pfont text-black pt-[5px] lg:whitespace-nowrap">
                                  Virtual visits
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {amenity == "On-site pharmacy" ? (
                            <>
                              <div className="flex flex-col items-start">
                                <div className="border border-borderclr bg-[#f8f8f8] w-[90px] h-[90px] lg:w-[140px] lg:h-[140px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
                                  <MdOndemandVideo className="text-[50px] lg:text-[80px] text-iconclr2 group-hover:text-white" />
                                </div>
                                <p className="text-anchortext lg:text-pfont text-black pt-[5px] lg:whitespace-nowrap">
                                  On-site pharmacy
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {amenity == "Tourist attractions nearby" ? (
                            <>
                              <div className="flex flex-col items-start">
                                <div className="border border-borderclr bg-[#f8f8f8] w-[90px] h-[90px] lg:w-[140px] lg:h-[140px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
                                  <MdOndemandVideo className="text-[50px] lg:text-[80px] text-iconclr2 group-hover:text-white" />
                                </div>
                                <p className="text-anchortext lg:text-pfont text-black pt-[5px] lg:whitespace-nowrap">
                                  Tourist attractions nearby
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {amenity == "Online scheduling" ? (
                            <>
                              <div className="flex flex-col items-start">
                                <div className="border border-borderclr bg-[#f8f8f8] w-[90px] h-[90px] lg:w-[140px] lg:h-[140px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
                                  <MdOndemandVideo className="text-[50px] lg:text-[80px] text-iconclr2 group-hover:text-white" />
                                </div>
                                <p className="text-anchortext lg:text-pfont text-black pt-[5px] lg:whitespace-nowrap">
                                  Online scheduling
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {amenity == "Post-treatment support" ? (
                            <>
                              <div className="flex flex-col items-start">
                                <div className="border border-borderclr bg-[#f8f8f8] w-[90px] h-[90px] lg:w-[140px] lg:h-[140px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
                                  <MdOndemandVideo className="text-[50px] lg:text-[80px] text-iconclr2 group-hover:text-white" />
                                </div>
                                <p className="text-anchortext lg:text-pfont text-black pt-[5px] lg:whitespace-nowrap">
                                  Post-treatment support
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {amenity == "Multi-cuisine options" ? (
                            <>
                              <div className="flex flex-col items-start">
                                <div className="border border-borderclr bg-[#f8f8f8] w-[90px] h-[90px] lg:w-[140px] lg:h-[140px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
                                  <MdOndemandVideo className="text-[50px] lg:text-[80px] text-iconclr2 group-hover:text-white" />
                                </div>
                                <p className="text-anchortext lg:text-pfont text-black pt-[5px] lg:whitespace-nowrap">
                                  Multi-cuisine options
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {amenity == "Video consultation" ? (
                            <>
                              <div className="flex flex-col items-start">
                                <div className="border border-borderclr bg-[#f8f8f8] w-[90px] h-[90px] lg:w-[140px] lg:h-[140px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
                                  <MdOndemandVideo className="text-[50px] lg:text-[80px] text-iconclr2 group-hover:text-white" />
                                </div>
                                <p className="text-anchortext lg:text-pfont text-black pt-[5px] lg:whitespace-nowrap">
                                  Video consultation
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {amenity == "Telemedicine" ? (
                            <>
                              <div className="flex flex-col items-start">
                                <div className="border border-borderclr bg-[#f8f8f8] w-[90px] h-[90px] lg:w-[140px] lg:h-[140px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
                                  <MdOndemandVideo className="text-[50px] lg:text-[80px] text-iconclr2 group-hover:text-white" />
                                </div>
                                <p className="text-anchortext lg:text-pfont text-black pt-[5px] lg:whitespace-nowrap">
                                  Telemedicine
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}

                          {amenity == "English speaking staff" ? (
                            <>
                              <div className="flex flex-col items-start">
                                <div className="border border-borderclr bg-[#f8f8f8] w-[90px] h-[90px] lg:w-[140px] lg:h-[140px] rounded-[20px] flex items-center justify-center group hover:bg-pc">
                                  <MdOndemandVideo className="text-[50px] lg:text-[80px] text-iconclr2 group-hover:text-white" />
                                </div>
                                <p className="text-anchortext lg:text-pfont text-black pt-[5px] lg:whitespace-nowrap">
                                  English speaking staff
                                </p>
                              </div>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}

                    {/* <div className="flex flex-col items-start text-center h-[90px] w-[90px] lg:w-[140px] lg:h-[140px] justify-center">
            <p className="text-anchortext lg:text-pfont font-semibold text-blueclr2 cursor-pointer hover:text-hoverclr">
              Show all 36 amenities
            </p>
          </div> */}
                  </div>
                </>
              ) : (
                <>
                  {/* <div className="grid grid-cols-3 gap-[30px] lg:gap-[70px] md:max-w-[70%]">
                    <div className="flex flex-col items-start ">
                      
                    </div>
                  </div> */}
                  <p className="lg:text-pfont  pt-[5px]  text-pfont text-iconclr font-medium">
                    Coming soon
                  </p>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default AmenitiesCard;
