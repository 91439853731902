import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { PiMedalFill } from "react-icons/pi";
import cardimg from "../../../assets/images/destination-img1.png";
import treatmentColourMap from "../../modules/common/core/treatmentColourMap.json";
import CureValueRating from "../shared/CureValueRating";
import { Facility } from "../../modules/facility";
import { IoClose } from "react-icons/io5";
import { ChangeEvent, MouseEvent, MouseEventHandler } from "react";
import { City } from "../../modules/city";
import { FACILITY_IMAGE_CDN } from "../shared/urlConstants";

interface FacilityCardProps {
  facility: Facility;
  onCloseClicked?: (e: MouseEvent) => void;
  onCompareChanged?: (e: ChangeEvent<HTMLInputElement>) => void;
  isComparePage: boolean;
  isCompareChecked?: boolean;
  isRemoveFavoriteEnabled: boolean;
  compareLimitReached?: boolean;
  onClick?: (e: MouseEvent<HTMLDivElement>, entity: Facility) => void;
}

const FacilityCompareCard = ({
  facility,
  onCloseClicked,
  onCompareChanged,
  isComparePage,
  isRemoveFavoriteEnabled,
  isCompareChecked,
  compareLimitReached,
  onClick,
}: FacilityCardProps) => {
  const getTreatmentColour = (treatment: string) => {
    const colour = JSON.parse(JSON.stringify(treatmentColourMap))[treatment];

    return colour;
  };
  return (
    <div
      onClick={(e) => {
        if (onClick) {
          onClick(e, facility);
        }
      }}
      className={`facilityipad cursor-pointer border-[3px] border-borderclr hover:border-hoverclr rounded-[30px] px-[15px] pb-[19px] lg:px-[25px] lg:pb-[25px] bg-white relative pt-[110px] lg:pt-[120px] w-[390px] flex flex-col justify-between shrink-0 ${
        isComparePage == false ? "min-h-[450px]" : "min-h-[400px]"
      }`}
    >
      <img
        src={
          facility?.image_urls.length != 0
            ? `${
                FACILITY_IMAGE_CDN +
                facility?._id +
                "/" +
                facility?.image_urls[0]
              }`
            : cardimg
        }
        // src={cardimg}
        alt={facility.facility_name.toLocaleLowerCase()}
        className="w-[100%] lg:max-h-[190px] lg:max-w-[340px]  m-auto absolute left-0 right-0 top-[-50px] h-[165px] rounded-[25px] object-contain bg-gray-500 aspect-[16/9]"
      />
      {isRemoveFavoriteEnabled && (
        <div
          className="absolute top-[-40px] right-[40px] text-[20px] p-[8px] bg-[#d9e0d9bd] text-hoverclr rounded-full cursor-pointer"
          onClick={(e: MouseEvent) => {
            if (onCloseClicked) {
              onCloseClicked(e);
            }
          }}
        >
          <IoClose />
          {/* <IoMdHeart /> */}
        </div>
      )}
      <div className="flex items-center justify-between lg:pt-[13px] 2xl:pt-[31px] pb-[8px] h-[120px]">
        <h2 className="text-sc text-h4font/[22px] lg:text-[21px]/[27px] font-semibold 2xl:pb-[10px] max-w-[70%] lg:max-w-[70%]">
          {facility.facility_name}
          <span className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium block">
            {facility.facility_location}
          </span>
        </h2>

        <div className="flex items-center gap-[13px]">
          <CureValueRating
            rating={facility.curevalue_rating}
            size="sm"
          ></CureValueRating>
          {/* <IoMdInformationCircleOutline className="cursor-pointer text-[20px] text-iconclr2 2xl:text-[30px]"/> */}
        </div>
      </div>
      <div className="flex flex-wrap items-center gap-[10px] pt-[11px] lg:pt-[9px] pb-[12px]">
        {facility?.treatments_offered?.map((treatment) => {
          return (
            <p
              className={`text-[10px] xl:text-anchortext font-medium px-[8px] py-[2px] ${getTreatmentColour(
                treatment.name
              )}`}
              key={Math.random()}
            >
              {treatment.name}
            </p>
          );
        })}
      </div>
      {/* {facility.accreditations && facility.accreditations?.length != 0 && (
        <div className="flex items-center gap-[5px] pb-[6px] xl:pb-[12px]">
          <PiMedalFill className="text-cardpara lg:text-[20px] 2xl:text-[35px]" />
          <p className="font-medium text-cardpara text-[10px] lg:text-anchortext ">
            {facility?.accreditations?.join(",")}
          </p>
        </div>
      )} */}
      {!isComparePage && (
        <div className="flex items-center">
          <input type="checkbox" className="mr-2" onChange={onCompareChanged} />
          Add to compare
        </div>
      )}
    </div>
  );
};

export default FacilityCompareCard;
