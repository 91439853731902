import axios from "axios";
import { BaseResponse } from "../../modules/common";
import { API_USER_AUTH_URL, CMS_API_BASEURL } from "../shared/urlConstants";

export function getEntry(entryId: string) {
  return axios
    .get<any>(`${CMS_API_BASEURL}/entries/${entryId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CMS_ACCESS_TOKEN}`,
      },
    })
    .then((response) => {
      return response;
    });
}
export function getAllEntries() {
  return axios
    .get<any>(`${CMS_API_BASEURL}/entries`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CMS_ACCESS_TOKEN}`,
      },
    })
    .then((response) => {
      return response;
    });
}
export function getAsset(assetId: string) {
  return axios
    .get<any>(`${CMS_API_BASEURL}/assets/${assetId}`, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CMS_ACCESS_TOKEN}`,
      },
    })
    .then((response) => {
      return response;
    });
}
