import axios from "axios";
import { BaseResponse, ListResponse } from "../../common";
import {
  API_CITY_URL,
  API_FACILITY_URL,
} from "../../../components/shared/urlConstants";
import { City } from "./_models";
import { Facility } from "../../facility/core/_models";

export function getTopRatesCities(payload: { offset: number; limit: number }) {
  return axios
    .post<any, BaseResponse<ListResponse<City>>>(
      `${API_CITY_URL}getTopRated`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}

export function getAllCities(payload: { treatmentName: any }) {
  return axios
    .get<any, BaseResponse<City[]>>(
      `${API_CITY_URL}getAll?treatmentName=${payload.treatmentName}`
    )
    .then((response) => {
      return response.data;
    });
}
export function getCityById(payload: { cityId: string }) {
  return axios
    .get<any, BaseResponse<any>>(
      `${API_CITY_URL}getById?cityId=` + payload.cityId
    )
    .then((response) => {
      return response.data;
    });
}

export function getCityByName(payload: { cityName: string }) {
  return axios
    .get<any, BaseResponse<any>>(
      `${API_CITY_URL}getByName?cityName=` + payload.cityName
    )
    .then((response) => {
      return response.data;
    });
}

export function getAllByCountryName(payload: { countryName: string }) {
  return axios
    .post<any, BaseResponse<any>>(
      `${API_CITY_URL}getAllByCountryName?countryName=`,
      payload
    )
    .then((response) => {
      return response.data;
    });
}
