import { IoClose } from "react-icons/io5";
import TreatmentSearch from "./TreatmentSearch";
import { useEffect, useState } from "react";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import {
  Treatment,
  TreatmentContextModel,
  getAllTreatments,
  getTreatmentByCityAndCountryName,
  useTreatment,
} from "../../modules/treatment";
import treatmentColourMap from "../../modules/common/core/treatmentColourMap.json";
import CVPopup from "./CVPopup";

interface TreatmentSearchPopupProps {
  setIsOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: (selectedTreatment: TreatmentContextModel | null) => void;
  treatmentsToShow?: Treatment[];
  isClosable?: boolean;
}
const TreatmentSearchPopup = ({
  setIsOpenModal,
  onClose,
  treatmentsToShow,
  isClosable,
}: TreatmentSearchPopupProps) => {
  const { treatments: treatmentFromContext, setSelectedTreatment } =
    useTreatment();
  const [treatments, setTreatments] = useState<Treatment[]>([]);
  const getTreatmentColour = (treatment: string) => {
    const colour = JSON.parse(JSON.stringify(treatmentColourMap))[treatment];

    return colour;
  };

  useEffect(() => {
    if (treatmentsToShow) {
      setTreatments(treatmentsToShow);
    } else {
      const filteredTreatment = treatmentFromContext.filter((treatment) => {
        if (
          treatment.name != "Sports Medicine" &&
          treatment.name != "Medical Spa Treatment" &&
          treatment.name != "Robotic Surgery" &&
          treatment.name != "IVF (In Vitro Fertilization)" &&
          treatment.name != "ENT (Ear, Nose, and Throat)" &&
          treatment.name != "Rehabilitation"
        ) {
          return true;
        } else return false;
      });
      setTreatments(filteredTreatment);
    }
  }, [treatmentFromContext]);

  return (
    <CVPopup
      isClosable={isClosable != undefined ? isClosable : true}
      onClose={() => {
        if (setIsOpenModal) {
          setIsOpenModal(false);
        }
        if (onClose) {
          onClose(null);
        }
      }}
    >
      <div className="flex flex-col lg:flex-row lg:items-end gap-[30px] lg:gap-[80px] pb-[20px] lg:pb-[35px]">
        <h4 className="text-h3font/[18px] lg:text-[26px]/[26px] text-sc font-semibold pb-[3px] w-[100%]">
          {treatmentsToShow
            ? "Choose your preferred treatment at this facility"
            : "Select your preferred treatment"}
        </h4>
      </div>
      <div className="flex flex-wrap lg:items-end gap-[20px] lg:gap-[20px]  justify-center">
        {treatments.map((treatment: Treatment) => {
          return (
            <span
              key={treatment._id}
              className={`flex text-[10px] xl:text-anchortext font-medium px-[8px] py-[2px] gap-[5px] items-center rounded-[50px] cursor-pointer ${getTreatmentColour(
                treatment.name
              )}`}
              onClick={() => {
                setSelectedTreatment({
                  treatment: treatment.name,
                  treatmentId: treatment._id,
                  subTreatment: "",
                  subTreatmentId: "",
                });
                if (setIsOpenModal) {
                  setIsOpenModal(false);
                }
                if (onClose) {
                  onClose({
                    treatment: treatment.name,
                    treatmentId: treatment._id,
                    subTreatment: "",
                    subTreatmentId: "",
                  });
                }
              }}
            >
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/media/treatment_Icons/${treatment.name.replace(
                  "/",
                  "_"
                )}.svg`}
                alt={`${process.env.PUBLIC_URL}/media/treatment_Icons/default.svg`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = `${process.env.PUBLIC_URL}/media/treatment_Icons/default.svg`;
                }}
                className="w-[30px] h-[30px] max-w-[60px]"
              />
              {treatment.name}
            </span>
          );
        })}
      </div>
    </CVPopup>
  );
};

export default TreatmentSearchPopup;
