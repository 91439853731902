import { IoIosArrowRoundForward } from "react-icons/io";
import CityCompareCard from "../components/city/CityCompareCard";
import FacilityCompareCard from "../components/facility/FacilityCompareCard";
import { ChangeEvent, MouseEvent, useEffect, useState } from "react";
import { CureValueService } from "../../helpers/ServiceWrapper";
import {
  getAllFavorites,
  removeFromFavorite,
} from "../modules/favorite/core/FavoriteService";
import { City } from "../modules/city";
import { Facility } from "../modules/facility";
import Loader from "../components/shared/Loader";
import { AddRemoveFavoriteRequest } from "../modules/favorite/core/_models";
import { useNavigate } from "react-router-dom";
import { constructURLSegment } from "../../helpers/CVHelper";
import { useAuth } from "../modules/auth";

const MyFavorite = () => {
  const navigate = useNavigate();
  const [favoriteFacilities, setFavoriteFacilities] = useState<Facility[]>([]);
  const [favoriteCities, setFavoriteCities] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [favoriteCityNames, setFavoriteCityNames] = useState<string[]>([]);
  const [favoriteFacilityNames, setFavoriteFacilityNames] = useState<string[]>(
    []
  );
  const [selectedCities, setSelectedCities] = useState<City[]>([]);
  const [selectedFacilities, setSelectedFacilities] = useState<Facility[]>([]);

  useEffect(() => {
    getMyFavorite(true);
  }, []);
  const getMyFavorite = async (isOnLoad: boolean = false) => {
    let config = {};
    if (isOnLoad) {
      config = { setIsLoading };
    }
    const { data, error } = await CureValueService(
      getAllFavorites,
      null,
      config
    );
    if (data) {
      setFavoriteCities(data.allFavouriteCities);
      setFavoriteFacilities(data.allFavouriteFacilities);
    }
  };
  const removeFacilityFavorite = async (e: MouseEvent, entity: Facility) => {
    let payload: AddRemoveFavoriteRequest = {
      favourite_type: "facility",
      facility_id: entity?._id,
    };
    const { data, error } = await CureValueService(removeFromFavorite, payload);
    if (data) {
      getMyFavorite();
    }
  };
  const removeCityFavorite = async (e: MouseEvent, entity: City) => {
    let payload: AddRemoveFavoriteRequest = {
      favourite_type: "city",
      city_id: entity?._id,
    };
    const { data, error } = await CureValueService(removeFromFavorite, payload);
    if (data) {
      getMyFavorite();
    }
  };

  const compareCitiesCheckHandle = (
    e: ChangeEvent<HTMLInputElement>,
    city: City
  ) => {
    const cityCompareURL = `${constructURLSegment(
      city.city_name
    )}-country-${constructURLSegment(city.country_name)}`;
    if (e.target.checked) {
      if (selectedCities.length < 3) {
        setSelectedCities([...selectedCities, city]);
        setFavoriteCityNames([...favoriteCityNames, cityCompareURL]);
      }
    } else {
      const filteredFavoriedCityName = favoriteCityNames.filter((cityName) => {
        return cityName != cityCompareURL;
      });
      setFavoriteCityNames([...filteredFavoriedCityName]);
    }
  };

  const compareFacilitiesCheckHandle = (
    e: ChangeEvent<HTMLInputElement>,
    facility: Facility
  ) => {
    const facilityCompareURL = `${constructURLSegment(
      facility.facility_name
    )}-city-${constructURLSegment(
      facility.city_name
    )}-country-${constructURLSegment(facility.country_name)}`;
    if (e.target.checked) {
      if (selectedFacilities.length < 4) {
        setSelectedFacilities([...selectedFacilities, facility]);
        setFavoriteFacilityNames([
          ...favoriteFacilityNames,
          facilityCompareURL,
        ]);
      }
    } else {
      const filteredFavoriteFacilityName = favoriteFacilityNames.filter(
        (facilityName) => {
          return facilityName != facilityCompareURL;
        }
      );
    }
  };

  return (
    <div className="bg-[#F5F7FA] pt-[70px] lg:pt-[80px] pb-[70px]">
      <div className="sm:block lg:block lg:max-w-[1800px] m-auto px-[24px] pt-[30px] pb-[24px]">
        <div className="mt-[20px]">
          <h2 className="text-[24px] lg:text-[26px] font-semibold mb-[11px]">
            Favorite destinations
          </h2>
          <div
            className={`flex gap-[20px] justify-start flex-wrap mb-[20px] min-h-[200px] ${
              isLoading || favoriteCities.length == 0
                ? "justify-center items-center"
                : ""
            }`}
          >
            {!isLoading &&
              favoriteCities.map((city) => {
                return (
                  <div className="pt-[50px]" key={city._id}>
                    <CityCompareCard
                      city={city}
                      onCloseClicked={(e) => {
                        removeCityFavorite(e, city);
                      }}
                      onCompareChanged={(e: ChangeEvent<HTMLInputElement>) => {
                        compareCitiesCheckHandle(e, city);
                      }}
                      isComparePage={false}
                      isRemoveFavoriteEnabled={true}
                      compareLimitReached={selectedCities.length >= 3}
                      isCompareChecked={selectedCities.some(
                        (c) => c._id === city._id
                      )}
                    ></CityCompareCard>
                  </div>
                );
              })}
            {isLoading && <Loader />}
            {!isLoading && favoriteCities.length == 0 && (
              <div>You have not selected any favorite destinations yet.</div>
            )}
          </div>

          {/* <a
            className="flex items-center gap-2 text-pc text-pfont lg:text-pfont font-medium"
            onClick={(e) => {
              if (
                favoriteCityNames.length >= 2 &&
                favoriteCityNames.length <= 3
              ) {
                navigate(`/compare-city/${favoriteCityNames.join("-vs-")}`);
              }
            }}
          >
            {favoriteCityNames.length <= 3 ? (
              <>
                Compare destinations <IoIosArrowRoundForward />
              </>
            ) : (
              <>Maximum 3 cities can be compaired at a time.</>
            )}
          </a> */}
          {/* {favoriteCityNames.length <= 3 ? (
              <>
                Compare destinations <IoIosArrowRoundForward />
              </>
            ) : (
              <>Maximum 3 cities can be compaired at a time.</>
            )} */}
          <div className="flex gap-3 items-center">
            <button
              className={`btn text-white rounded-full font-anchor font-medium py-[7px] px-[27px] flex justify-center items-center gap-[10px] ${
                favoriteCityNames.length > 3 ? " bg-[gray]" : "bg-pc"
              }`}
              onClick={(e) => {
                if (
                  favoriteCityNames.length >= 2 &&
                  favoriteCityNames.length <= 3
                ) {
                  navigate(`/compare-city/${favoriteCityNames.join("-vs-")}`);
                }
              }}
              disabled={favoriteCityNames.length > 3 ? true : false}
            >
              Compare destinations <IoIosArrowRoundForward />
            </button>
            {favoriteCityNames.length > 3 && (
              <span className="text-smalltext">
                <span>*</span>Maximum 3 cities can be compaired at a time.
              </span>
            )}
          </div>

          <h2 className="text-[24px] lg:text-[26px] font-semibold mb-[11px] mt-[50px]">
            Favorite facilities
          </h2>
          <div
            className={`flex gap-[20px] justify-start flex-wrap mb-[20px] min-h-[200px] ${
              isLoading || favoriteFacilities.length == 0
                ? "justify-center items-center"
                : ""
            }`}
          >
            {!isLoading &&
              favoriteFacilities.map((facility) => {
                return (
                  <div className="pt-[50px]" key={facility._id}>
                    <FacilityCompareCard
                      facility={facility}
                      onCloseClicked={(e) => {
                        removeFacilityFavorite(e, facility);
                      }}
                      onCompareChanged={(e: ChangeEvent<HTMLInputElement>) => {
                        compareFacilitiesCheckHandle(e, facility);
                      }}
                      isRemoveFavoriteEnabled={true}
                      isComparePage={false}
                      compareLimitReached={selectedFacilities.length >= 4}
                      isCompareChecked={selectedFacilities.some(
                        (f) => f._id === facility._id
                      )}
                    ></FacilityCompareCard>
                  </div>
                );
              })}
            {isLoading && <Loader />}
            {!isLoading && favoriteFacilities.length == 0 && (
              <div>No Favorites to show</div>
            )}
          </div>

          {/* <a
            className="flex items-center gap-2 text-pc text-pfont lg:text-pfont font-medium"
            onClick={(e) => {
              if (
                favoriteFacilityNames.length >= 2 &&
                favoriteFacilityNames.length <= 4
              ) {
                navigate(`/compare/${favoriteFacilityNames.join("-vs-")}`);
              }
            }}
          >
            {favoriteFacilityNames.length <= 4 ? (
              <>
                Compare facilities <IoIosArrowRoundForward />
              </>
            ) : (
              <>Maximum 4 facilities can be compaired at a time.</>
            )}
          </a> */}
          <div className="flex gap-3 items-center">
            <button
              className={`btn text-white rounded-full font-anchor font-medium py-[7px] px-[27px] flex justify-center items-center gap-[10px] ${
                favoriteFacilityNames.length > 4 ? " bg-[gray]" : "bg-pc"
              }`}
              onClick={(e) => {
                if (
                  favoriteFacilityNames.length >= 2 &&
                  favoriteFacilityNames.length <= 4
                ) {
                  navigate(`/compare/${favoriteFacilityNames.join("-vs-")}`);
                }
              }}
              disabled={favoriteFacilityNames.length > 4 ? true : false}
            >
              Compare facilities <IoIosArrowRoundForward />
            </button>
            {favoriteFacilityNames.length > 4 && (
              <span className="text-smalltext">
                <span>*</span>Maximum 4 facilities can be compaired at a time.
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyFavorite;
