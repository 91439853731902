import { City } from "../../modules/city";
interface CityCompareSlideProps {
  entity: City;
}

const CityCompareSlide = ({ entity }: CityCompareSlideProps) => {
  return (
    <div className="w-[320px] lg:w-[390px] text-left flex flex-col gap-[20px] shrink-0">
      <div className={`bg-[#ECECEC]  block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {entity.city_rating}
        </div>
        CV Rating
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {Math.round(100 - parseFloat(entity.affordability_index))}%
        </div>
        Saving on average
      </div>
      <div className={` bg-[#ECECEC]  block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {entity.region_name ? entity.region_name : "-"}
        </div>
        Region
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {entity.city_name != "USA"
            ? `${(
                Math.round(entity.dist_from_nyc ? entity.dist_from_nyc : 1) /
                1.609344
              ).toFixed(0)}`
            : "0"}{" "}
          miles
        </div>
        Travel Distance
      </div>
      <div className={` bg-[#ECECEC]  block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {/* {entity.visa_required === "1" ? "YES" : "NO"} */}
          {entity?.visa_required != "5" ? "Visa required" : `Visa free`}
        </div>
        Visa Required
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {"-"}
        </div>
        Languages Spoken
      </div>
      <div className={` bg-[#ECECEC]   block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] min-h-[200px] max-h-[70px] lg:text-[18px]/[26px] font-medium overflow-y-scroll">
          {entity.average_weather ? entity.average_weather : "-"}
        </div>
        Climate and Weather
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {entity.exchange_rate ? entity.exchange_rate : "-"}
        </div>
        Currency and Exchange rate
      </div>
      <div className={`bg-[#ECECEC]  block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          -
        </div>
        Local Attractions
      </div>
    </div>
  );
};
export default CityCompareSlide;
