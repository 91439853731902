import { useEffect, useState } from "react";
import { MdOutlineSegment, MdAirlineSeatIndividualSuite } from "react-icons/md";
import { TbLicense } from "react-icons/tb";
import { IoLanguage } from "react-icons/io5";
import { LiaCcVisa } from "react-icons/lia";
import { GrNext } from "react-icons/gr";
import { TiWeatherCloudy, TiWeatherSunny } from "react-icons/ti";
import { LiaCertificateSolid } from "react-icons/lia";
import { PiMedalFill } from "react-icons/pi";
import {
  FaHandHoldingMedical,
  FaLanguage,
  FaStethoscope,
} from "react-icons/fa";
import { FaHands } from "react-icons/fa";
import { IoStar } from "react-icons/io5";
import { FaLocationDot, FaUserDoctor } from "react-icons/fa6";
import { TiPlus } from "react-icons/ti";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useRef } from "react";
import CureValueRating from "../shared/CureValueRating";

const OverviewFilter = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [activeButton, setActiveButton] = useState<string | null>(null);
  const sliderRef = useRef<any>(null);
  const currentSectionRef = useRef<string | null>(null); // To track current section
  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <GrNext />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const handleScroll = () => {
      const OverviewFilterElement = document.getElementById("overfiewfilter");
      if (!OverviewFilterElement) return; // Null check
      const filterOffset = OverviewFilterElement.offsetTop;
      const scrollTop = window.pageYOffset;

      setIsFixed(scrollTop > filterOffset);

      const sections = [
        "Description",
        "Overview",
        "Language",
        "Visa Required",
        "Weather",
        "Accreditations",
        "Ambience",
        "Treatments",
        "Amenities",
        "Careteam",
      ];
      const windowHeight = window.innerHeight;

      for (const section of sections) {
        const sectionElement = document.getElementById(
          section.toLowerCase().replace(" ", "-")
        );
        const sectionBtnElement: any = document.getElementById(
          (section + "-btn").toLowerCase().replace(" ", "-")
        );

        let scrollPosition = 0;
        if (sectionBtnElement) {
          scrollPosition =
            sectionBtnElement.offsetLeft -
            window.innerWidth / 2 +
            sectionBtnElement.offsetWidth / 2;
        }

        if (!sectionElement) continue;

        const sectionOffset = sectionElement.offsetTop;
        const sectionHeight = sectionElement.offsetHeight;
        const sectionMiddle = sectionOffset + sectionHeight / 2;
        const scrollMiddle = scrollTop + windowHeight / 2;

        if (
          scrollMiddle >= sectionOffset + 50 &&
          scrollMiddle < sectionOffset + sectionHeight + 50
        ) {
          if (currentSectionRef.current !== section) {
            currentSectionRef.current = section;
            let slk = Math.floor(
              scrollPosition / sectionBtnElement.offsetWidth
            );
            sliderRef.current.slickGoTo(slk - 2);
            setActiveButton(section);
          }
          return;
        }
      }

      setActiveButton(null);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleButtonClick = (buttonName: any) => {
    setActiveButton(buttonName); // Set active button
    // Scroll to corresponding section
    const sectionElement = document.getElementById(
      buttonName.toLowerCase().replace(" ", "-")
    );
    if (sectionElement) {
      //   sectionElement.scrollIntoView({ behavior: "smooth" });
      const offset = 300; // Adjust this value to the desired offset
      const elementPosition = sectionElement.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - offset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="hidden lg:block">
      <div
        id="overfiewfilter"
        className={`w-[100%] z-10 overview-fil  ${
          isFixed ? "fixed top-[140px] bg-[#fcfcfc] pt-[30px] shadow-sm" : ""
        }`}
      >
        <div className="container">
          {/* desktop  */}
          <div
            className={`hidden lg:grid grid-cols-10 gap-[20px] w-[350%] lg:w-[100%] overflow-hidden filters ${
              isFixed
                ? "border-0 pb-[20px]"
                : "border-b border-borderclr pb-[43px]"
            }`}
          >
            <div
              className="min-w-[152px] flex flex-col items-center text-center gap-[5px] cursor-pointer group"
              //   id="overview-btn"
              onClick={() => handleButtonClick("Overview")}
            >
              <MdOutlineSegment
                size={"35px"}
                className={`group-hover:text-[#000000] ${
                  activeButton === "Overview"
                    ? "text-[#000000]"
                    : "text-[#1B2B41B0]"
                }`}
              />
              <a
                className={`text-[${
                  activeButton == "Overview" ? "#000000" : "#1B2B41B0"
                }]  ${
                  activeButton == "Overview" ? "border-b border-black" : ""
                } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
              >
                City Overview
              </a>
            </div>

            <div
              className="min-w-[152px] flex flex-col items-center text-center gap-[5px] cursor-pointer group"
              //   id="description-btn"
              onClick={() => handleButtonClick("Description")}
            >
              <MdOutlineSegment
                size={"35px"}
                className={`group-hover:text-[#000000] ${
                  activeButton === "Description"
                    ? "text-[#000000]"
                    : "text-[#1B2B41B0]"
                }`}
              />
              <a
                className={`text-[${
                  activeButton == "Description" ? "#000000" : "#1B2B41B0"
                }]  ${
                  activeButton == "Description" ? "border-b border-black" : ""
                } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
              >
                Facility Description
              </a>
            </div>

            <div
              className="min-w-[152px] flex flex-col items-center text-center gap-[5px] cursor-pointer group"
              //   id="treatments-btn"
              onClick={() => handleButtonClick("Treatments")}
            >
              <FaHandHoldingMedical
                size={"35px"}
                className={`group-hover:text-[#000000] ${
                  activeButton === "Treatments"
                    ? "text-[#000000]"
                    : "text-[#1B2B41B0]"
                }`}
              />
              <a
                className={`text-[${
                  activeButton == "Treatments" ? "#000000" : "#1B2B41B0"
                }]  ${
                  activeButton == "Treatments" ? "border-b border-black" : ""
                } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
              >
                Treatments
              </a>
            </div>

            <div
              className="min-w-[152px] flex flex-col items-center text-center gap-[5px] cursor-pointer group"
              //   id="careteam-btn"
              onClick={() => handleButtonClick("Careteam")}
            >
              <FaUserDoctor
                size={"35px"}
                className={`group-hover:text-[#000000] ${
                  activeButton === "Careteam"
                    ? "text-[#000000]"
                    : "text-[#1B2B41B0]"
                }`}
              />
              <a
                className={`text-[${
                  activeButton == "Careteam" ? "#000000" : "#1B2B41B0"
                }]  ${
                  activeButton == "Careteam" ? "border-b border-black" : ""
                } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
              >
                Medical Specialists
              </a>
            </div>

            <div
              className="min-w-[152px] flex flex-col items-center text-center gap-[5px] cursor-pointer group"
              //   id="weather-btn"
              onClick={() => handleButtonClick("Accreditations")}
            >
              <PiMedalFill
                size={"35px"}
                className={`group-hover:text-[#000000] ${
                  activeButton === "Accreditations"
                    ? "text-[#000000]"
                    : "text-[#1B2B41B0]"
                }`}
              />
              <a
                className={`text-[${
                  activeButton == "Accreditations" ? "#000000" : "#1B2B41B0"
                }]  ${
                  activeButton == "Accreditations"
                    ? "border-b border-black"
                    : ""
                } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
              >
                Accreditations
              </a>
            </div>

            <div
              className="min-w-[152px] flex flex-col items-center text-center gap-[5px] cursor-pointer group"
              //   id="amenities-btn"
              onClick={() => handleButtonClick("Amenities")}
            >
              <MdAirlineSeatIndividualSuite
                size={"35px"}
                className={`group-hover:text-[#000000] ${
                  activeButton === "Amenities"
                    ? "text-[#000000]"
                    : "text-[#1B2B41B0]"
                }`}
              />
              <a
                className={`text-[${
                  activeButton == "Amenities" ? "#000000" : "#1B2B41B0"
                }]  ${
                  activeButton == "Amenities" ? "border-b border-black" : ""
                } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
              >
                Amenities
              </a>
            </div>

            <div
              className="min-w-[152px] flex flex-col items-center text-center gap-[5px] cursor-pointer group"
              //   id="language-btn"
              onClick={() => handleButtonClick("Visa Required")}
            >
              <TbLicense
                size={"35px"}
                className={`group-hover:text-[#000000] ${
                  activeButton === "Visa Required"
                    ? "text-[#000000]"
                    : "text-[#1B2B41B0]"
                }`}
              />
              <a
                className={`text-[${
                  activeButton == "Visa Required" ? "#000000" : "#1B2B41B0"
                }]  ${
                  activeButton == "Visa Required" ? "border-b border-black" : ""
                } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
              >
                Visa Required
              </a>
            </div>

            <div
              className="min-w-[152px] flex flex-col items-center text-center gap-[5px] cursor-pointer group"
              //   id="language-btn"
              onClick={() => handleButtonClick("Language")}
            >
              <FaLanguage
                size={"35px"}
                className={`group-hover:text-[#000000] ${
                  activeButton === "Language"
                    ? "text-[#000000]"
                    : "text-[#1B2B41B0]"
                }`}
              />
              <a
                className={`text-[${
                  activeButton == "Language" ? "#000000" : "#1B2B41B0"
                }]  ${
                  activeButton == "Language" ? "border-b border-black" : ""
                } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
              >
                Languages
              </a>
            </div>

            <div
              className="min-w-[152px] flex flex-col items-center text-center gap-[5px] cursor-pointer group"
              //   id="weather-btn"
              onClick={() => handleButtonClick("Weather")}
            >
              <TiWeatherSunny
                size={"35px"}
                className={`group-hover:text-[#000000] ${
                  activeButton === "Weather"
                    ? "text-[#000000]"
                    : "text-[#1B2B41B0]"
                }`}
              />
              <a
                className={`text-[${
                  activeButton == "Weather" ? "#000000" : "#1B2B41B0"
                }]  ${
                  activeButton == "Weather" ? "border-b border-black" : ""
                } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
              >
                Weather
              </a>
            </div>

            {/* <div
              className="flex flex-col items-center text-center gap-[5px] cursor-pointer group"
              //   id="ambience-btn"
              onClick={() => handleButtonClick("Ambience")}
            >
              <MdOutlineSegment
                size={"35px"}
                className={`group-hover:text-[#000000] ${
                  activeButton === "Ambience"
                    ? "text-[#000000]"
                    : "text-[#1B2B41B0]"
                }`}
              />
              <a
                className={`text-[${
                  activeButton == "Ambience" ? "#000000" : "#1B2B41B0"
                }]  ${
                  activeButton == "Ambience" ? "border-b border-black" : ""
                } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
              >
                Ambience
              </a>
            </div> */}
          </div>

          {/* mobile  */}

          <div className="block lg:hidden pb-[43px]">
            <div className="relative overview-slider">
              <Slider {...settings} ref={sliderRef}>
                <div
                  id="overview-btn"
                  onClick={() => handleButtonClick("Overview")}
                >
                  <div className="flex flex-col items-center text-center gap-[5px] cursor-pointer group">
                    <MdOutlineSegment
                      size={"35px"}
                      className={`group-hover:text-[#000000] text-[${
                        activeButton == "Overview" ? "#000000" : "#1B2B41B0"
                      }]`}
                    />
                    <a
                      className={`text-[${
                        activeButton == "Overview" ? "#000000" : "#1B2B41B0"
                      }]  ${
                        activeButton == "Overview"
                          ? "border-b border-black"
                          : ""
                      } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
                    >
                      City Overview
                    </a>
                  </div>
                </div>

                <div
                  id="description-btn"
                  onClick={() => handleButtonClick("Description")}
                >
                  <div className="flex flex-col items-center text-center gap-[5px] cursor-pointer group">
                    <MdOutlineSegment
                      size={"35px"}
                      className={`group-hover:text-[#000000] text-[${
                        activeButton == "Description" ? "#000000" : "#1B2B41B0"
                      }]`}
                    />
                    <a
                      className={`text-[${
                        activeButton == "Description" ? "#000000" : "#1B2B41B0"
                      }]  ${
                        activeButton == "Description"
                          ? "border-b border-black"
                          : ""
                      } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
                    >
                      Facility Description
                    </a>
                  </div>
                </div>

                <div
                  id="treatments-btn"
                  onClick={() => handleButtonClick("Treatments")}
                >
                  <div className="flex flex-col items-center text-center gap-[5px] cursor-pointer group">
                    <FaHandHoldingMedical
                      size={"35px"}
                      className={`group-hover:text-[#000000] text-[${
                        activeButton == "Treatments" ? "#000000" : "#1B2B41B0"
                      }]`}
                    />
                    <a
                      className={`text-[${
                        activeButton == "Treatments" ? "#000000" : "#1B2B41B0"
                      }]  ${
                        activeButton == "Treatments"
                          ? "border-b border-black"
                          : ""
                      } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
                    >
                      Treatments
                    </a>
                  </div>
                </div>

                <div
                  id="careteam-btn"
                  onClick={() => handleButtonClick("Careteam")}
                >
                  <div className="flex flex-col items-center text-center gap-[5px] cursor-pointer group">
                    <FaUserDoctor
                      size={"35px"}
                      className={`group-hover:text-[#000000] text-[${
                        activeButton == "Careteam" ? "#000000" : "#1B2B41B0"
                      }]`}
                    />
                    <a
                      className={`text-[${
                        activeButton == "Careteam" ? "#000000" : "#1B2B41B0"
                      }]  ${
                        activeButton == "Careteam"
                          ? "border-b border-black"
                          : ""
                      } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
                    >
                      Medical Specialists
                    </a>
                  </div>
                </div>

                <div
                  id="accreditations-btn"
                  onClick={() => handleButtonClick("Accreditations")}
                >
                  <div className="flex flex-col items-center text-center gap-[5px] cursor-pointer group">
                    <PiMedalFill
                      size={"35px"}
                      className={`group-hover:text-[#000000] text-[${
                        activeButton == "Accreditations"
                          ? "#000000"
                          : "#1B2B41B0"
                      }]`}
                    />
                    <a
                      className={`text-[${
                        activeButton == "Accreditations"
                          ? "#000000"
                          : "#1B2B41B0"
                      }]  ${
                        activeButton == "Accreditations"
                          ? "border-b border-black"
                          : ""
                      } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
                    >
                      Accreditations
                    </a>
                  </div>
                </div>

                <div
                  id="amenities-btn"
                  onClick={() => handleButtonClick("Amenities")}
                >
                  <div className="flex flex-col items-center text-center gap-[5px] cursor-pointer group">
                    <MdAirlineSeatIndividualSuite
                      size={"35px"}
                      className={`group-hover:text-[#000000] text-[${
                        activeButton == "Amenities" ? "#000000" : "#1B2B41B0"
                      }]`}
                    />
                    <a
                      className={`text-[${
                        activeButton == "Amenities" ? "#000000" : "#1B2B41B0"
                      }]  ${
                        activeButton == "Amenities"
                          ? "border-b border-black"
                          : ""
                      } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
                    >
                      Amenities
                    </a>
                  </div>
                </div>

                <div
                  id="visa-required-btn"
                  onClick={() => handleButtonClick("Visa Required")}
                >
                  <div className="flex flex-col items-center text-center gap-[5px] cursor-pointer group">
                    <TbLicense
                      size={"35px"}
                      className={`group-hover:text-[#000000] text-[${
                        activeButton == "Visa Required"
                          ? "#000000"
                          : "#1B2B41B0"
                      }]`}
                    />
                    <a
                      className={`text-[${
                        activeButton == "Visa Required"
                          ? "#000000"
                          : "#1B2B41B0"
                      }]  ${
                        activeButton == "Visa Required"
                          ? "border-b border-black"
                          : ""
                      } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
                    >
                      Visa Required
                    </a>
                  </div>
                </div>

                <div
                  id="language-btn"
                  onClick={() => handleButtonClick("Language")}
                >
                  <div className="flex flex-col items-center text-center gap-[5px] cursor-pointer group">
                    <FaLanguage
                      size={"35px"}
                      className={`group-hover:text-[#000000] text-[${
                        activeButton == "Language" ? "#000000" : "#1B2B41B0"
                      }]`}
                    />
                    <a
                      className={`text-[${
                        activeButton == "Language" ? "#000000" : "#1B2B41B0"
                      }]  ${
                        activeButton == "Language"
                          ? "border-b border-black"
                          : ""
                      } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
                    >
                      Languages
                    </a>
                  </div>
                </div>

                <div
                  id="weather-btn"
                  onClick={() => handleButtonClick("Weather")}
                >
                  <div className="flex flex-col items-center text-center gap-[5px] cursor-pointer group">
                    <TiWeatherSunny
                      size={"35px"}
                      className={`group-hover:text-[#000000] text-[${
                        activeButton == "Weather" ? "#000000" : "#1B2B41B0"
                      }]`}
                    />
                    <a
                      className={`text-[${
                        activeButton == "Weather" ? "#000000" : "#1B2B41B0"
                      }]  ${
                        activeButton == "Weather" ? "border-b border-black" : ""
                      } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
                    >
                      Weather
                    </a>
                  </div>
                </div>

                {/* <div
                  id="ambience-btn"
                  onClick={() => handleButtonClick("Ambience")}
                >
                  <div className="flex flex-col items-center text-center gap-[5px] cursor-pointer group">
                    <MdOutlineSegment
                      size={"35px"}
                      className={`group-hover:text-[#000000] text-[${
                        activeButton == "Ambience" ? "#000000" : "#1B2B41B0"
                      }]`}
                    />
                    <a
                      className={`text-[${
                        activeButton == "Ambience" ? "#000000" : "#1B2B41B0"
                      }]  ${
                        activeButton == "Ambience"
                          ? "border-b border-black"
                          : ""
                      } group-hover:text-[#000000] group-hover:border-b group-hover:border-b-[#000000] text-[11px] font-semibold lg:text-[12px] 2xl:text-[15px] lg:font-bold font-sans`}
                    >
                      Ambience
                    </a>
                  </div>
                </div> */}
              </Slider>

              <div className="slider-arrows flex items-center absolute right-[-10px] top-0 bottom-0 my-auto">
                <div className="next-arrow" onClick={handleNextClick}>
                  <GrNext
                    size={"20px"}
                    className="text-iconclr2 cursor-pointer hover:text-hoverclr"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewFilter;
