import { EventEmitter } from "fbemitter";
import { useEffect } from "react";
import { CallbackFunction } from "../../../helpers/ServiceWrapper";

export interface INotification {
  message: string;
  type: "ERROR" | "WARNING" | "INFO";
}
class CVEventEmmiter extends EventEmitter {
  cvNotify<INotification>(e: INotification) {
    this.emit("cvNotify", e);
  }
}
const eventEmmiter = new CVEventEmmiter();

export const useEventEmmiter = () => {
  return eventEmmiter;
};
export const useEventSubscripton = (
  eventName: string,
  cbFunction: CallbackFunction<any>
) => {
  return eventEmmiter.addListener(eventName, cbFunction);
};

export const useCvNotiication = (
  cbFunction: CallbackFunction<INotification>
) => {
  const listner = eventEmmiter.addListener("cvNotify", cbFunction);
  useEffect(() => {
    return () => {
      listner.remove();
    };
  }, [listner]);

  return { listner };
};
