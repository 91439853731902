const API_URL = process.env.REACT_APP_BACKEND_BASEURL;

export const API_USER_AUTH_URL = `${API_URL}/user/auth/`;

export const API_USER_URL = `${API_URL}/user/`;

export const API_SUBTREATMENT_URL = `${API_URL}/subTreatment/`;

export const API_TREATMENT_URL = `${API_URL}/treatment/`;

export const API_CITY_URL = `${API_URL}/city/`;

export const API_COMMENT_URL = `${API_URL}/comment/`;

export const API_REPLY_URL = `${API_URL}/reply/`;

export const API_COUNTRY_URL = `${API_URL}/country/`;

export const API_FACILITY_URL = `${API_URL}/facility/`;

export const API_DOCTOR_URL = `${API_URL}/doctor/`;

export const API_ACCREDITATION_URL = `${API_URL}/accreditation/`;

export const API_TREATMENT_COST_URL = `${API_URL}/treatmentCost/`;

export const API_FAVORITE_URL = `${API_URL}/userFavourite/`;

export const API_PAYMENT_URL = `${API_URL}/payment/`;

export const CMS_API_BASEURL = `${process.env.REACT_APP_CMS_API_BASEURL}/spaces/${process.env.REACT_APP_CMS_SPACE_ID}/environments/${process.env.REACT_APP_CMS_ENVIRONMENT_ID}`;

export const CITY_IMAGE_CDN = `https://dat1iz0i6xycu.cloudfront.net/city_images/`;

export const CV_DATA_CDN = `https://dat1iz0i6xycu.cloudfront.net/curevalue_data/`;

export const FACILITY_IMAGE_CDN = `https://dat1iz0i6xycu.cloudfront.net/facility_images/`;

export const API_REQUEST_CALLBACK = `${API_URL}/requestCallback/`;
