import gallery1 from "../../../assets/images/hospital1.png";
import gallery2 from "../../../assets/images/hospital2.png";
import gallery3 from "../../../assets/images/hospital3.png";
import no_image from "../../../assets/images/no_image.jpg";
import doctorimg from "../../../assets/images/doctorimg.png";
import doctorimgmob from "../../../assets/images/Avatar.png";
import mobilegalleryimg from "../../../assets/images/mobile-gallery-img.png";
import { AiFillCloseCircle } from "react-icons/ai";
import React, { useState, useEffect, useRef } from "react";
import { IoMdLock } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { useAuth } from "../../modules/auth";
import { FACILITY_IMAGE_CDN } from "../shared/urlConstants";
import defaultFacilityImage from "../../../assets/images/cv-hospital-illustration.jpg";
import CureValueRating from "../shared/CureValueRating";
import trustimg from "../../../assets/images/trustmark4.svg";
import { useTreatment } from "../../modules/treatment";

const HospitalGallery = ({
  facility,
  doctorCount,
  isBeforeLogin,
  handleShowSignUpPopup,
}: any) => {
  const { currentUser } = useAuth();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [imageCount, setImageCount] = useState(facility.image_urls.length - 4);
  const sliderRef = useRef<any>(null);
  const { selectedTreatment }: any = useTreatment();

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };
  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    if (isOpenModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpenModal]);

  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  var settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <MdKeyboardArrowLeft />,
    nextArrow: <MdKeyboardArrowRight />,
    afterChange: (currentSlide: number) => setActiveSlide(currentSlide),
  };

  const handleLockBtnClick = () => {
    handleShowSignUpPopup();
  };

  return (
    <div>
      {/* mobile gallery start */}
      <div className="block pt-[11px] lg:hidden relative overflow-hidden">
        <Slider {...settings} ref={sliderRef}>
          {facility.image_urls.map((image: any) => {
            return (
              <div className="gallery-img" key={Math.random()}>
                <img
                  src={`${FACILITY_IMAGE_CDN + facility?._id + "/" + image}`}
                  alt={`Top ${selectedTreatment.treatment.toLowerCase()} treatment facility`}
                  className="w-[100%] h-[100%] max-h-[280px] object-contain bg-[#5f697b]"
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = defaultFacilityImage;
                  }}
                />
              </div>
            );
          })}
        </Slider>

        {/* rating badge start */}
        {/* <div className="absolute top-[20px] right-[10px] lg:hidden">
          <img src={trustimg} className="w-[80px] h-[80px]" />
          <p className="absolute text-[18px] font-semibold text-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] mx-auto">
            {Math.round(parseFloat(facility.curevalue_rating))}
          </p>
        </div> */}
        {/* rating badge end */}
        <div className="slider-arrows flex items-center justify-between w-[90%] absolute left-0 right-0 top-0 bottom-0 m-auto">
          <div className="prev-arrow" onClick={handlePrevClick}>
            <MdKeyboardArrowLeft
              size={"45px"}
              className="text-white cursor-pointer"
            />
          </div>
          <div className="next-arrow" onClick={handleNextClick}>
            <MdKeyboardArrowRight
              size={"45px"}
              className="text-white cursor-pointer"
            />
          </div>
        </div>
        <div className="flex items-center justify-between w-[90%] bg-transparent absolute bottom-[20px] left-[0] right-[0] mx-auto">
          <div className="flex items-center justify-center rounded-full w-fit py-[5px] pr-[15px]">
            {/* <img src={doctorimgmob} alt="" className="max-w-[100px]" /> */}
            {/* <p className="text-h4font text-cradpara font-semibold">
              {doctorCount}+ Medical Specialists
            </p> */}

            {/* {doctorCount < 1 ? (
              <>
                <p className="text-h4font text-cradpara font-semibold">
                  Medical Specialists
                </p>
              </>
            ) : (
              <>
                <p className="text-h4font text-cradpara font-semibold">
                  {doctorCount}+ Medical specialists
                </p>
              </>
            )} */}
          </div>
          <div className="flex items-center justify-center rounded-[5px] w-fit py-[5px] px-[15px] bg-[#1A293DD4]">
            <p className="text-[15px] text-white font-semibold">
              {activeSlide + 1}/5
            </p>
          </div>
        </div>
      </div>

      {/* rating badge start */}
      <div className="lg:hidden flex justify-center w-[100%] ">
        <div className="relative">
          <img src={trustimg} className="w-[80px] h-[80px]" />
          <p className="absolute text-[18px] font-semibold text-white top-[50%] left-[49%] translate-x-[-50%]  translate-y-[-50%] mx-auto">
            {Math.round(parseFloat(facility.curevalue_rating))}
          </p>
        </div>
      </div>
      {/* rating badge end */}
      {/* mobile gallery ends  */}

      <div className="container">
        {/* desktop gallery starts  */}
        <div className="hidden lg:flex items-center gap-[15px] pb-[70px]">
          <div
            className="flex items-center gap-[8px]  w-[70%] cursor-pointer"
            onClick={!isBeforeLogin ? toggleModal : handleLockBtnClick}
          >
            {/* display 3 images on details page if available */}
            {facility.image_urls.length > 2 ? (
              <>
                <div className="gallery-img w-[70%]">
                  <img
                    src={`${
                      FACILITY_IMAGE_CDN +
                      facility?._id +
                      "/" +
                      facility.image_urls[0]
                    }`}
                    alt={`Top ${selectedTreatment.treatment.toLowerCase()} treatment facility`}
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = defaultFacilityImage;
                    }}
                    className=" h-[440px] rounded-tl-[30px] rounded-bl-[30px] object-contain bg-[#5f697b] w-full"
                  />
                </div>
                <div className="flex flex-col gap-[8px] w-[30%]">
                  <div className="gallery-img">
                    <img
                      src={`${
                        FACILITY_IMAGE_CDN +
                        facility?._id +
                        "/" +
                        facility.image_urls[1]
                      }`}
                      alt={`Top ${selectedTreatment.treatment.toLowerCase()} treatment facility`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = defaultFacilityImage;
                      }}
                      className="w-[100%] h-[210px] max-w-[346] rounded-tr-[30px] object-contain bg-[#5f697b]"
                    />
                  </div>
                  <div className="gallery-img relative flex items-center justify-center">
                    <img
                      src={`${
                        FACILITY_IMAGE_CDN +
                        facility?._id +
                        "/" +
                        facility.image_urls[2]
                      }`}
                      alt={`Top ${selectedTreatment.treatment.toLowerCase()} treatment facility`}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = defaultFacilityImage;
                      }}
                      className="w-[100%] h-[220px] max-w-[346] rounded-br-[30px] object-contain bg-[#5f697b]"
                    />
                    <p className="absolute z-1 text-[37px] text-white font-medium flex items-center">
                      {/* {currentUser ? (
                        <></>
                      ) : (
                        <>
                          <div className=" z-[5]">
                            <div onClick={handleLockBtnClick}>
                              <IoMdLock size={"37px"} />
                            </div>
                          </div>
                        </>
                      )} */}
                      {imageCount}+ Images
                    </p>
                  </div>
                </div>
              </>
            ) : facility.image_urls.length == 2 ? (
              <>
                <div className="gallery-img">
                  <img
                    src={`${
                      FACILITY_IMAGE_CDN +
                      facility?._id +
                      "/" +
                      facility.image_urls[0]
                    }`}
                    alt={`Top ${selectedTreatment.treatment.toLowerCase()} treatment facility`}
                    className="w-[654px] h-[440px] max-w-[704px] rounded-tl-[30px] rounded-bl-[30px]"
                  />
                </div>
                <div className="flex flex-col gap-[8px]">
                  <div className="gallery-img">
                    <img
                      src={`${
                        FACILITY_IMAGE_CDN +
                        facility?._id +
                        "/" +
                        facility.image_urls[1]
                      }`}
                      alt={`Top ${selectedTreatment.treatment.toLowerCase()} treatment facility`}
                      className="w-[100%] h-[210px] max-w-[346] rounded-tr-[30px]"
                    />
                  </div>
                  <div className="gallery-img relative flex items-center justify-center">
                    <img
                      src={no_image}
                      alt={`Top ${selectedTreatment.treatment.toLowerCase()} treatment facility`}
                      className="w-[100%] h-[220px] max-w-[346] rounded-br-[30px]"
                    />
                    {/* <p className="absolute z-1 text-[37px] text-white font-medium flex items-center">
                      <IoMdLock size={"37px"} />
                      8+ Images
                    </p> */}
                  </div>
                </div>
              </>
            ) : facility.image_urls.length == 1 ? (
              <>
                <div className="gallery-img">
                  <img
                    src={`${
                      FACILITY_IMAGE_CDN +
                      facility?._id +
                      "/" +
                      facility.image_urls[0]
                    }`}
                    alt={`Top ${selectedTreatment.treatment.toLowerCase()} treatment facility`}
                    className="w-[654px] h-[440px] max-w-[704px] rounded-tl-[30px] rounded-bl-[30px] rounded-tr-[30px] rounded-br-[30px]"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="gallery-img">
                  <img
                    src={no_image}
                    alt={`Top ${selectedTreatment.treatment.toLowerCase()} treatment facility`}
                    className="w-[1004px] h-[440px] max-w-[704px] rounded-tl-[30px] rounded-bl-[30px] rounded-tr-[30px] rounded-br-[30px]"
                  />
                </div>
              </>
            )}
          </div>
          <div className="flex-col items-center justify-center w-[30%]">
            {/* <h3 className="lg:text-h2font/[35px] font-medium text-center max-w-[80%] m-auto">
              {doctorCount < 1 ? (
                <>
                  <span className="lg:text-h2font/[38px] font-semibold">
                    Medical specialists
                  </span>
                </>
              ) : (
                <>
                  <span>More than </span>
                  <span className="lg:text-h2font/[38px] font-semibold">
                    {doctorCount}+ Medical specialists
                  </span>
                </>
              )}
            </h3> */}
            {/* <img
              src={doctorimg}
              alt=""
              className="m-auto  xl:max-w-[70%] 2xl:max-w-[371px]"
            /> */}
            {/* <CureValueRating
              rating={facility.curevalue_rating}
              size="xl"
            ></CureValueRating> */}

            {/* rating badge start */}
            <div className="flex w-[100%] justify-center">
              <div className="relative">
                <img src={trustimg} className="w-[300px] h-[300px]" />
                <p className="absolute text-[40px] font-semibold text-white top-[50%] left-[49%] translate-x-[-50%]  translate-y-[-50%] mx-auto">
                  {Math.round(parseFloat(facility.curevalue_rating))}
                </p>
              </div>
            </div>
            {/* rating badge end */}
          </div>
        </div>
        {/* desktop gallery ends  */}

        {isOpenModal && (
          <div className="modal-hospital z-20 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
            <div className="modal-hospital-content bg-white rounded-lg pb-[30px] w-[60%] relative">
              <div className="max-h-[600px] overflow-y-scroll relative">
                <div className="flex items-center gap-[30px] sticky top-0 bg-pc z-10 p-[10px]">
                  <span className="close cursor-pointer" onClick={toggleModal}>
                    <AiFillCloseCircle size={"40px"} className="text-white" />
                  </span>
                  <p className="text-h3font text-white font-medium px-[10px]">
                    Photo gallery
                  </p>
                </div>
                <div className="px-[20px]">
                  <div className="my-[25px]">
                    <h2 className="text-regsize font-semibold px-[10px]">
                      All Photos
                    </h2>
                  </div>

                  <div className="grid grid-cols-2 gap-[15px] px-[10px]">
                    {facility.image_urls.map((image: any) => {
                      return (
                        <>
                          <img
                            src={`${
                              FACILITY_IMAGE_CDN + facility?._id + "/" + image
                            }`}
                            alt={`Top ${selectedTreatment.treatment.toLowerCase()} treatment facility`}
                            className="w-[100%] h-[300px]"
                          />
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HospitalGallery;
