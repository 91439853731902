import { FC } from "react";

import { IntlProvider } from "react-intl";
// import "@formatjs/intl-relativetimeformat/polyfill";
// import "@formatjs/intl-relativetimeformat/locale-data/en";
// import "@formatjs/intl-relativetimeformat/locale-data/de";
// import "@formatjs/intl-relativetimeformat/locale-data/es";
// import "@formatjs/intl-relativetimeformat/locale-data/fr";
// import "@formatjs/intl-relativetimeformat/locale-data/ja";
// import "@formatjs/intl-relativetimeformat/locale-data/zh";

import enMessages from "../../../assets/i18n/messages/en.json";
import { WithChildren } from "../../../helpers";
import { useLang } from "../../../helpers/i18nHelper";

const allMessages = {
  en: enMessages,
};

const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export { I18nProvider };
