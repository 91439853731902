import axios from "axios";
import { BaseResponse } from "../../common";
import { API_REPLY_URL } from "../../../components/shared/urlConstants";

export function createReply(payload: any) {
  return axios
    .post<any, BaseResponse<any>>(`${API_REPLY_URL}create`, payload)
    .then((response) => {
      return response.data;
    });
}
