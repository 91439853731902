import { MouseEvent, useState } from "react";
import { HiCurrencyDollar } from "react-icons/hi2";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { MdChromeReaderMode } from "react-icons/md";
// import flagimg from "../../../assets/images/flags/";
import { BiSolidDownArrow, BiSolidRightArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import { useAuth } from "../../modules/auth";
import { City } from "../../modules/city/core/_models";
import {
  addToFavorite,
  removeFromFavorite,
} from "../../modules/favorite/core/FavoriteService";
import { AddRemoveFavoriteRequest } from "../../modules/favorite/core/_models";
import { CITY_IMAGE_CDN } from "../shared/urlConstants";
import cardimg from "../../../assets/images/city-default.png";

export type DestinationCardActionType = "VIEWMORE" | "CARDCLICK";
interface DestincationCardProps {
  isOpenModal?: boolean;
  setIsOpenModal?: () => void;
  handleShowSignUpPopup?: () => void;
  onClick?: (actionType: DestinationCardActionType) => void;
  city: City;
}

const Destinationcard = ({
  city: cityProp,
  onClick,
  handleShowSignUpPopup,
}: DestincationCardProps) => {
  const [city, setCity] = useState<City>(cityProp);
  const [showSignuptooltip, setShowSignuptooltip] = useState<boolean>(false);
  const navigate = useNavigate();
  const toggleModal = (e: MouseEvent) => {
    e.stopPropagation();
    if (onClick) {
      onClick("VIEWMORE");
    }
  };
  const { currentUser } = useAuth();
  const favoriteClickHandle = (e: MouseEvent) => {
    e.stopPropagation();
    if (currentUser) {
      changeFavorite();
    } else {
      handleShowSignUpPopup?.();
      // navigate("/auth/login");
    }
  };
  const changeFavorite = async () => {
    let payload: AddRemoveFavoriteRequest = {
      favourite_type: "city",
      city_id: city?._id,
    };
    if (city?.is_favourite == false || city?.is_favourite == undefined) {
      const { data, error } = await CureValueService(addToFavorite, payload);
      if (data) {
        if (city) {
          setCity({ ...city, is_favourite: true });
        }
      }
    } else {
      const { data, error } = await CureValueService(
        removeFromFavorite,
        payload
      );
      if (data) {
        if (city) {
          setCity({ ...city, is_favourite: false });
        }
      }
    }
  };
  const getAffordableText = (city: City) => {
    const affordabilityIndex = parseFloat(city.affordability_index);
    if (affordabilityIndex < 54) {
      return "Very affordable";
    } else if (affordabilityIndex < 86) {
      return "Affordable";
    } else if (affordabilityIndex < 118) {
      return "Moderate";
    } else if (affordabilityIndex < 151) {
      return "Expensive";
    }
  };

  return (
    <>
      <div onClick={toggleModal}>
        <div className=" w-[350px] lg:w-auto cursor-pointer border-[3px] border-borderclr hover:border-hoverclr rounded-[30px] px-[15px] pb-[19px] lg:px-[32px] lg:pb-[25px] bg-white relative pt-[110px] lg:pt-[140px] destinationcard">
          <img
            //src={cardimg}
            src={
              city?.image_name ? `${CITY_IMAGE_CDN + city.image_name}` : cardimg
            }
            alt={city?.city_name.toLocaleLowerCase()}
            // className="w-[90%] lg:max-w-[340px] lg:pb-[19px] m-auto absolute left-0 right-0 top-[-70px]"
            className="w-[90%] max-w-[340px]  m-auto absolute left-0 right-0  top-[-100px] lg:top-[-70px] h-[200px] rounded-[25px] object-cover"
          />
          <div
            className="absolute right-[30px] top-[-90px] lg:top-[-60px] lg:right-[70px] 2xl:right-[75px] text-[20px] p-[8px] bg-white text-hoverclr rounded-full cursor-pointer"
            onClick={(e) => favoriteClickHandle(e)}
          >
            {city?.is_favourite == false || city?.is_favourite == undefined ? (
              <IoMdHeartEmpty />
            ) : (
              <IoMdHeart />
            )}
          </div>
          {/* {showSignuptooltip && (
            <div className="absolute top-[-90px] right-[70px] px-2 border border-hoverclr bg-white rounded h-[30px] cursor-default">
              <span
                className="text-hoverclr cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate("/auth/signup");
                }}
              >
                Sign up
              </span>{" "}
              to mark as Favorite
              <BiSolidDownArrow className=" hidden lg:block absolute right-2 bottom-[-13px] text-hoverclr" />
              <BiSolidRightArrow className="absolute lg:hidden right-[-12px] top-[7px] text-hoverclr" />
            </div>
          )} */}
          {/* <div className="absolute top-[-20px] right-[60px] text-[10px] p-[8px] bg-white">Sign in to mark as Favorite</div> */}

          <p className="flex items-center m-auto xl:gap-[10px]  lg:gap-[5px] text-h6font lg:text-pfont rounded-full bg-[#1A38601A] xl:px-[59px]  lg:px-[30px] px-[40px] py-[5px]  lg:py-[8px] font-semibold justify-center w-fit">
            CureValue score :<span className="pr-2">{city?.city_rating}</span>
          </p>
          <div className="flex items-center justify-between lg:pt-[13px] 2xl:pt-[31px] pb-[8px] mt-[10px] lg:mt-[0]">
            <div className="flex items-center gap-[13px]">
              <p className="bg-[green] border-hoverclr border-1 border-solid text-white text-[15px] lg:text-h4font px-[15px] py-[5px] rounded-[50%]  w-[60px] h-[60px] font-semibold flex justify-center items-center">
                {Math.round(100 - parseFloat(city.affordability_index))}%
              </p>
              <p className="text-greyclr text-[10px] lg:text-smalltext ">
                Savings on average
              </p>
            </div>
            <div className="flex items-center gap-[13px]">
              {/* <IoMdInformationCircleOutline className="cursor-pointer text-[20px] text-iconclr2 lg:text-h5font" /> */}
              <img
                src={`${
                  process.env.PUBLIC_URL
                }/media/flags/Property 1=${city?.country_name.toLocaleLowerCase()}.svg`}
                className="w-[100%] max-w-[60px]"
              />
            </div>
          </div>

          <h2 className="text-sc text-h3font/[28px] lg:text-h5font/[38px] font-semibold pb-[10px]  ">
            <span className=" block text-ellipsis overflow-hidden whitespace-nowrap">
              {" "}
              {city?.city_name},
            </span>
            <div className="text-h4font lg:text-[25px]/[30px]">
              {city?.country_name}
            </div>
          </h2>
          <p className="font-medium text-smalltext lg:text-[15px] text-sc max-h-[10vh] lg:max-h-[15vh] overflow-y-auto text-justify pr-2">
            {city?.curevalue_overview}
          </p>
          <div className="grid grid-cols-2 gap-[13px] pb-[15px] mt-5">
            <button className="flex bg-[#e9ecf0] text-extraclr3 gap-[10px] items-center text-left pl-[11px] py-[7px] rounded-[30px] text-[10px] lg:text-smalltext font-medium  justify-center">
              <HiCurrencyDollar className="text-extraclr3 text-smalltext lg:text-[20px]" />
              {getAffordableText(city)}
            </button>
            <button className="flex bg-[#e9ecf0] text-extraclr3 gap-[10px] items-center text-left pl-[11px] py-[7px] rounded-[30px] text-[10px] lg:text-smalltext font-medium justify-center">
              <MdChromeReaderMode className="text-extraclr3 text-smalltext lg:text-[20px]" />
              {city?.visa_required != "5" ? "Visa required" : `Visa free`}
            </button>
          </div>
          <span
            className="text-smalltext lg:text-anchortext 2xl:text-pfont underline text-[#006AF5] font-medium cursor-pointer"
            onClick={toggleModal}
          >
            View more
          </span>
        </div>
      </div>
    </>
  );
};

export default Destinationcard;
