import { number } from "yup";
import FacilityCompareCard from "./FacilityCompareCard";
import { Facility } from "../../modules/facility";
import { City } from "../../modules/city";
interface FacilityCompareSlideProps {
  entity: Facility;
}

const FacilityCompareSlide = ({ entity }: FacilityCompareSlideProps) => {
  return (
    <div className="w-[320px] lg:w-[390px] text-left flex flex-col gap-[20px] shrink-0">
      <div className={`bg-[#ECECEC]  block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {entity.curevalue_rating ? entity.curevalue_rating : "-"}
        </div>
        CV Rating
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          -
        </div>
        Local attractions
      </div>
      <div className={` bg-[#ECECEC]  block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {entity.accreditations?.length != 0
            ? entity.accreditations?.join(",")
            : "-"}
        </div>
        Facility Accreditations
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {Math.round(100 - parseFloat(entity.affordability_index))}%
        </div>
        Savings on average than U.S.
      </div>
      <div className={` bg-[#ECECEC]  block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          -
        </div>
        Patient Satisfaction Ratings
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium ">
          {entity.timezone}
        </div>
        Time zone
      </div>
      <div className={` bg-[#ECECEC]   block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium min-h-[200px] max-h-[70px] overflow-auto">
          {entity.average_weather}
        </div>
        Weather
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {entity.exchange_rate}
        </div>
        Currency and Exchange rate
      </div>
      <div className={`bg-[#ECECEC]  block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          -
        </div>
        Availability of Telemedicine Services
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          -
        </div>
        Accessibility for Disabled Patients
      </div>
      <div className={`bg-[#ECECEC]   block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          -
        </div>
        Travel time to nearest airport
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          {entity.languages_spoken?.length != 0
            ? entity.languages_spoken?.join(",")
            : "-"}
        </div>
        Languages spoken
      </div>
      <div className={`bg-[#ECECEC]  block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          -
        </div>
        Video consultants before traveling
      </div>
      <div className={`block p-[10px] text-smalltext rounded`}>
        <div className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium">
          -
        </div>
        Post-Treatment Care Offered
      </div>
    </div>
  );
};
export default FacilityCompareSlide;
