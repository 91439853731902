import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="relative overflow-hidden bg-[#F5F7FA] pt-[70px] lg:pt-[80px]">
      Sitemap comming soon...
    </div>
  );
};

export default PrivacyPolicy;
