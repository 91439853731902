import axios from "axios";
import { BaseResponse, ListResponse } from "../../common";
import { API_COUNTRY_URL } from "../../../components/shared/urlConstants";
import { Country } from "./_models";

export const getCountryById = (payload: { country_id: string }) => {
  return axios
    .get<any, BaseResponse<any>>(
      `${API_COUNTRY_URL}getById?countryId=` + payload.country_id
    )
    .then((response) => {
      return response.data;
    });
};

export const getCountryByName = (payload: { country_name: string }) => {
  return axios
    .get<any, BaseResponse<any>>(
      `${API_COUNTRY_URL}getByName?countryName=` + payload.country_name
    )
    .then((response) => {
      return response.data;
    });
};
