import axios from "axios";
import { BaseResponse, ListResponse } from "../../common";
import { Doctor } from "./_models";
import { API_DOCTOR_URL } from "../../../components/shared/urlConstants";

//get all doctors by facility id
export function getById(payload: { facilityId: string }) {
  return axios
    .get<any, BaseResponse<ListResponse<Doctor>>>(
      `${API_DOCTOR_URL}getByFacilityId?facilityId=${payload.facilityId}`
    )
    .then((response) => {
      return response.data;
    });
}
