import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import AppRoutes from "./app/routing/AppRoutes";
// import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./app/modules/auth/core/AuthContext";
import axios from "axios";
import { setupAxios } from "./app/modules/auth";
import MessageContainer from "./app/components/shared/MessageContainer";
import ScrollToTop from "./app/components/shared/ScroolToTop";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  //gtmId: "GTM-WQ4HG64W",
  gtmId: "GTM-56332CGK",
};
if (process.env.REACT_APP_ENVIRONMENT != "DEV") {
  console.log("GTM initialized");
  TagManager.initialize(tagManagerArgs);
} else {
  console.log("GTM skipped for dev");
}

setupAxios(axios);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
