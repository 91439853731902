import { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { City, CountryWiseCity } from "../../modules/city";

type CountryAccordianProps = CountryWiseCity & {
  onCityClick: (city: City) => void;
  updateAllSelectedCities?: (
    country: string,
    selectedCities: Set<string>
  ) => void;
};

const CountryAccordian = ({
  country_name,
  total_facility_count,
  cities,
  onCityClick,
  updateAllSelectedCities,
}: CountryAccordianProps) => {
  const [isCityListVisible, setCityListVisible] = useState(false);
  const [selectedCities, setSelectedCities] = useState<Set<string>>(new Set());
  const [isCountrySelected, setCountrySelected] = useState(false);
  // const [allSelectedCities, setAllSelectedCities] = useState<Set<string>>(
  //   new Set()
  // );

  useEffect(() => {
    const savedSelectedCities = localStorage.getItem(
      `selectedCities_${country_name}`
    );
    if (savedSelectedCities) {
      const selectedCityArray: string[] = JSON.parse(savedSelectedCities);
      const selectedCitySet = new Set<string>(selectedCityArray);
      setSelectedCities(selectedCitySet);
      setCountrySelected(selectedCitySet.size === cities.length);
    }
  }, [country_name, cities.length]);

  const handleCountryCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.stopPropagation();
    const newSelectedCities = new Set(selectedCities);
    if (isCountrySelected) {
      cities.forEach((city) =>
        newSelectedCities.delete(city.city_name.toLowerCase())
      );
    } else {
      cities.forEach((city) =>
        newSelectedCities.add(city.city_name.toLowerCase())
      );
    }
    setSelectedCities(newSelectedCities);
    setCountrySelected(!isCountrySelected);
    if (updateAllSelectedCities) {
      updateAllSelectedCities(country_name, newSelectedCities);
    }
  };

  const handleCityCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    city_name: string
  ) => {
    e.stopPropagation();
    const newSelectedCities = new Set(selectedCities);
    if (newSelectedCities.has(city_name.toLowerCase())) {
      newSelectedCities.delete(city_name.toLowerCase());
    } else {
      newSelectedCities.add(city_name.toLowerCase());
    }
    setSelectedCities(newSelectedCities);
    setCountrySelected(newSelectedCities.size === cities.length);
    if (updateAllSelectedCities) {
      updateAllSelectedCities(country_name, newSelectedCities);
    }
  };

  useEffect(() => {
    updateAllSelectedCities?.(country_name, selectedCities);
  }, [selectedCities]);

  return (
    <>
      <div
        className="text-anchortext flex justify-start gap-[10px] items-center cursor-pointer hover:border-pc border-[1px] rounded p-[5px] border-transparent group border-b-pc"
        // onClick={() => setCityListVisible(!isCityListVisible)}
      >
        <input
          type="checkbox"
          checked={isCountrySelected}
          onChange={handleCountryCheckboxChange}
        />
        <img
          src={`${
            process.env.PUBLIC_URL
          }/media/flags/Property 1=${country_name.toLocaleLowerCase()}.svg`}
          className="w-[100%] max-w-[30px] rounded-[50px]"
          alt={`${country_name} flag`}
        />
        <p>{country_name}</p>
        {!isCityListVisible ? (
          <IoIosArrowDown onClick={() => setCityListVisible(true)} />
        ) : (
          <IoIosArrowUp onClick={() => setCityListVisible(false)} />
        )}
        <p> ({total_facility_count})</p>
      </div>
      {isCityListVisible &&
        cities.map((city: City) => {
          let selectedCity = city.city_name.toLowerCase();
          return (
            <div
              className="pl-[10px] flex justify-start gap-[10px] cursor-pointer hover:font-semibold text-anchortext"
              // onClick={() => onCityClick(city)}
              key={city.city_name}
            >
              <input
                type="checkbox"
                checked={selectedCities.has(selectedCity)}
                onChange={(e) => handleCityCheckboxChange(e, city.city_name)}
              />
              <p>{city.city_name}</p>
              <p>( {city.facilities_count} )</p>
            </div>
          );
        })}
    </>
  );
};

export default CountryAccordian;
