import { useEffect, useRef, useState } from "react";
import { FaMountainSun } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { CureValueService } from "../../helpers/ServiceWrapper";
import { getAllFavorites } from "../modules/favorite/core/FavoriteService";
import { City, GetByNameRequest, getCityByName } from "../modules/city";
import { deConstructURLSegment } from "../../helpers/CVHelper";
import CityCompareCard from "../components/city/CityCompareCard";
import FacilityCompareSlide from "../components/facility/FacilityCompareSlide";
import CityCompareSlide from "../components/city/CityCompareSlide";
import FavoritePopup, {
  FavoritePopupCloseEvent,
} from "../components/shared/FavoritePopup";
import { useAuth } from "../modules/auth";
import { useEventEmmiter } from "../components/shared/eventEmmiter";
import { FiShare } from "react-icons/fi";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { IoClose } from "react-icons/io5";
const usaCity: City = {
  _id: "",
  city_name: "",
  city_rating: "100",
  country_name: "USA",
  affordability_index: "100",
  region_name: "America",
  distance_index: "0",
  country_id: "",
  latitude: "",
  longitude: "",
  description: "",
  image_urls: "",
  is_favourite: true,
  region_id: "",
  updatedAt: "",
  visa_required: "5",
  curevalue_overview: "",
  exchange_rate: "-",
};
const CityCompare = () => {
  const { cityCompareNames } = useParams();
  const [favoriteCities, setFavoriteCities] = useState<City[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [isFavoritePopupOpen, setIsFavoritePopupOpen] = useState(false);
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [shareUrl, setShareUrl] = useState<any>(null);
  const eventEmmiter = useEventEmmiter();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  useEffect(() => {
    if (cityCompareNames) {
      const textToCopy =
        process.env.REACT_APP_BASEURL + "/compare-city/" + cityCompareNames;
      setShareUrl(textToCopy);
    }
    const cityNamesWithCountry = cityCompareNames?.split("-vs-");
    const cities: GetByNameRequest[] = [];
    cityNamesWithCountry?.forEach((nameWithCountry) => {
      const facilityNamesAndCityArray = nameWithCountry.split("-country-");
      cities.push({
        cityName: deConstructURLSegment(facilityNamesAndCityArray[0]),
        countryName: deConstructURLSegment(facilityNamesAndCityArray[1]),
      });
    });
    getCitiesDetailsByNames(cities);
    if (currentUser) {
      getMyFavorite();
    }
  }, []);
  useEffect(() => {
    if (isOpenModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpenModal]);
  const getMyFavorite = async (isOnLoad: boolean = false) => {
    const { data, error } = await CureValueService(getAllFavorites, null);
    if (data) {
      setFavoriteCities(data.allFavouriteCities);
    }
  };
  const getCitiesDetailsByNames = (payload: GetByNameRequest[]) => {
    const cityList: City[] = [];
    payload.forEach(async (request) => {
      const { data, error } = await CureValueService(getCityByName, request);
      if (data) {
        cityList.push(data);
      }
      if (cityList.length == payload.length) {
        setCities(cityList);
      }
    });
  };
  const onCityCloseClicked = (cityClosed: City) => {
    let newCityList = [];
    newCityList = cities.filter((city: City) => city._id != cityClosed._id);
    setCities([...newCityList]);
  };
  const handleonClose = (event: FavoritePopupCloseEvent | null) => {
    if (event && event.selectedCity) {
      setCities([...cities, event.selectedCity]);
    }
    setIsFavoritePopupOpen(false);
  };
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        eventEmmiter.cvNotify({
          message: "Link copied to clipboard successfully",
          type: "INFO",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const toggleSharePopup = (e: any) => {
    e.preventDefault();
    setIsOpenModal(!isOpenModal);
  };
  return (
    <>
      {isFavoritePopupOpen && currentUser && (
        <FavoritePopup
          exsistingComparedEntity={cities}
          typeOfFavorite="City"
          onClose={handleonClose}
          favoriteFacilities={[]}
          favoriteCities={favoriteCities}
        ></FavoritePopup>
      )}
      <div className="bg-[#F5F7FA] pt-[70px] lg:pt-[80px] pb-[70px]">
        <div className="lg:block lg:max-w-[1800px] m-auto px-[24px] pt-[30px] pb-[24px]">
          <div
            className={`flex items-center  pt-[10px] w-[100%] ${
              currentUser ? "justify-between" : "justify-end"
            }`}
          >
            {currentUser && (
              <div
                onClick={() => {
                  navigate("/myfavorites");
                }}
                className="flex items-center gap-[4px]  cursor-pointer"
              >
                <MdArrowBackIos className="text-extraclr3 text-[24px] lg:text-iconclr3 lg:text-[20px]" />
                <a className="text-pfont lg:text-[18px] font-medium text-sc font-sans whitespace-nowrap backbtn">
                  See all favorites
                </a>
              </div>
            )}
            <div className=" flex items-center gap-[22px]">
              <FiShare
                size={"30px"}
                className={`cursor-pointer ${
                  isFixed ? "text-white ease-in-out duration-300" : ""
                }`}
                onClick={(e) => {
                  toggleSharePopup(e);
                }}
              />

              {/* <FaBookmark className="cursor-pointer text-h4font lg:text-[30px]" /> */}
              {isOpenModal && (
                <div
                  className="z-[200] fixed top-0 left-0 w-full h-full flex justify-center lg:justify-end items-start bg-black bg-opacity-50"
                  onClick={(e) => {
                    toggleSharePopup(e);
                  }}
                >
                  <div className=" bg-white rounded-[24px] p-[20px] lg:py-[50px] lg:pl-[50px] w-[95%] lg:w-[30%] relative mt-[200px]">
                    <div className="absolute right-0 top-0 lg:right-[5%] lg:top-[5%]">
                      <span
                        className="close cursor-pointer"
                        onClick={(e) => {
                          toggleSharePopup(e);
                        }}
                      >
                        <IoClose size={"40px"} className="text-black" />
                      </span>
                    </div>
                    <div className="max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
                      <div className="lg:max-w-[90%] modal-hospital">
                        <h6 className=" text-[20px]  font-semibold text-cardpara text-center pt-[5px] pb-[25px]">
                          Share this city comparison
                        </h6>
                        <div className="grid lg:grid-cols-2 items-start gap-[20px] lg:gap-[40px]">
                          <div onClick={copyToClipboard}>
                            <h6 className="capitalize text-anchortext font-semibold text-cardpara border border-gray-400 rounded-[10px] cursor-pointer text-center pt-[5px] pb-[5px]">
                              Copy Link
                            </h6>
                          </div>
                          <div>
                            <h6 className="capitalize text-anchortext font-semibold text-cardpara border border-gray-400 rounded-[10px] cursor-pointer text-center pt-[5px] pb-[5px]">
                              <EmailShareButton
                                url={shareUrl}
                                subject="CureValue-Compare Cities"
                              >
                                Email
                              </EmailShareButton>
                            </h6>
                          </div>
                          <div>
                            <h6 className="capitalize text-anchortext font-semibold text-cardpara border border-gray-400 rounded-[10px] cursor-pointer text-center pt-[5px] pb-[5px]">
                              <FacebookShareButton url={shareUrl}>
                                Facebook
                              </FacebookShareButton>
                            </h6>
                          </div>
                          <div>
                            <h6 className="capitalize text-anchortext font-semibold text-cardpara border border-gray-400 rounded-[10px] cursor-pointer text-center pt-[5px] pb-[5px]">
                              <WhatsappShareButton
                                url={shareUrl}
                                title="CureValue-Compare Cities"
                              >
                                WhatsApp
                              </WhatsappShareButton>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-[20px] overflow-y-auto">
            <h2 className="text-[24px] lg:text-[26px] font-semibold mb-[11px]">
              Compare destinations
            </h2>
            <div className="flex gap-[50px] mt-[80px] justify-start ">
              <div className="w-[320px] lg:w-[390px] flex flex-col items-center justify-center shrink-0">
                <div className="text-pfont font-medium text-center">
                  Average Cost of Dental Treatment in USA is
                </div>
                <div className="text-h1font font-bold text-hoverclr">
                  $ 1450
                </div>
              </div>
              {cities.map((city) => {
                return (
                  <CityCompareCard
                    key={city._id}
                    city={city}
                    isComparePage={true}
                    isRemoveFavoriteEnabled={currentUser ? true : false}
                    onCloseClicked={(e) => {
                      onCityCloseClicked(city);
                    }}
                  ></CityCompareCard>
                );
              })}
              {cities.length != 3 && currentUser && (
                <div
                  onClick={() => {
                    setIsFavoritePopupOpen(true);
                  }}
                  className="flex flex-col items-center justify-center cursor-pointer border-[3px] border-borderclr hover:border-hoverclr rounded-[30px] px-[15px] pb-[19px] lg:px-[20px] lg:pb-[25px] bg-white relative destinationcard w-[300px] h-[350px] shrink-0"
                >
                  <div className="flex items-center justify-center border-[2px] border-solid border-pc rounded-full w-[50px] h-[50px]">
                    <FaMountainSun className="text-pc text-h2font" />
                  </div>
                  <div className="text-pfont font-medium pt-2">
                    Add destination
                  </div>
                </div>
              )}
            </div>

            <h2 className="text-[20px] lg:text-[20px] font-semibold mb-[11px] pt-[30px] flex items-center">
              Compare Details
              <IoIosInformationCircleOutline className="text-pc mx-[20px] text-h2font" />
            </h2>
            <div className="flex flex-row  gap-[50px] justify-start">
              <CityCompareSlide entity={usaCity}></CityCompareSlide>
              {cities.map((city) => {
                return (
                  <CityCompareSlide
                    key={Math.random()}
                    entity={city}
                  ></CityCompareSlide>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CityCompare;
