import FacilityInnerCards from "../components/Cards/FacilityInnerCards";
import PageBack from "../components/FacilityComponents/Pageback";
import FacilityTitle from "../components/FacilityComponents/FacilityTitle";
import OverviewFilter from "../components/FacilityComponents/OverviewFilter";
import SavingsCalculator from "../components/Cards/Savingscalculator";
import AmenitiesCard from "../components/Cards/AmenitiesCard";
import AmbienceCard from "../components/Cards/AmbienceCard";
import TreatmentsCard from "../components/Cards/TreatmentsCard";
import ReviewCard from "../components/Cards/ReviewCard";
import FAQ from "../components/shared/FAQ";
import FacilityLocation from "../components/Maps/FacilityLocation";
import HospitalGallery from "../components/FacilityComponents/HospitalGallery";
import SavingsMobile from "../components/Cards/SavingsMobile";
import CareTeam from "../components/Cards/CareTeam";
import Header from "../components/shared/Header";
import Footer from "../components/shared/Footer";
import FaqNew from "../components/shared/FaqNew";
import FacilityCard from "../components/facility/FacilityCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useState, useEffect, useRef } from "react";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
import { IoStar, IoClose } from "react-icons/io5";
import BeforeLogin from "../components/BeforeLogin";
import { CureValueService } from "../../helpers/ServiceWrapper";
import { getById, getByName } from "../modules/facility";
import { Facility } from "../modules/facility/core/_models";
import { useAuth } from "../modules/auth";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../components/shared/Loader";
import { useTreatment } from "../modules/treatment";
import SignUpPopup from "../components/auth/SignUpPopup";
import { Helmet } from "react-helmet";
import { json } from "stream/consumers";
import { FACILITY_IMAGE_CDN } from "../components/shared/urlConstants";
import { deConstructURLSegment } from "../../helpers/CVHelper";
import { getCityByName } from "../modules/city";
import CVPopup from "../components/shared/CVPopup";
import AppointmentSchedule from "./AppointmentSchedule";
import { CommentSection } from "react-comments-section";
import "react-comments-section/dist/index.css";
import axios from "axios";
import RelatedFacilities from "../components/shared/RelatedFacilities";
import { CV_DATA_CDN } from "../components/shared/urlConstants";
import {
  createComment,
  deleteComment,
  editComment,
  getAllByFacilityId,
} from "../modules/comment/core/commentService";
import { createReply } from "../modules/reply/core/replyService";

const FacilityPage = () => {
  let userTreatment = useTreatment();
  const { countryName, cityName, facilityName } = useParams();
  const [searchParams] = useSearchParams();
  const treatmentName = searchParams.get("service");
  const isClaimPage = searchParams.get("isclaim");

  const [selectedTreatment, setSelectedTreatment] = useState<any>(null);
  const [city, setCity] = useState<any>(null);
  const { currentUser } = useAuth();
  const [isBeforeLogin, setIsBeforeLogin] = useState(
    currentUser || isClaimPage ? false : true
  ); // Set this to true/false based on whether BeforeLogin component is rendered
  const sliderRef = useRef<any>(null);
  const [facility, setFacility] = useState<Facility | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [doctorCount, setDoctorCount] = useState(0);
  const [showSignUpPopup, setShowSignUpPopup] = useState(false);
  const [isSignupFromContactus, setIsSignupFromContactus] = useState(false);
  const [isBookingPopupOpen, setIsBookingPopupOpen] = useState(false);
  const [commentData, setCommentData] = useState([]);
  const navigate = useNavigate();
  const [jsonLD, setJsonLD] = useState({
    "@context": "https://schema.org",
    "@type": "MedicalClinic",
    name: facilityName,
    description: `${facilityName} in ${cityName}, ${countryName} offers a wide range of ${treatmentName} services, providing top-notch medical care and exceptional results.`,
    url: `${window.location}`,
    address: {
      "@type": "PostalAddress",
      streetAddress: `${facility?.address}`,
      addressLocality: `${facility?.city_name}`,
      addressCountry: `${facility?.country_name}`,
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: "32.5149",
      longitude: "-117.0382",
    },
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+52-664-123-4567",
      contactType: "Customer Service",
    },
    openingHours: "Mo,Tu,We,Th,Fr 09:00-18:00",
    sameAs: [
      "https://www.facebook.com/cosmedclinic",
      "https://www.instagram.com/cosmedclinic",
      "https://twitter.com/cosmedclinic",
    ],
    medicalSpecialty: "CosmeticSurgery",
    image: "http://localhost:3000/images/cosmed-clinic.jpg",
    priceRange: "$$$",
  });

  useEffect(() => {
    if (treatmentName) {
      setSelectedTreatment(deConstructURLSegment(treatmentName));
      return;
    }
    if (userTreatment.selectedTreatment) {
      setSelectedTreatment(userTreatment.selectedTreatment.treatment);
      return;
    }
  }, [treatmentName, userTreatment]);

  //get all facilities by city and treatment id
  const getFacilityByName = async (
    payload: {
      facilityName: string;
      countryName: string;
      cityName: string;
    },
    isInitialLoad: boolean = true
  ) => {
    setIsLoading(true);
    const { data, error } = await CureValueService(getByName, payload);
    if (data) {
      if (isInitialLoad) {
        setFacility(data);
        setIsLoading(false);
      } else {
        setFacility(data);
        setIsLoading(false);
      }
      let jsonld = jsonLD;
      jsonld.address.streetAddress = data.address;
      jsonld.address.addressCountry = data.country_name;
      jsonld.address.addressLocality = data.city_name;
      jsonld.geo.latitude = data.latitude;
      jsonld.geo.longitude = data.longitude;
      jsonld.image = FACILITY_IMAGE_CDN + data?._id + "/" + data.image_urls[0];
      setJsonLD(jsonld);

      if (localStorage.getItem("CONTACTUSCLICKED") && currentUser) {
        localStorage.removeItem("CONTACTUSCLICKED");
        // if (selectedTreatment) {
        //   // window.open(
        //   //   `/schedule-appointment/${data._id}/${selectedTreatment}`,
        //   //   "_blank"
        //   // );
        // } else {
        //   //window.open(`/schedule-appointment/${data._id}`, "_blank");
        // }
        setIsBookingPopupOpen(true);
      } else if (localStorage.getItem("CONTACTUSCLICKED")) {
        localStorage.removeItem("CONTACTUSCLICKED");
      }
      // destinationSliderRef.current.slickPrev();
    }
  };
  const getCityByNameFunc = async (payload: { cityName: string }) => {
    const { data, error } = await CureValueService(getCityByName, payload);
    if (data) {
      setCity(data);
    }
  };

  useEffect(() => {
    if (!facilityName) return;
    let facility_name = facilityName.replace(/-/g, " ");
    getFacilityByName({
      facilityName: facility_name,
      countryName: countryName ? countryName : "",
      cityName: cityName ? cityName : "",
    });
    if (cityName) {
      getCityByNameFunc({ cityName: cityName.replace(/-/g, " ") });
    }
  }, [facilityName]);

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };
  var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <FaRegArrowAltCircleLeft />,
    nextArrow: <FaRegArrowAltCircleRight />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  // const appClasses = isBeforeLogin
  //   ? "app-container no-scroll"
  //   : "app-container";

  const appClasses = isBeforeLogin ? "app-container" : "app-container";

  const handleShowSignUpPopup = () => {
    setShowSignUpPopup(true);
  };
  const handleShowSingupFromContactus = () => {
    setIsSignupFromContactus(true);
    handleShowSignUpPopup();
  };

  const handleShowLoginPopup = () => {
    setShowSignUpPopup(false);
    if (isSignupFromContactus) {
      localStorage.setItem("CONTACTUSCLICKED", "true");
      setIsSignupFromContactus(false);
    }
  };

  const handleClosePopup = () => {
    setShowSignUpPopup(false);
    if (isSignupFromContactus) {
      localStorage.setItem("CONTACTUSCLICKED", "true");
      setIsSignupFromContactus(false);
    }
  };
  //let jsonLD = ;

  useEffect(() => {
    if (!facility) return;

    const fetchComments = async () => {
      const payload = {
        facilityId: facility._id,
      };

      const { data, error } = await CureValueService(
        getAllByFacilityId,
        payload
      );
      if (data) {
        setCommentData(data);
      }
    };

    fetchComments();
  }, [facility]);

  const handleCommentSubmit = async (commentData: any) => {
    if (!facility) return;
    console.log("facility ", facility);
    const updatedCommentData = {
      ...commentData,
      facilityId: facility?._id, // Assuming facility._id is the correct property
    };

    try {
      // await axios.post(
      //   "http://localhost:3002/api/comment/create",
      //   updatedCommentData
      // );

      await CureValueService(createComment, updatedCommentData);

      // Refresh the comment list after posting

      const payload = {
        facilityId: facility._id,
      };

      const { data, error } = await CureValueService(
        getAllByFacilityId,
        payload
      );
      if (data) {
        setCommentData(data);
      }
    } catch (error) {
      console.error("Error posting comment:", error);
    }
  };

  const handleReplySubmit = async (replyData: any) => {
    if (!facility) return;
    try {
      await CureValueService(createReply, replyData);

      // await axios.post("http://localhost:3002/api/reply/create", replyData);

      // Refresh the comment list after posting

      const payload = {
        facilityId: facility._id,
      };

      const { data, error } = await CureValueService(
        getAllByFacilityId,
        payload
      );
      if (data) {
        setCommentData(data);
      }
    } catch (error) {
      console.error("Error posting reply:", error);
    }
  };

  const handleEditComment = async (editData: any) => {
    if (!facility) return;
    console.log("editData ", editData);
    await CureValueService(editComment, editData);
  };

  const handleDeleteComment = async (deleteData: any) => {
    if (!facility) return;
    await CureValueService(deleteComment, deleteData);
  };

  return (
    <>
      {facility && isBookingPopupOpen && (
        <CVPopup
          onClose={() => {
            setIsBookingPopupOpen(false);
          }}
          isClosable
        >
          <AppointmentSchedule
            treatmentId={selectedTreatment}
            facility={facility}
            onClose={() => {
              setIsBookingPopupOpen(false);
            }}
          ></AppointmentSchedule>
        </CVPopup>
      )}
      <Helmet>
        <title>
          {`${facility?.facility_name} ${treatmentName?.replace(
            /-/g,
            " "
          )} Services in ${facility?.city_name}, ${
            facility?.country_name
          } | Cure Value`}
        </title>
        <meta
          name="description"
          content={`Comprehensive Facility Guide to ${facility?.facility_name}, ${facility?.city_name}, ${facility?.country_name}. Learn more about our expert doctors and compare cost savings with similar treatments in the US.`}
        />
        <link
          rel="canonical"
          href={`https://curevalue.org/location/${countryName}/${cityName}/${facilityName} `}
        />

        {/* <meta
          property="og:title"
          content={`${facility?.facility_name} - Top ${treatmentName?.replace(
            /-/g,
            " "
          )} Services in ${cityName}, ${countryName} - Cure Value`}
        />
        <meta
          property="og:description"
          content={`Discover ${
            facility?.facility_name
          } in ${cityName}, ${countryName} for top-rated ${treatmentName?.replace(
            /-/g,
            " "
          )} services. Compare services, read reviews, and make an informed decision for your ${treatmentName?.replace(
            /-/g,
            " "
          )} with Cure Value.`}
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        {facility && facility.image_urls.length != 0 && (
          <meta
            property="og:image"
            content={
              FACILITY_IMAGE_CDN + facility?._id + "/" + facility?.image_urls[0]
            }
          />
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${facility?.facility_name} - Top ${treatmentName?.replace(
            /-/g,
            " "
          )} Services in ${cityName}, ${countryName} - Cure Value`}
        />
        <meta
          name="twitter:description"
          content={`Discover ${
            facility?.facility_name
          } in ${cityName}, ${countryName} for top-rated ${treatmentName?.replace(
            /-/g,
            " "
          )} services. Compare services, read reviews, and make an informed decision for your ${treatmentName?.replace(
            /-/g,
            " "
          )} with Cure Value.`}
        />
        <meta name="twitter:url" content={window.location.href} />

        {facility && facility.image_urls.length != 0 && (
          <meta
            property="twitter:image"
            content={
              FACILITY_IMAGE_CDN + facility?._id + "/" + facility?.image_urls[0]
            }
          />
        )}
        <meta name="twitter:site" content="@CureValue" />
        <meta name="twitter:creator" content="@CureValue" /> */}

        <script type="application/ld+json">{JSON.stringify(jsonLD)}</script>
      </Helmet>
      {isLoading ? (
        <>
          <div className="container w-[100%] h-[100vh] pt-[50vh]">
            <Loader />
          </div>
        </>
      ) : (
        <>
          {showSignUpPopup && (
            <SignUpPopup
              handleShowSignUpPopup={handleShowSignUpPopup}
              showSignUpPopup={showSignUpPopup}
              handleShowLoginPopup={handleShowLoginPopup}
              handleClosePopup={handleClosePopup}
              popupDetailMainText={""}
            />
          )}

          <div className={appClasses}>
            {/* <div> */}
            {/* <BeforeLogin /> */}
            <div className="w-[100%] fac-page pt-[70px] lg:pt-[80px]">
              <PageBack
                facility={facility}
                selectedTreatment={selectedTreatment}
                setFacility={setFacility}
              />
              <div className="flex lg:flex-col flex-col-reverse">
                {facility && (
                  <>
                    <FacilityTitle
                      facility={facility}
                      selectedTreatment={selectedTreatment}
                      setFacility={setFacility}
                      handleShowSignUpPopup={handleShowSignUpPopup}
                    />
                    <HospitalGallery
                      facility={facility}
                      doctorCount={doctorCount}
                      isBeforeLogin={false}
                      handleShowSignUpPopup={handleShowSignUpPopup}
                    />
                  </>
                )}
              </div>
              <OverviewFilter />
              <div className="container flex items-start justify-between lg:pt-[52px] lg:mb-[50px] facilitydetail-ipad">
                <div className="lg:max-w-[65%] overflow-hidden">
                  {facility && (
                    <>
                      <FacilityInnerCards
                        cardFor={"City Overview"}
                        facility={facility}
                        isBeforeLogin={false}
                        city={city}
                      />

                      {/* not showing this data if user is not logged in */}
                      {/* {!isBeforeLogin && ( */}

                      <FacilityInnerCards
                        cardFor={"Facility Description"}
                        facility={facility}
                        isBeforeLogin={false}
                        handleShowSignUpPopup={handleShowSignUpPopup}
                      />

                      <TreatmentsCard
                        cardFor={"Treatments"}
                        facility={facility}
                        isBeforeLogin={false}
                        handleShowSignUpPopup={handleShowSignUpPopup}
                      />

                      <CareTeam
                        cardFor={"Careteam"}
                        setDoctorCount={setDoctorCount}
                        facility={facility}
                        isBeforeLogin={false}
                        handleShowSignUpPopup={handleShowSignUpPopup}
                      />

                      <FacilityInnerCards
                        cardFor={"Accreditations"}
                        facility={facility}
                        isBeforeLogin={false}
                        handleShowSignUpPopup={handleShowSignUpPopup}
                      />

                      <AmenitiesCard
                        cardFor={"Amenities"}
                        facility={facility}
                        isBeforeLogin={false}
                        handleShowSignUpPopup={handleShowSignUpPopup}
                      />

                      <FacilityInnerCards
                        cardFor={"Visa Requirement"}
                        facility={facility}
                        isBeforeLogin={false}
                        handleShowSignUpPopup={handleShowSignUpPopup}
                      />

                      <FacilityInnerCards
                        cardFor={"Language Spoken"}
                        facility={facility}
                        isBeforeLogin={false}
                        handleShowSignUpPopup={handleShowSignUpPopup}
                      />

                      <FacilityInnerCards
                        cardFor={"Weather"}
                        facility={facility}
                        isBeforeLogin={false}
                        handleShowSignUpPopup={handleShowSignUpPopup}
                      />

                      {/* <AmbienceCard cardFor={"Ambience"} /> */}

                      {/* )} */}
                    </>
                  )}

                  {/* not showing this data if user is not logged in */}

                  {/* {!isBeforeLogin && (
                <>
                  <div className="mb-[30px] lg:mb-[52px] pb-[27px] lg:pb-[58px] border-b border-borderclr">
                    <div className="flex items-center gap-[5px] pb-[16px]">
                      <IoStar className="text-iconclr text-h3font lg:text-[30px]" />
                      <h2 className="text-sc text-h3font font-semibold lg:text-[20px] lg:font-bold">
                        Facilities with similar treatments
                      </h2>
                    </div>

                    <div className="careteam-slide">
                      <Slider {...settings} ref={sliderRef}>
                        <div>
                          <FacilityCard facility={facility} />
                        </div>

                        <div>
                          <FacilityCard facility={facility} />
                        </div>

                        <div>
                          <FacilityCard facility={facility} />
                        </div>
                      </Slider>

                      <div className="slider-arrows flex items-center gap-[25px] lg:gap-[45px] pl-[20px] justify-start">
                                        <div className="prev-arrow" onClick={handlePrevClick}>
                                        <FaRegArrowAltCircleLeft
                                            className="text-extraclr1 text-[38px] lg:text-[50px] cursor-pointer"
                                        />
                                        </div>
                                        <div className="next-arrow" onClick={handleNextClick}>
                                        <FaRegArrowAltCircleRight
                                            className="text-extraclr1 text-[38px] lg:text-[50px] cursor-pointer"
                                        />
                                        </div>
                                    </div>
                    </div>
                  </div>
                </>
              )} */}
                </div>
                <div className="filter-rightside hidden lg:block lg:sticky md:top-[50px] lg:top-[190px]">
                  {facility && !isClaimPage && (
                    <SavingsCalculator
                      facility={facility}
                      cityName={cityName}
                      selectedTreatment={selectedTreatment}
                      isBeforeLogin={false}
                      handleShowSignUpPopup={handleShowSingupFromContactus}
                      handleBookingpopup={() => {
                        setIsBookingPopupOpen(true);
                      }}
                    />
                  )}
                </div>
              </div>
              <FacilityLocation cityName={cityName} />

              <RelatedFacilities
                cityName={cityName ? cityName : ""}
                treatmentName={treatmentName ? treatmentName : ""}
              ></RelatedFacilities>
              {currentUser ? (
                <>
                  <div className="container pt-[50px] react-comment-section-wrapper ">
                    <div className="border-t border-borderclr">
                      <CommentSection
                        currentUser={{
                          currentUserId: currentUser?._id
                            ? currentUser._id
                            : currentUser?.email
                            ? currentUser.email
                            : "User Name Not Available",
                          currentUserImg: CV_DATA_CDN + "comment_icon.png",
                          currentUserProfile: "",
                          currentUserFullName: currentUser?.name
                            ? currentUser.name
                            : "Name not available",
                        }}
                        logIn={{
                          loginLink: "http://localhost:3001/",
                          signupLink: "http://localhost:3001/",
                        }}
                        commentData={commentData}
                        onSubmitAction={handleCommentSubmit}
                        onReplyAction={handleReplySubmit}
                        onDeleteAction={handleDeleteComment}
                        onEditAction={handleEditComment}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              <FaqNew />
              {!isClaimPage && (
                <SavingsMobile
                  facility={facility}
                  cityName={cityName}
                  selectedTreatment={selectedTreatment}
                  isBeforeLogin={false}
                  handleShowSignUpPopup={handleShowSignUpPopup}
                  handleBookingpopup={() => {
                    setIsBookingPopupOpen(true);
                  }}
                />
              )}
              {/* <CVPopup>
                <AppointmentSchedule></AppointmentSchedule>
              </CVPopup> */}
              {/* <Footer /> */}

              {/* {isBeforeLogin && (
                <>
                  <BeforeLogin />
                </>
              )} */}
            </div>
          </div>
        </>
      )}
    </>
  );
};
export default FacilityPage;
