import axios from "axios";
import { BaseResponse, ListResponse } from "../../common";
import {
  API_SUBTREATMENT_URL,
  API_TREATMENT_URL,
} from "../../../components/shared/urlConstants";
import { Treatment } from "./_models";

export function findTreatment(payload: {}) {
  return axios
    .post<any, BaseResponse<any>>(`${API_SUBTREATMENT_URL}search`, payload)
    .then((response) => {
      return response.data;
    });
}
export const getAllTreatments = () => {
  return axios
    .get<any, BaseResponse<Treatment[]>>(`${API_TREATMENT_URL}getAll`)
    .then((response) => {
      return response.data;
    });
};

export const getTreatmentById = (payload: { treatment_id: string }) => {
  return axios
    .get<any, BaseResponse<any>>(
      `${API_TREATMENT_URL}getById?treatmentId=` + payload.treatment_id
    )
    .then((response) => {
      return response.data;
    });
};

export const getTreatmentByName = (payload: { treatment_name: string }) => {
  return axios
    .get<any, BaseResponse<any>>(
      `${API_TREATMENT_URL}getByName?treatmentName=` + payload.treatment_name
    )
    .then((response) => {
      return response.data;
    });
};
export const getTreatmentByCityAndCountryName = (payload: {
  city_name: string;
  country_name: string;
}) => {
  return axios
    .post<any, BaseResponse<Treatment[]>>(
      `${API_TREATMENT_URL}getByCityAndCountryName`,
      payload
    )
    .then((response) => {
      return response.data;
    });
};
