import React, { useEffect, useState } from "react";
import "./App.css";

import { BrowserRouter as Router, Outlet } from "react-router-dom";
import { AuthInit } from "./modules/auth/core/AuthContext";
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import { I18nProvider } from "./modules/i18n/i18nProvider";
import { LayoutProvider } from "./components/layout/LayoutContext";
import { CMSInit, CMSProvider } from "./components/cms/CMSContext";
import MessageContainer from "./components/shared/MessageContainer";
import { TreatmentProvider, TreatmentInit } from "./modules/treatment";
import ScrollToTop from "./components/shared/ScroolToTop";

function App() {
  return (
    <>
      <I18nProvider>
        <MessageContainer />
        <AuthInit>
          <CMSProvider>
            <CMSInit>
              <TreatmentProvider>
                <TreatmentInit>
                  <LayoutProvider>
                    <ScrollToTop />
                    <Outlet />
                  </LayoutProvider>
                </TreatmentInit>
              </TreatmentProvider>
            </CMSInit>
          </CMSProvider>
        </AuthInit>
      </I18nProvider>
    </>
  );
}

export default App;
