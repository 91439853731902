import doctor from "../../assets/images/Patient-resources.png";
import doctormob from "../../assets/images/Patient-resources-mob.png";
import Stories from "./Cards/Stories";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useRef, useState } from "react";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
import { useGetCMS } from "./cms/CMSContext";
import contentIdMap from "../../assets/content/contentful.json";
import { title } from "process";
import { getURLFromAssetsId } from "./cms/CMSHelper";

const CustomerStories = () => {
  const contentId = contentIdMap.homePage.fullWidthSliderContent;
  const content = useGetCMS(contentId);
  var settings = {
    arrows: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    centerMode: true,
    prevArrow: <FaRegArrowAltCircleLeft />,
    nextArrow: <FaRegArrowAltCircleRight />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const sliderRef = useRef<any>(null);

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  return (
    <div className="overflow-hidden ">
      <div className="lg:grid lg:grid-cols-2 h-[100%] gap-[60px] bg-[#F5F7FA] relative ">
        <div>
          <div className="block lg:hidden px-[24px] pt-[47px] pb-[21px]">
            <h2 className="text-[30px]/[35px] text-black max-w-[50%] pb-[15px] font-semibold">
              {content?.title}
            </h2>
            <p className="text-h4font font-medium max-w-[100%]">
              {content?.subTitle}
            </p>
          </div>
          <div className="h-[100%]">
            <img
              src={getURLFromAssetsId(content?.imageDesktop?.sys.id, content)}
              className="2xl:w-[900px] lg:w-[730px] h-[100%] customer-docimg hidden lg:block object-cover"
            />
            <img
              src={getURLFromAssetsId(content?.imageMobile?.sys.id, content)}
              className=" customer-docimg block lg:hidden w-[100%] object-cover"
            />
          </div>
        </div>
        <div className="lg:pt-[60px] pb-[67px] lg:pb-[30px]">
          <div className="hidden lg:block customertext">
            <h2 className="text-[38px]/[45px] text-black lg:max-w-[70%] pb-[21px] font-semibold">
              {content?.title}
            </h2>
            <p className="lg:text-h3font font-medium max-w-[80%]">
              {content?.subTitle}
            </p>
          </div>
          <div>
            <div className="mt-[-20%] ml-[-10%] lg:mt-[0] lg:ml-[-150%] md:ml-[-90%]">
              <div className="stories-slider lg:pt-[53px] ">
                {content.entries && (
                  <Slider {...settings} ref={sliderRef}>
                    {content?.entries.map((entry: any) => {
                      return (
                        <div key={Math.random()}>
                          <Stories {...entry} />
                        </div>
                      );
                    })}
                  </Slider>
                )}
              </div>
            </div>
            <div className="slider-arrows flex items-center gap-[25px] 2xl:gap-[45px] pl-[20px] justify-start pt-[34px] lg:pt-[20px] 2xl:pt-[30px]">
              <div className="prev-arrow" onClick={handlePrevClick}>
                <FaRegArrowAltCircleLeft className="text-cardpara text-[38px] 2xl:text-[50px] cursor-pointer hover:text-hoverclr" />
              </div>
              <div className="next-arrow" onClick={handleNextClick}>
                <FaRegArrowAltCircleRight className="text-cardpara text-[38px] 2xl:text-[50px] cursor-pointer hover:text-hoverclr" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerStories;
