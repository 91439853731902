import { ChangeEvent, useEffect, useState } from "react";
import { IoClose } from "react-icons/io5";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import { City } from "../../modules/city";
import { Facility, GetByNameRequest } from "../../modules/facility";
import { getAllFavorites } from "../../modules/favorite/core/FavoriteService";
import FacilityCompareCard from "../facility/FacilityCompareCard";
import Loader from "./Loader";
import CityCompareCard from "../city/CityCompareCard";
import CVPopup from "./CVPopup";

export type FavoritePopupCloseEvent = {
  selectedFaciltiy: Facility | null;
  selectedCity: City | null;
};
interface FavoritePopupProps {
  setIsOpenModal?: React.Dispatch<React.SetStateAction<boolean>>;
  onClose?: (event: FavoritePopupCloseEvent | null) => void;
  typeOfFavorite: "City" | "Facility";
  exsistingComparedEntity: Facility[] | City[];
  favoriteFacilities: Facility[];
  favoriteCities: City[];
}
const FavoritePopup = ({
  setIsOpenModal,
  onClose,
  typeOfFavorite,
  exsistingComparedEntity,
  favoriteCities: favoriteCitiesProp,
  favoriteFacilities: favoriteFacilitiesProp,
}: FavoritePopupProps) => {
  const [favoriteFacilities, setFavoriteFacilities] = useState<Facility[]>([]);
  const [favoriteCities, setFavoriteCities] = useState<City[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useEffect(() => {
    if (typeOfFavorite == "Facility") {
      const newFavFacilityList = favoriteFacilitiesProp.filter(
        (facility) => !isFavoriteSelected(facility)
      );
      setFavoriteFacilities(newFavFacilityList);
    } else {
      const newFavCityList = favoriteCitiesProp.filter(
        (city) => !isFavoriteSelected(city)
      );
      setFavoriteCities(newFavCityList);
    }
  }, [favoriteCitiesProp, favoriteFacilitiesProp]);
  const isFavoriteSelected = (entityToCompare: Facility | City) => {
    let isFound = false;
    exsistingComparedEntity.forEach((entity) => {
      if (entity._id === entityToCompare._id) {
        isFound = true;
        return;
      }
    });
    return isFound;
  };
  const handleFacilityonClick = (facility: Facility) => {
    if (onClose) {
      onClose({ selectedFaciltiy: facility, selectedCity: null });
    }
  };
  const handleCityonClick = (city: City) => {
    if (onClose) {
      onClose({ selectedFaciltiy: null, selectedCity: city });
    }
  };
  return (
    // <div className="modal-hospital z-30 fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50">
    //   <div className=" bg-white rounded-[24px] p-[20px] lg:py-[50px] lg:pl-[50px] w-[95%] lg:w-[80%]  relative">
    //     <div className="max-h-[600px]">
    //       <div className="absolute right-[10px] top-[10px] lg:right-[36px] lg:top-[30px]">
    //         <span
    //           className="close cursor-pointer"
    //           onClick={() => {
    //             if (setIsOpenModal) {
    //               setIsOpenModal(false);
    //             }
    //             if (onClose) {
    //               onClose(null);
    //             }
    //           }}
    //         >
    //           <IoClose size={"20px"} className="text-black" />
    //         </span>
    //       </div>
    //       <div className="flex flex-col lg:flex-row lg:items-end gap-[30px] lg:gap-[80px] pb-[20px] lg:pb-[35px]">
    //         <h4 className="text-h3font/[18px] lg:text-[26px]/[26px] text-sc font-semibold pb-[3px] w-[100%]">
    //           Please select {typeOfFavorite} from your favorite list
    //         </h4>
    //       </div>

    //       <div
    //         className={`flex gap-[20px] justify-start flex-wrap mb-[20px] min-h-[200px] max-h-[400px] overflow-y-scroll ${
    //           isLoading || favoriteFacilities.length == 0
    //             ? "justify-center items-center"
    //             : ""
    //         }`}
    //       >
    //         {!isLoading &&
    //           typeOfFavorite == "Facility" &&
    //           favoriteFacilities.map((facility) => {
    //             return (
    //               <div className="pt-[50px]" key={facility._id}>
    //                 <FacilityCompareCard
    //                   facility={facility}
    //                   onCloseClicked={(e) => {}}
    //                   onCompareChanged={(
    //                     e: ChangeEvent<HTMLInputElement>
    //                   ) => {}}
    //                   isComparePage={true}
    //                   isRemoveFavoriteEnabled={false}
    //                   isCompareChecked={false}
    //                   onClick={() => handleFacilityonClick(facility)}
    //                 ></FacilityCompareCard>
    //               </div>
    //             );
    //           })}
    //         {isLoading && <Loader />}

    //         {!isLoading &&
    //           favoriteFacilities.length == 0 &&
    //           typeOfFavorite == "Facility" && <div>No Favorites to show</div>}

    //         {!isLoading &&
    //           typeOfFavorite == "City" &&
    //           favoriteCities.map((city) => {
    //             return (
    //               <div className="pt-[50px]" key={city._id}>
    //                 <CityCompareCard
    //                   city={city}
    //                   onCloseClicked={(e) => {}}
    //                   onCompareChanged={(
    //                     e: ChangeEvent<HTMLInputElement>
    //                   ) => {}}
    //                   isComparePage={true}
    //                   isRemoveFavoriteEnabled={false}
    //                   isCompareChecked={false}
    //                   onClick={() => handleCityonClick(city)}
    //                 ></CityCompareCard>
    //               </div>
    //             );
    //           })}
    //         {isLoading && <Loader />}
    //         {!isLoading &&
    //           favoriteFacilities.length == 0 &&
    //           typeOfFavorite == "Facility" && <div>No Favorites to show</div>}
    //       </div>

    //       {/* <a className="flex items-center gap-2 text-pc text-pfont lg:text-pfont font-medium mt-10">
    //         Compare <IoIosArrowRoundForward />
    //       </a> */}
    //     </div>
    //   </div>
    // </div>
    <CVPopup
      onClose={() => {
        if (setIsOpenModal) {
          setIsOpenModal(false);
        }
        if (onClose) {
          onClose(null);
        }
      }}
      isClosable={true}
    >
      <div className="flex flex-col lg:flex-row lg:items-end gap-[30px] lg:gap-[80px] pb-[20px] lg:pb-[35px]">
        <h4 className="text-h3font/[18px] lg:text-[26px]/[26px] text-sc font-semibold pb-[3px] w-[100%]">
          Please select a {typeOfFavorite.toLowerCase()} from your favorite list
        </h4>
      </div>

      <div
        className={`flex gap-[20px] justify-start flex-wrap mb-[20px]  ${
          isLoading || favoriteFacilities.length == 0
            ? "justify-center items-center"
            : ""
        }`}
      >
        {!isLoading &&
          typeOfFavorite == "Facility" &&
          favoriteFacilities.map((facility) => {
            return (
              <div className="pt-[50px]" key={facility._id}>
                <FacilityCompareCard
                  facility={facility}
                  onCloseClicked={(e) => {}}
                  onCompareChanged={(e: ChangeEvent<HTMLInputElement>) => {}}
                  isComparePage={true}
                  isRemoveFavoriteEnabled={false}
                  isCompareChecked={false}
                  onClick={() => handleFacilityonClick(facility)}
                ></FacilityCompareCard>
              </div>
            );
          })}
        {isLoading && <Loader />}

        {!isLoading &&
          favoriteFacilities.length == 0 &&
          typeOfFavorite == "Facility" && <div>No Favorites to show</div>}

        {!isLoading &&
          typeOfFavorite == "City" &&
          favoriteCities.map((city) => {
            return (
              <div className="pt-[50px]" key={city._id}>
                <CityCompareCard
                  city={city}
                  onCloseClicked={(e) => {}}
                  onCompareChanged={(e: ChangeEvent<HTMLInputElement>) => {}}
                  isComparePage={true}
                  isRemoveFavoriteEnabled={false}
                  isCompareChecked={false}
                  onClick={() => handleCityonClick(city)}
                ></CityCompareCard>
              </div>
            );
          })}
        {isLoading && <Loader />}
        {!isLoading &&
          favoriteFacilities.length == 0 &&
          typeOfFavorite == "City" && <div>No Favorites to show</div>}
      </div>
    </CVPopup>
  );
};

export default FavoritePopup;
