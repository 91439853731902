import axios from "axios";
import { BaseResponse } from "../../common";
import { API_COMMENT_URL } from "../../../components/shared/urlConstants";

export function getAllByFacilityId(payload: { facilityId: any }) {
  return axios
    .get<any, BaseResponse<any>>(
      `${API_COMMENT_URL}getAll?facilityId=` + payload.facilityId
    )
    .then((response) => {
      return response.data;
    });
}

export function createComment(payload: any) {
  return axios
    .post<any, BaseResponse<any>>(`${API_COMMENT_URL}create`, payload)
    .then((response) => {
      return response.data;
    });
}

export function deleteComment(payload: any) {
  return axios
    .post<any, BaseResponse<any>>(`${API_COMMENT_URL}delete`, payload)
    .then((response) => {
      return response.data;
    });
}

export function editComment(payload: any) {
  return axios
    .post<any, BaseResponse<any>>(`${API_COMMENT_URL}edit`, payload)
    .then((response) => {
      return response.data;
    });
}
