import { useEffect, useState } from "react";
import { FaLocationDot } from "react-icons/fa6";

const FacilityLocation = ({ cityName }: any) => {
  const [mapSrc, setMapSrc] = useState("https://www.google.com/maps/embed");

  useEffect(() => {
    if (!cityName) return;
    const newSrc = `https://www.google.com/maps?q=${encodeURIComponent(
      cityName.toLowerCase() + " city"
    )}&output=embed`;
    setMapSrc(newSrc);
  }, [cityName]);
  return (
    <div className="container">
      <div className="flex items-center gap-[5px] pb-[30px] ">
        <FaLocationDot className="text-iconclr text-h3font lg:text-[30px]" />
        <h2 className="text-sc text-h3font font-semibold lg:text-[20px] lg:font-bold">
          Location
        </h2>
      </div>
      <div>
        <iframe
          className="w-[100%] rounded-[30px] h-[215px] lg:h-[450px]"
          src={mapSrc}
          key="map"
          width="100%"
          style={{ border: 0 }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default FacilityLocation;
