import _ from "lodash";

const getURLFromAssetsId = (assetId: string, content: any) => {
  if (content) {
    const asset = _.find(content.assets, { sys: { id: assetId } });
    if (asset) {
      return asset.fields.file.url;
    }
  }
};
export { getURLFromAssetsId };
