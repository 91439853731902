import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Outlet,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useLayout } from "../components/layout/LayoutContext";
import { useTreatment } from "../modules/treatment";
import {
  deConstructURLSegment,
  getURLFromString,
} from "../../helpers/CVHelper";

function UserLayout() {
  const { layout, setLayout } = useLayout();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { treatments, setSelectedTreatment, selectedTreatment } =
    useTreatment();
  useEffect(() => {
    setLayout({ ...layout, isFooter: true });
  }, []);
  useEffect(() => {
    if (searchParams.get("service") != null) {
      const treatmentFromURL = searchParams.get("service");
      if (treatmentFromURL) {
        const treatment = treatments.filter(
          (treatment) =>
            treatment.name.toLowerCase() ==
            deConstructURLSegment(treatmentFromURL)
        );
        if (treatment.length != 0) {
          if (!selectedTreatment) {
            setSelectedTreatment({
              subTreatment: "",
              subTreatmentId: "",
              treatment: treatment[0].name,
              treatmentId: treatment[0]._id,
            });
          }
        }
      }
    }
  }, [searchParams, treatments]);
  return (
    <>
      <Outlet></Outlet>
    </>
  );
}

export default UserLayout;
