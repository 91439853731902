import trustimg from "../../../assets/images/trustmark4.svg";

const CureValueRating = ({
  rating,
  size,
}: {
  rating: string;
  size?: "sm" | "lg" | "xl";
}) => {
  return (
    <>
      {size && size == "sm" && (
        <div className="relative">
          <img src={trustimg} className="w-[80%] max-w-[69px]" />
          <p className="absolute text-smalltext font-semibold text-white top-[50%] left-[40%] translate-x-[-50%]  translate-y-[-50%] mx-auto">
            {Math.round(parseFloat(rating))}
          </p>
        </div>
      )}
      {size && size == "lg" && (
        <div className="relative">
          <img src={trustimg} className="w-[100%] max-w-[69px]" />
          <p className="absolute text-smalltext font-semibold text-white top-[50%] left-[40%] translate-x-[-50%]  translate-y-[-50%] mx-auto">
            {Math.round(parseFloat(rating))}
          </p>
        </div>
      )}
      {!size && (
        <div className="relative">
          <img src={trustimg} className="w-[100%] max-w-[69px]" />
          <p className="absolute text-smalltext font-semibold text-white top-[50%] left-[50%] translate-x-[-50%]  translate-y-[-50%] mx-auto">
            {Math.round(parseFloat(rating))}
          </p>
        </div>
      )}

      {size && size == "xl" && (
        <div className="relative">
          <img src={trustimg} className="w-[100px] h-[100px]" />
          <p className="absolute text-h3font font-semibold text-white top-[50%] left-[49%] translate-x-[-50%]  translate-y-[-50%] mx-auto">
            {Math.round(parseFloat(rating))}
          </p>
        </div>
      )}
    </>
    // <div className="relative">
    //   <img src={trustimg} className="w-[100%] max-w-[69px]" />
    //   <p className="absolute text-smalltext font-semibold text-white top-[50%] left-[50%] translate-x-[-50%]  translate-y-[-50%] mx-auto">
    //     {rating}
    //   </p>
    // </div>
  );
};
export default CureValueRating;
