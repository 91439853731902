import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Outlet } from "react-router-dom";
import { useLayout } from "../components/layout/LayoutContext";

function AuthLayout() {
  const { layout, setLayout } = useLayout();
  useEffect(() => {
    setLayout({ ...layout, isFooter: false });
  }, []);
  return (
    <>
      <Outlet></Outlet>
    </>
  );
}

export default AuthLayout;
