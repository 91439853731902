import { MdArrowBackIos, MdEmail } from "react-icons/md";
import { FaBookmark, FaCopy, FaFacebookSquare } from "react-icons/fa";
import { BsShareFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useTreatment } from "../../modules/treatment";
import { useEventEmmiter } from "../shared/eventEmmiter";
import { useEffect, useState } from "react";
import { AddRemoveFavoriteRequest } from "../../modules/favorite/core/_models";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import {
  addToFavorite,
  removeFromFavorite,
} from "../../modules/favorite/core/FavoriteService";
import { useAuth } from "../../modules/auth";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { IoClose, IoLogoWhatsapp } from "react-icons/io5";
import {
  constructURLSegment,
  getURLFromString,
} from "../../../helpers/CVHelper";
import { FiShare } from "react-icons/fi";

const PageBack = ({ facility, selectedTreatment, setFacility }: any) => {
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const userSelectedTreatment = useTreatment();
  const eventEmmiter = useEventEmmiter();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [showSignuptooltip, setShowSignuptooltip] = useState<boolean>(false);
  const [shareUrl, setShareUrl] = useState<any>(null);

  useEffect(() => {
    if (!facility || !selectedTreatment) return;
    const textToCopy =
      process.env.REACT_APP_BASEURL +
      "/location/" +
      facility.country_name +
      "/" +
      facility.city_name +
      "/" +
      facility.facility_name +
      "?service=" +
      selectedTreatment;

    setShareUrl(textToCopy);
  }, [selectedTreatment]);

  const handleBackClick = () => {
    // navigate(-1); // This navigates one step back in the history
    if (!userSelectedTreatment.selectedTreatment) {
      navigate("/");
    } else {
      navigate(
        "/treatment/" +
          constructURLSegment(
            userSelectedTreatment.selectedTreatment.treatment
          ) +
          "/city-filter"
      );
    }
  };

  const favoriteClickHandle = (e: any) => {
    e.stopPropagation();
    if (currentUser) {
      changeFavorite();
    } else {
      setShowSignuptooltip(true);
    }
  };

  const changeFavorite = async () => {
    let payload: AddRemoveFavoriteRequest = {
      favourite_type: "facility",
      facility_id: facility?._id,
    };
    if (
      facility?.is_favourite == false ||
      facility?.is_favourite == undefined
    ) {
      const { data, error } = await CureValueService(addToFavorite, payload);
      if (data) {
        if (facility) {
          setFacility({ ...facility, is_favourite: true });
        }
      }
    } else {
      const { data, error } = await CureValueService(
        removeFromFavorite,
        payload
      );
      if (data) {
        if (facility) {
          setFacility({ ...facility, is_favourite: false });
        }
      }
    }
  };

  const toggleSharePopup = (e: any) => {
    e.preventDefault();
    setIsOpenModal(!isOpenModal);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        eventEmmiter.cvNotify({
          message: "Link copied to clipboard successfully",
          type: "INFO",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <>
      <div className="container pageback">
        <div className="flex items-center justify-between pt-[10px]">
          <div className="flex items-center gap-[4px] lg:gap-[10px] cursor-pointer">
            <MdArrowBackIos className="text-extraclr3 text-[24px] lg:text-iconclr3 lg:text-[20px]" />
            <a
              className="text-pfont lg:text-[18px] font-medium text-sc font-sans"
              onClick={() => {
                navigate("/");
              }}
            >
              {/* <p>
                Home/City/{facility?.country_name}/{facility?.city_name}/
                {facility?.facility_name}
              </p> */}
              <p>Home </p>
            </a>
            /
            <a
              className="text-pfont lg:text-[18px] font-medium text-sc font-sans"
              onClick={handleBackClick}
            >
              {/* <p>
                Home/City/{facility?.country_name}/{facility?.city_name}/
                {facility?.facility_name}
              </p> */}
              <p>Facility selection</p>
            </a>
          </div>
          <div className="lg:hidden flex items-center gap-[22px]">
            <FiShare
              size={"30px"}
              className={`cursor-pointer ${
                isFixed ? "text-white ease-in-out duration-300" : ""
              }`}
              onClick={(e) => {
                toggleSharePopup(e);
              }}
            />

            <div
              className="text-hoverclr cursor-pointer"
              onClick={(e) => favoriteClickHandle(e)}
            >
              {facility?.is_favourite == false ||
              facility?.is_favourite == undefined ? (
                <IoMdHeartEmpty size={"30px"} />
              ) : (
                <IoMdHeart size={"30px"} />
              )}
            </div>
            {/* <FaBookmark className="cursor-pointer text-h4font lg:text-[30px]" /> */}
          </div>
        </div>

        {isOpenModal && (
          <div
            className="z-[200] fixed top-0 left-0 w-full h-full flex justify-center items-start bg-black bg-opacity-50"
            onClick={(e) => {
              toggleSharePopup(e);
            }}
          >
            <div className=" bg-white rounded-[24px] p-[50px] lg:py-[50px] lg:pl-[50px] w-[95%] lg:w-[30%] relative mt-[200px]">
              <div className="absolute right-5 top-5 lg:right-[5%] lg:top-[5%]">
                <span
                  className="close cursor-pointer"
                  onClick={(e) => {
                    toggleSharePopup(e);
                  }}
                >
                  <IoClose size={"30px"} className="text-black" />
                </span>
              </div>

              <div className="max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
                <div className="lg:max-w-[90%] modal-hospital">
                  <h6 className="capitalize text-[20px]  font-semibold text-cardpara pt-[5px] pb-[25px]">
                    Shareee
                  </h6>
                  <div className="grid lg:grid-cols-2 items-start gap-[20px] lg:gap-[40px]">
                    <div
                      className="flex items-center justify-center  gap-[10px] border border-pc rounded-[10px] cursor-pointer"
                      onClick={copyToClipboard}
                    >
                      <FaCopy size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[10px] pb-[10px]">
                        Copy Link
                      </h6>
                    </div>
                    <div className="flex items-center justify-center ps-[0px] gap-[10px] border border-pc rounded-[10px] cursor-pointer">
                      <MdEmail size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[5px] pb-[5px]">
                        <EmailShareButton
                          url={shareUrl}
                          subject={facility.facility_name}
                        >
                          Email
                        </EmailShareButton>
                      </h6>
                    </div>
                    <div className="flex items-center justify-center ps-[20px] gap-[10px] border border-pc rounded-[10px] cursor-pointer">
                      <FaFacebookSquare size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[5px] pb-[5px]">
                        <FacebookShareButton url={shareUrl}>
                          Facebook
                        </FacebookShareButton>
                      </h6>
                    </div>
                    <div className="flex items-center justify-center  gap-[10px] border border-pc rounded-[10px] cursor-pointer">
                      <IoLogoWhatsapp size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[5px] pb-[5px]">
                        <WhatsappShareButton
                          url={shareUrl}
                          title={facility.facility_name}
                        >
                          WhatsApp
                        </WhatsappShareButton>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PageBack;
