import Header from "../components/shared/Header";
import LoginFooter from "../components/auth/LoginFooter";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CureValueService } from "../../helpers/ServiceWrapper";
import { setpassword, verifyUserEmail } from "../modules/auth";
import Loader from "../components/shared/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { useEventEmmiter } from "../components/shared/eventEmmiter";

const loginSchema = Yup.object().shape({
  password: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Confirm password is required")
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

const initialValues = {
  password: "",
  confirmPassword: "",
};

const ResetPassword = () => {
  const { token } = useParams();
  const [isTokenVerified, setIsTokenVerified] = useState(false);
  const [isVerificationLoading, setisVerificationLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState({ email: "" });
  const eventEmmiter = useEventEmmiter();
  const navigate = useNavigate();
  const verifyToken = async (token: string) => {
    let { data, error } = await CureValueService<any, any>(
      verifyUserEmail,
      token
    );
    if (data) {
      setIsTokenVerified(true);
      setisVerificationLoading(false);
      setUser(data);
    } else {
      setIsTokenVerified(false);
      setIsTokenVerified(true);
      setisVerificationLoading(false);
      navigate("/");
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      formik.setSubmitting(true);
      let payload = {
        authToken: token ? token : "",
        password: values.password,
      };
      const { data, error } = await CureValueService(setpassword, payload);
      if (!error) {
        eventEmmiter.cvNotify({
          message: "Password reset succsfully! Please login with new password.",
          type: "INFO",
        });
        navigate("/");
      } else {
        eventEmmiter.cvNotify({
          message: "Unable to reset the password. Please try again.",
          type: "ERROR",
        });
        navigate("/");
      }
    },
  });
  useEffect(() => {
    if (token) {
      verifyToken(token);
    } else {
      navigate("/");
    }
  }, [token]);
  return (
    <div className="">
      <div className="relative">
        <div className="pt-[100px] realtive z-20  ">
          <div className="flex flex-col  justify-center px-[24px] py-[50px] max-w-[100%] lg:max-w-[30%] mx-auto shadow-lg rounded-lg border">
            <h2 className="text-h2font lg:text-[39px] text-lightblack font-bold pb-[30px] text-center">
              Reset Password
            </h2>
            {!isVerificationLoading && (
              <form
                className=" w-full"
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <p className="text-center pb-[20px]">
                  Enter a new password for{" "}
                  <span className="font-medium">{user.email}</span>
                </p>
                {formik.status ? (
                  <div className="mb-lg-15 alert alert-danger">
                    <div className="alert-text font-weight-bold">
                      {formik.status}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                <div className="flex flex-col gap-[4px] mb-[10px]">
                  <label className="text-pfont text-lightblack font-semibold">
                    Password
                  </label>
                  <input
                    type="password"
                    autoComplete="off"
                    placeholder="Password"
                    {...formik.getFieldProps("password")}
                    className={clsx(
                      "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                      {
                        "is-invalid":
                          formik.touched.password && formik.errors.password,
                      },
                      {
                        "is-valid":
                          formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span
                          role="alert"
                          className="text-[11px] text-[#E02B1D] font-medium mb-0"
                        >
                          {formik.errors.password}
                        </span>
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col gap-[4px] mb-[10px]">
                  <label className="text-pfont text-lightblack font-semibold">
                    Confirm password
                  </label>
                  <input
                    type="password"
                    autoComplete="off"
                    placeholder="Confirm password"
                    {...formik.getFieldProps("confirmPassword")}
                    className={clsx(
                      "form-control bg-transparent border border-[#abbed1] py-[4px] px-[12px] rounded-[5px]",
                      {
                        "is-invalid":
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword,
                      },
                      {
                        "is-valid":
                          formik.touched.confirmPassword &&
                          !formik.errors.confirmPassword,
                      }
                    )}
                  />
                  {formik.touched.confirmPassword &&
                    formik.errors.confirmPassword && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          <span
                            role="alert"
                            className="text-[11px] text-[#E02B1D] font-medium mb-0"
                          >
                            {formik.errors.confirmPassword}
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                <div className="d-grid mt-[25px]">
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className="bg-pc border border-pc text-white rounded-full w-[100%] text-pfont font-medium py-[8px] cursor-pointer group hover:bg-white disabled:cursor-not-allowed"
                    disabled={formik.isSubmitting || !formik.isValid}
                  >
                    {!loading && (
                      <span className="indicator-label group-hover:text-pc">
                        Continue
                      </span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: "block" }}
                      >
                        Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </form>
            )}
            {isVerificationLoading && <Loader></Loader>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
