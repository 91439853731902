import React, { useEffect, useRef, useState } from "react";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
// import flagimg from "../../../assets/images/flags/";
import Slider from "react-slick";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import { getTopRatesCities as getTopRatedCitiesService } from "../../modules/city";
import { City, CityFilterRequest } from "../../modules/city/core/_models";
import Destinationcard, { DestinationCardActionType } from "./Destinationcard";
import CitySliderTitle from "../CitySliderTitle";
import CityPopup from "./CityPopUp";
import { CMSContentProps, ContentProps } from "../../modules/common";
import { TreatmentContextModel, useTreatment } from "../../modules/treatment";
import TreatmentSearchPopup from "../shared/TreatmentSearchPopup";
import { useNavigate } from "react-router-dom";
import { getURLFromString } from "../../../helpers/CVHelper";
import Loader from "../shared/Loader";
const settings = {
  arrows: true,
  dots: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  initialSlide: 0,
  infinite: false,
  prevArrow: <FaRegArrowAltCircleLeft />,
  nextArrow: <FaRegArrowAltCircleRight />,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

type CityCarouselProps = CMSContentProps & {
  request?: CityFilterRequest;
  handleShowSignUpPopup?: () => void;
};

const CityCarousel: React.FC<CityCarouselProps> = ({
  contentId,
  request,
  handleShowSignUpPopup,
}) => {
  const destinationSliderRef = useRef<any>(null);
  const [topCities, setTopCities] = useState<City[]>([]);
  const totalCityRecords = useRef(0);
  const [selectedCity, setSelectedCity] = useState<City>();
  const [isOpenModal, setIsOpenModal] = useState<any>(false);
  const [citySortBy, setCitySortBy] = useState<any>("CV Rating");
  const [isTreatmentModelOpen, setIsTreatmentModelOpen] = useState<any>(false);
  const { selectedTreatment, setSelectedTreatment } = useTreatment();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const sortingOptions = [
    {
      id: 1,
      sortBy: "CV Rating",
    },
    {
      id: 2,
      sortBy: "Distance",
    },
    // {
    //   id: 3,
    //   sortBy: "Cost",
    // },
    // {
    //   id: 4,
    //   sortBy: "Quality",
    // },
    // {
    //   id: 5,
    //   sortBy: "Trust",
    // },
  ];

  useEffect(() => {
    setTopCities([]);

    getTopRatedCities({ offset: 0, limit: 10, sort_by: citySortBy });
  }, [citySortBy]);
  const handlePrevClick = () => {
    if (destinationSliderRef.current) {
      destinationSliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (destinationSliderRef.current) {
      destinationSliderRef.current.slickNext();
    }
  };
  const destinationSliderChanged = (index: number) => {
    if (topCities.length - 3 == index) {
      getTopRatedCities(
        { offset: topCities.length + 1, limit: 10, sort_by: citySortBy },
        false
      );
    }
  };
  const handleViewMore = (city: City) => {
    setIsOpenModal(!isOpenModal);
  };
  const handleOnClick = (actionType: DestinationCardActionType, city: City) => {
    setSelectedCity(city);
    if (actionType == "VIEWMORE") {
      handleViewMore(city);
    } else {
      if (!selectedTreatment) {
        setIsTreatmentModelOpen(true);
      } else {
        navigate(
          `/city/${getURLFromString(city.country_name)}/${getURLFromString(
            city.city_name
          )}/${getURLFromString(selectedTreatment.treatment)}`
        );
      }
    }
  };
  const getTopRatedCities = async (
    payload: {
      offset: number;
      limit: number;
      sort_by: string;
    },
    isInitialLoad: boolean = true
  ) => {
    const { data, error } = await CureValueService(
      getTopRatedCitiesService,
      payload,
      { setIsLoading }
    );
    if (data) {
      totalCityRecords.current = data.totalCount;
      if (isInitialLoad) {
        setTopCities([...data.items]);
      } else {
        setTopCities([...topCities, ...data.items]);
      }
      // if (destinationSliderRef.current) {
      //   destinationSliderRef.current.slickPrev();
      // }
      setTimeout(() => {
        if (destinationSliderRef.current) {
          destinationSliderRef.current.slickPrev();
        }
      }, 1000);
    }
  };
  const handleonClose = (treatment: TreatmentContextModel | null) => {
    if (treatment) {
      if (selectedCity) {
        navigate(
          `/city/${getURLFromString(
            selectedCity.country_name
          )}/${getURLFromString(selectedCity.city_name)}/${getURLFromString(
            treatment.treatment
          )}`
        );
      } else {
        navigate(
          `/citySelection?service=${getURLFromString(treatment.treatment)}`
        );
      }
    }
    setIsTreatmentModelOpen(false);
  };

  const handleSelectedSort = (event: any) => {
    const sortName = event.target.value;
    setCitySortBy(sortName);
    // const sort = sortingOptions.find((sort: any) => sort.sortBy === sortName);
    // setSelectedSort(sort);
    // openFilter();
  };
  return (
    <>
      {isOpenModal && (
        <CityPopup setIsOpenModal={setIsOpenModal} city={selectedCity} />
      )}
      {isTreatmentModelOpen && (
        <TreatmentSearchPopup onClose={handleonClose}></TreatmentSearchPopup>
      )}
      <div className="flex flex-col lg:flex-row lg:items-center gap-[25px] lg:gap-[80px] lg:min-h-[690px] min-h-fit">
        <div className="w-[100%] lg:w-[40%]">
          <CitySliderTitle
            contentId={contentId}
            onClick={() => {
              setIsTreatmentModelOpen(true);
            }}
          />
          <div className="flex">
            <h2 className="pb-[10px] me-[15px] text-[16px] lg:text-[20px] font-semibold text-cardpara">
              Sort by
            </h2>
            <div className=" border-b border-borderclr  mb-[21px] xl:w-[40%] lg:w-[50%] w-[40%]">
              {/* <h2 className="pb-[30px] text-[27px] font-semibold text-cardpara">
              Sort By
            </h2> */}
              <select
                className="font-semibold text-[16px] lg:text-[20px] ps-[10px] text-cardpara bg-transparent appearance-none cursor-pointer w-[100%] selectcity"
                style={{ outline: "none", border: "none" }}
                onChange={handleSelectedSort}
              >
                {sortingOptions.map((sort: any) => {
                  return (
                    <option key={sort.sortBy} value={sort.sortBy}>
                      {sort.sortBy}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="w-[100%] lg:w-[60%]">
          <div className="destination-slider ">
            <Slider
              {...settings}
              ref={destinationSliderRef}
              afterChange={destinationSliderChanged}
            >
              {topCities.map((city: City) => {
                return (
                  <div key={city._id}>
                    <Destinationcard
                      onClick={(e) => handleOnClick(e, city)}
                      city={city}
                      handleShowSignUpPopup={handleShowSignUpPopup}
                    />
                  </div>
                );
              })}
              {isLoading && <Loader></Loader>}
            </Slider>
          </div>
          {topCities.length != 0 && (
            <div
              className={`slider-arrows flex items-center gap-[25px] 2xl:gap-[45px] pl-[20px] justify-start pt-[20px] 2xl:pt-[30px] ${
                isLoading ? "pointer-events-none" : "pointer-events-auto"
              }`}
            >
              <div className="prev-arrow" onClick={handlePrevClick}>
                <FaRegArrowAltCircleLeft
                  className={`${
                    isLoading ? "text-gray-400" : "text-cardpara"
                  } text-[38px] 2xl:text-[50px] cursor-pointer hover:text-hoverclr`}
                />
              </div>
              <div className="next-arrow" onClick={handleNextClick}>
                <FaRegArrowAltCircleRight
                  className={`${
                    isLoading ? "text-gray-400" : "text-cardpara"
                  } text-[38px] 2xl:text-[50px] cursor-pointer hover:text-hoverclr`}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default CityCarousel;
