import { useEffect, useState } from "react";
import {
  MdOutlineAttachMoney,
  MdEdit,
  MdOutlineSaveAlt,
  MdEmail,
} from "react-icons/md";
// import { MdOutlineSaveAlt } from "react-icons/md";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { getTreatmentCost } from "../../modules/facility";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import { useTreatment } from "../../modules/treatment";
import { useAuth } from "../../modules/auth";
import { FiShare } from "react-icons/fi";
import { IoClose, IoLogoWhatsapp } from "react-icons/io5";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { useEventEmmiter } from "../shared/eventEmmiter";
import { IoMdLock } from "react-icons/io";
import { FaCopy, FaFacebookSquare } from "react-icons/fa";

const SavingsCalculator = ({
  facility,
  cityName,
  selectedTreatment,
  isBeforeLogin,
  handleShowSignUpPopup,
  handleBookingpopup,
}: any) => {
  const currentUser = useAuth();
  // const selectedTreatment = useTreatment();
  const [treatmentCost, setTreatmentCost] = useState<any>(null);
  const [subTreatmentNameOne, setSubtreatmentNameOne] = useState<any>("");
  const [subTreatmentNameTwo, setSubtreatmentNameTwo] = useState<any>("");
  const [usCostOne, setUsCostOne] = useState<any>(0);
  const [cityCostOne, setCityCostOne] = useState<any>(0);
  const [usCostTwo, setusCostTwo] = useState<any>(0);
  const [cityCostTwo, setCityCostTwo] = useState<any>(0);
  const [barHeightOne, setBarHeightOne] = useState<any>(0);
  const [barHeightTwo, setBarHeightTwo] = useState<any>(0);
  const [barHeightThree, setBarHeightThree] = useState<any>(0);
  const [barHeightFour, setBarHeightFour] = useState<any>(0);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isFixed, setIsFixed] = useState(false);
  const [shareUrl, setShareUrl] = useState<any>(null);
  const eventEmmiter = useEventEmmiter();

  const percentage = 30;
  const percentage2 = 65;
  const navigate = useNavigate();

  const toggleSharePopup = (e: any) => {
    e.preventDefault();
    setIsOpenModal(!isOpenModal);
  };

  useEffect(() => {
    const textToCopy =
      process.env.REACT_APP_BASEURL +
      "/location/" +
      facility.country_name +
      "/" +
      facility.city_name +
      "/" +
      facility.facility_name +
      "?treatment=" +
      selectedTreatment;

    setShareUrl(textToCopy);
  }, [selectedTreatment]);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        eventEmmiter.cvNotify({
          message: "Link copied to clipboard successfully",
          type: "INFO",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  useEffect(() => {
    if (!treatmentCost || treatmentCost.length < 1) return;
    let usCostOne = treatmentCost[0].us_cost;
    let cityCostOne = treatmentCost[0].city_cost;
    let usCostTwo = treatmentCost[1].us_cost;
    let cityCostTwo = treatmentCost[1].city_cost;

    setSubtreatmentNameOne(treatmentCost[0].subTreatment_name);
    setSubtreatmentNameTwo(treatmentCost[1].subTreatment_name);

    setUsCostOne(treatmentCost[0].us_cost);
    setCityCostOne(treatmentCost[0].city_cost);
    setusCostTwo(treatmentCost[1].us_cost);
    setCityCostTwo(treatmentCost[1].city_cost);

    let maxValue = Math.max(cityCostOne, usCostOne, cityCostTwo, usCostTwo);
    setBarHeightOne(20);
    setBarHeightTwo(20);
    setBarHeightThree(20);
    setBarHeightFour(20);
    if (treatmentCost[0].us_cost != maxValue) {
      setBarHeightOne((20 * usCostOne) / maxValue);
    }
    if (treatmentCost[0].city_cost != maxValue) {
      setBarHeightTwo((20 * cityCostOne) / maxValue);
    }
    if (treatmentCost[1].us_cost != maxValue) {
      setBarHeightThree((20 * usCostTwo) / maxValue);
    }
    if (treatmentCost[1].city_cost != maxValue) {
      setBarHeightFour((20 * cityCostTwo) / maxValue);
    }
  }, [treatmentCost]);

  //get all facilities by city and treatment id
  const getCost = async (
    payload: {
      city_name: string;
      treatment_name: string;
    },
    isInitialLoad: boolean = true
  ) => {
    const { data, error } = await CureValueService(getTreatmentCost, payload);
    if (data) {
      if (data.length == 1) {
        data.push({
          ...data[0],
          city_cost: "0",
          subTreatment_name: "NA",
          us_cost: "0",
        });
      }
      if (isInitialLoad) {
        setTreatmentCost(data);
        // setUsTreatmentCost(data.usCost.treatment_cost);
      } else {
        setTreatmentCost(data);
        // setUsTreatmentCost(data.usCost.treatment_cost);
      }
      // destinationSliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    if (!cityName || !selectedTreatment) return;
    getCost({
      city_name: cityName.replace(/-/g, " "),
      treatment_name: selectedTreatment,
    });
  }, [facility]);

  const capitalizeFirstLetter = (string: any) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleLockBtnClick = () => {
    handleShowSignUpPopup();
  };
  const handleContactUsClick = () => {
    if (currentUser.auth) {
      handleBookingpopup();
    } else {
      handleShowSignUpPopup();
    }
  };

  return (
    <div id="saving" className="lg:mt-[100px]">
      <div className="flex items-center justify-center gap-[5px] lg:pb-[27px] lg:max-w-[360px]">
        <MdOutlineAttachMoney className="text-iconclr text-h3font lg:text-[34px]" />
        <h2 className="text-sc text-h3font font-semibold lg:text-[18px]">
          CureValue savings meter
        </h2>
        <FiShare
          size={"34px"}
          onClick={(e) => {
            toggleSharePopup(e);
          }}
          className={`cursor-pointer ${
            isFixed ? "text-white ease-in-out duration-300" : ""
          }`}
        />
      </div>
      {isBeforeLogin ? (
        <>
          <div className="relative top-[300px] lg:top-[90px] z-[5] flex-col items-center flex">
            <div
              onClick={handleLockBtnClick}
              className="flex flex-col items-center text-center w-[180px] lg:w-[200px]"
            >
              <IoMdLock size={"37px"} className="text-cardpara mb-2" />
              <p>Create a free account or login to view</p>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div
        className={`flex flex-col justify-center text-center items-center bg-white shadow-cardshadow lg:w-[360px] rounded-[22px] px-[27px] pt-[14px] pb-[30px] mb-[30px] ml-auto calculator ${
          isBeforeLogin ? "blur-text" : ""
        }`}
      >
        <div className="flex items-end gap-[10%]  w-full">
          <div className="flex flex-col items-center w-[50%]">
            <div className="flex gap-[5px]">
              <div className="flex flex-col gap-[5px] items-center justify-end">
                <h3 className="text-black font-semibold text-[18px]">
                  ${usCostOne}
                </h3>
                <div
                  className="w-[48px] bg-[#1C375A29] h-[300px] rounded-[4px]"
                  style={{ height: `${barHeightOne}vh` }}
                ></div>
              </div>
              <div className="flex flex-col gap-[5px] items-center justify-end">
                <h3 className="text-black font-semibold text-[18px]">
                  ${cityCostOne}
                </h3>
                <div
                  className="w-[48px] bg-[#4AB972] h-[150px] rounded-[4px]"
                  style={{ height: `${barHeightTwo}vh` }}
                ></div>
              </div>
            </div>
            {/* <div>{subTreatmentNameOne}</div> */}
          </div>
          <div className="flex flex-col items-center w-[50%]">
            <div className="flex gap-[5px]">
              <div className="flex flex-col gap-[5px] items-center justify-end">
                <h3 className="text-black font-semibold text-[18px]">
                  ${usCostTwo}
                </h3>
                <div
                  className="w-[48px] bg-[#1C375A29] h-[160px] rounded-[4px]"
                  style={{ height: `${barHeightThree}vh` }}
                ></div>
              </div>
              <div className="flex flex-col gap-[5px] items-center justify-end">
                <h3 className="text-black font-semibold text-[18px]">
                  ${cityCostTwo}
                </h3>
                <div
                  className="w-[48px] bg-[#4AB972] h-[120px] rounded-[4px]"
                  style={{ height: `${barHeightFour}vh` }}
                ></div>
              </div>
            </div>
            {/* <div>{subTreatmentNameTwo}</div> */}
          </div>
        </div>
        <div className="flex gap-[10%] w-full">
          <div className="w-[50%] mb-[13px] break-words">
            {subTreatmentNameOne}
          </div>
          <div className="w-[50%] break-words">{subTreatmentNameTwo}</div>
        </div>
        <div className="flex gap-[10px]">
          <h3 className="text-smalltext font-semibold text-cardpara flex gap-[5px] items-center">
            <div className="w-[10px] h-[10px] bg-[#1C375A29]"></div>U.S. Cost
          </h3>
          <h3 className="text-smalltext font-semibold text-cardpara flex gap-[5px] items-center">
            <div className="w-[10px] h-[10px] bg-[#4AB972]"></div>{" "}
            {capitalizeFirstLetter(cityName)} Cost
          </h3>
        </div>

        {/* <button
          className="flex bg-pc text-white gap-[10px] items-center text-left px-[24px] py-[11px] rounded-full text-smalltext font-semibold mt-[21px] border border-pc group hover:bg-white hover:text-pc"
          onClick={() => {
            navigate("/pricing", { replace: true, state: facility });
          }}
        >
          <MdEdit className="text-white text-[21px] group-hover:text-pc" />
          Personalize & Save
        </button> */}
      </div>

      <div className="flex items-center justify-center gap-[5px] cursor-pointer">
        {/* <p className="text-black">Share with your friends </p> */}
        {/* <h2 className="text-sc text-h3font font-semibold lg:text-[25px] lg:font-bold">
          Share this page
        </h2> */}

        <button
          className="btn bg-pc text-white rounded-full font-anchor font-medium py-[7px] px-[27px] w-full"
          onClick={handleContactUsClick}
        >
          Customize your savings
        </button>
      </div>

      {isOpenModal && (
        <div
          className="z-[200] fixed top-0 left-0 w-full h-full flex justify-end items-start bg-black bg-opacity-50"
          onClick={(e) => {
            toggleSharePopup(e);
          }}
        >
          <div className=" bg-white rounded-[24px] p-[20px] lg:py-[50px] lg:pl-[50px] w-[95%] lg:w-[30%] me-[20px] relative mt-[42vh] me-[200px]">
            <div className="absolute right-0 top-0 lg:right-[5%] lg:top-[5%]">
              <span
                className="close cursor-pointer"
                onClick={(e) => {
                  toggleSharePopup(e);
                }}
              >
                <IoClose size={"40px"} className="text-black" />
              </span>
            </div>

            <div className="max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
              <div className="lg:max-w-[90%] modal-hospital">
                <h6 className="capitalize text-[20px]  font-semibold text-cardpara text-left pt-[5px] pb-[25px] items-start">
                  Share
                </h6>
                <div className="grid lg:grid-cols-2 items-start gap-[20px] lg:gap-[40px]">
                  <div
                    className="flex items-center justify-center gap-[10px] border border-pc rounded-[10px] cursor-pointer"
                    onClick={copyToClipboard}
                  >
                    <FaCopy size={20} className="text-pc" />
                    <h6 className="capitalize text-anchortext font-semibold text-cardpara  text-center pt-[10px] pb-[10px]">
                      Copy Link
                    </h6>
                  </div>
                  <EmailShareButton
                    url={shareUrl}
                    subject={facility.facility_name}
                  >
                    <div className="flex items-center justify-center gap-[10px] border border-pc rounded-[10px] cursor-pointer">
                      <MdEmail size={20} className="text-pc" />
                      <div className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[10px] pb-[10px]">
                        Email
                      </div>
                    </div>
                  </EmailShareButton>
                  <FacebookShareButton url={shareUrl}>
                    <div className="flex items-center justify-center gap-[10px] border border-pc rounded-[10px] cursor-pointer">
                      <FaFacebookSquare size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[10px] pb-[10px]">
                        Facebook
                      </h6>
                    </div>
                  </FacebookShareButton>
                  <WhatsappShareButton
                    url={shareUrl}
                    title={facility.facility_name}
                  >
                    <div className="flex items-center justify-center gap-[10px] border border-pc rounded-[10px] cursor-pointer">
                      <IoLogoWhatsapp size={20} className="text-pc" />
                      <h6 className="capitalize text-anchortext font-semibold text-cardpara text-center pt-[10px] pb-[10px]">
                        WhatsApp
                      </h6>
                    </div>
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SavingsCalculator;
