import { MouseEvent, useState } from "react";
import { BiSolidDownArrow, BiSolidRightArrow } from "react-icons/bi";
import { IoMdHeart, IoMdHeartEmpty } from "react-icons/io";
import { PiMedalFill } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import cardimg from "../../../assets/images/destination-img1.png";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import { useAuth } from "../../modules/auth";
import treatmentColourMap from "../../modules/common/core/treatmentColourMap.json";
import { Facility } from "../../modules/facility/core/_models";
import {
  addToFavorite,
  removeFromFavorite,
} from "../../modules/favorite/core/FavoriteService";
import { AddRemoveFavoriteRequest } from "../../modules/favorite/core/_models";
import CureValueRating from "../shared/CureValueRating";
import { Treatment } from "../../modules/treatment";
import { FACILITY_IMAGE_CDN } from "../shared/urlConstants";
export type FacilityCardActionType = "VIEWMORE" | "CARDCLICK";
interface FacilityCardProps {
  facility?: Facility;
  onClick?: (actionType: FacilityCardActionType) => void;
  handleShowSignUpPopup?: () => void;
  showTreatment?: boolean;
}

const FacilityCard = ({
  facility: facilityProp,
  onClick,
  handleShowSignUpPopup,
  showTreatment = true,
}: FacilityCardProps) => {
  const [facility, setFacility] = useState<Facility | undefined>(facilityProp);
  const [showSignuptooltip, setShowSignuptooltip] = useState<boolean>(false);
  const navigate = useNavigate();
  const getTreatmentColour = (treatment: string) => {
    const colour = JSON.parse(JSON.stringify(treatmentColourMap))[treatment];

    return colour;
  };
  const { currentUser } = useAuth();

  const favoriteClickHandle = (e: MouseEvent) => {
    e.stopPropagation();
    if (currentUser) {
      changeFavorite();
    } else {
      handleShowSignUpPopup?.();
      // navigate("/auth/login");
    }
  };
  const changeFavorite = async () => {
    let payload: AddRemoveFavoriteRequest = {
      favourite_type: "facility",
      facility_id: facility?._id,
    };
    if (
      facility?.is_favourite == false ||
      facility?.is_favourite == undefined
    ) {
      const { data, error } = await CureValueService(addToFavorite, payload);
      if (data) {
        if (facility) {
          setFacility({ ...facility, is_favourite: true });
        }
      }
    } else {
      const { data, error } = await CureValueService(
        removeFromFavorite,
        payload
      );
      if (data) {
        if (facility) {
          setFacility({ ...facility, is_favourite: false });
        }
      }
    }
  };
  return (
    <div
      className=" min-h-[475px] h-[60vh] xl:h-[60vh] lg:h-[40vh] w-[350px] lg:w-auto facilityipad cursor-pointer border-[3px] border-borderclr hover:border-hoverclr rounded-[30px] px-[15px] pb-[19px] lg:px-[32px] lg:pb-[25px] bg-white relative pt-[110px] lg:pt-[140px] destinationcard flex flex-col"
      onClick={(e: MouseEvent) => {
        e.stopPropagation();
        if (onClick) onClick("CARDCLICK");
      }}
    >
      <img
        src={
          facility?.image_urls.length != 0
            ? `${
                FACILITY_IMAGE_CDN +
                facility?._id +
                "/" +
                facility?.image_urls[0]
              }`
            : cardimg
        }
        // src={cardimg}
        alt={facility?.facility_name.toLocaleLowerCase()}
        //"w-[100%] max-w-[340px]  m-auto absolute left-0 right-0  top-[-100px] lg:top-[-70px] h-[200px] rounded-[25px] object-cover"
        className=" aspect-video w-[90%] max-h-[200px] lg:max-h-[200px] lg:max-w-[340px] lg:mb-[19px] rounded-[30px] m-auto absolute left-0 right-0 top-[-100px] lg:top-[-70px] object-contain bg-[#5f697b]"
      />
      <div
        className="absolute right-[30px] top-[-90px] lg:top-[-60px] lg:right-[70px] 2xl:right-[75px] text-[20px] p-[8px] bg-white text-hoverclr rounded-full cursor-pointer"
        onClick={(e) => favoriteClickHandle(e)}
      >
        {facility?.is_favourite == false ||
        facility?.is_favourite == undefined ? (
          <IoMdHeartEmpty />
        ) : (
          <IoMdHeart />
        )}
      </div>
      {/* {showSignuptooltip && (
        <div className="absolute top-[-90px] right-[70px] px-2 border border-hoverclr bg-white rounded h-[30px] cursor-default">
          <span
            className="text-hoverclr cursor-pointer"
            onClick={() => navigate("/auth/signup")}
          >
            Sign up
          </span>{" "}
          to mark as Favorite
          <BiSolidDownArrow className=" hidden lg:block absolute right-2 bottom-[-13px] text-hoverclr" />
          <BiSolidRightArrow className="absolute lg:hidden right-[-12px] top-[7px] text-hoverclr" />
        </div>
      )} */}
      <div className="flex items-center justify-between lg:pt-[13px] 2xl:pt-[31px] pb-[8px]">
        <h2 className="text-sc text-h4font/[22px] lg:text-[21px]/[27px] font-semibold 2xl:pb-[10px] max-w-[70%] lg:max-w-[70%]">
          <span className=" block text-ellipsis overflow-hidden whitespace-nowrap">
            {facility?.facility_name}{" "}
          </span>

          <span className="text-pfont/[20px] lg:text-[18px]/[26px] font-medium block text-ellipsis overflow-hidden whitespace-nowrap">
            {facility?.facility_location}
          </span>
        </h2>

        <div className="flex items-center gap-[13px]">
          <CureValueRating
            rating={
              facility?.curevalue_rating ? facility?.curevalue_rating : ""
            }
          ></CureValueRating>
          {/* <IoMdInformationCircleOutline className="cursor-pointer text-[20px] text-iconclr2 2xl:text-[30px]"/> */}
        </div>
      </div>
      {showTreatment && (
        <div className="flex flex-wrap items-center gap-[10px] pt-[11px] lg:pt-[9px] pb-[12px]">
          {facility?.treatments_offered
            .slice(0, 1)
            .map((treatment: Treatment) => {
              return (
                <p
                  className={`text-[10px] xl:text-anchortext font-medium px-[8px] py-[2px] ${getTreatmentColour(
                    treatment.name
                  )}`}
                  key={Math.random()}
                >
                  {treatment.name}
                </p>
              );
            })}
          {facility?.treatments_offered &&
            facility.treatments_offered.length > 1 && (
              <a className="text-pc">
                and {facility.treatments_offered.length - 1} more
              </a>
            )}
        </div>
      )}
      {/* {facility?.accreditations.length != 0 && (
        <div className="flex items-center gap-[5px] pb-[6px] xl:pb-[12px]">
          <PiMedalFill className="text-cardpara lg:text-[20px] 2xl:text-[35px]" />
          <p className="font-medium text-cardpara text-[10px] lg:text-anchortext ">
            {facility?.accreditations.join(",")}
          </p>
        </div>
      )} */}
      <div className="flex flex-col flex-grow  justify-between">
        <span className="font-medium text-smalltext lg:text-[15px] text-sc pb-[18px] lg:pb-[22px]  overflow-y-auto text-justify pr-2 max-h-[30vh] h-[28vh] lg:h-[18vh] lg:max-h-[20vh]">
          {facility?.facility_description}
        </span>

        <span
          className="text-smalltext lg:text-anchortext 2xl:text-pfont underline text-[#006AF5] font-medium cursor-pointer"
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            if (onClick) onClick("VIEWMORE");
          }}
        >
          View more
        </span>
      </div>
    </div>
  );
};

export default FacilityCard;
