import { useEffect, useState } from "react";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import { getTreatmentCost } from "../../modules/facility";
import { useAuth } from "../../modules/auth";
import { IoMdLock } from "react-icons/io";

const SavingsMobile = ({
  facility,
  cityName,
  selectedTreatment,
  isBeforeLogin,
  handleShowSignUpPopup,
  handleBookingpopup,
}: any) => {
  const currentUser = useAuth();
  const [treatmentCost, setTreatmentCost] = useState<any>(null);
  const [subTreatmentNameOne, setSubtreatmentNameOne] = useState<any>("");
  const [subTreatmentNameTwo, setSubtreatmentNameTwo] = useState<any>("");
  const [usCostOne, setUsCostOne] = useState<any>(0);
  const [cityCostOne, setCityCostOne] = useState<any>(0);
  const [usCostTwo, setusCostTwo] = useState<any>(0);
  const [cityCostTwo, setCityCostTwo] = useState<any>(0);
  const [barHeightOne, setBarHeightOne] = useState<any>(0);
  const [barHeightTwo, setBarHeightTwo] = useState<any>(0);
  const [barHeightThree, setBarHeightThree] = useState<any>(0);
  const [barHeightFour, setBarHeightFour] = useState<any>(0);

  useEffect(() => {
    if (!treatmentCost || treatmentCost.length < 1) return;
    let usCostOne = treatmentCost[0].us_cost;
    let cityCostOne = treatmentCost[0].city_cost;
    let usCostTwo = treatmentCost[1].us_cost;
    let cityCostTwo = treatmentCost[1].city_cost;

    setSubtreatmentNameOne(treatmentCost[0].subTreatment_name);
    setSubtreatmentNameTwo(treatmentCost[1].subTreatment_name);

    setUsCostOne(treatmentCost[0].us_cost);
    setCityCostOne(treatmentCost[0].city_cost);
    setusCostTwo(treatmentCost[1].us_cost);
    setCityCostTwo(treatmentCost[1].city_cost);

    let maxValue = Math.max(cityCostOne, usCostOne, cityCostTwo, usCostTwo);
    setBarHeightOne(300);
    setBarHeightTwo(300);
    setBarHeightThree(300);
    setBarHeightFour(300);

    if (treatmentCost[0].us_cost != maxValue) {
      setBarHeightOne((300 * usCostOne) / maxValue);
    }
    if (treatmentCost[0].city_cost != maxValue) {
      setBarHeightTwo((300 * cityCostOne) / maxValue);
    }
    if (treatmentCost[1].us_cost != maxValue) {
      setBarHeightThree((300 * usCostTwo) / maxValue);
    }
    if (treatmentCost[1].city_cost != maxValue) {
      setBarHeightFour((300 * cityCostTwo) / maxValue);
    }
  }, [treatmentCost]);

  //get all facilities by city and treatment id
  const getCost = async (
    payload: {
      city_name: string;
      treatment_name: string;
    },
    isInitialLoad: boolean = true
  ) => {
    const { data, error } = await CureValueService(getTreatmentCost, payload);
    if (data) {
      if (isInitialLoad) {
        setTreatmentCost(data);
        // setUsTreatmentCost(data.usCost.treatment_cost);
      } else {
        setTreatmentCost(data);
        // setUsTreatmentCost(data.usCost.treatment_cost);
      }
      // destinationSliderRef.current.slickPrev();
    }
  };

  useEffect(() => {
    if (!cityName || !selectedTreatment) return;
    getCost({
      city_name: cityName.replace(/-/g, " "),
      treatment_name: selectedTreatment,
    });
  }, [facility]);

  const capitalizeFirstLetter = (string: any) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  const handleLockBtnClick = () => {
    handleShowSignUpPopup();
  };
  const handleContactUsClick = () => {
    if (currentUser.auth) {
      handleBookingpopup();
    } else {
      handleShowSignUpPopup();
    }
  };

  return (
    <div className="fixed z-10 bottom-0 block lg:hidden w-full bg-[#fcfcfc] shadow-cardshadow border border-x-0 border-b-0 border-t-1 border-pc">
      {isBeforeLogin ? (
        <>
          <div className="fixed   lg:top-[90px] lg:left-[90px] z-[5] flex-col items-center flex w-full p-10">
            <div
              onClick={handleLockBtnClick}
              className="flex flex-col items-center text-center w-[180px] lg:w-[200px]"
            >
              <IoMdLock size={"37px"} className="text-cardpara mb-2" />
              <p>Create a free account or login to view</p>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
      <div
        className={`py-4 flex flex-col gap-4 container mx-auto ${
          isBeforeLogin ? "blur-text" : ""
        }`}
      >
        <div className="grid grid-cols-3 gap-2 text-cardpara font-semibold">
          <p className="col-span-1 text-left">Treatment</p>
          <p className="col-span-1 text-center">U.S Cost</p>
          <p className="col-span-1 text-right">
            {capitalizeFirstLetter(cityName)} Cost
          </p>
        </div>

        <div className="grid grid-cols-3 gap-2 text-cardpara font-semibold">
          <p className="col-span-1 text-left">{subTreatmentNameOne}</p>
          <p className="col-span-1 text-center">${usCostOne}</p>
          <p className="col-span-1 text-right text-green-500">${cityCostOne}</p>
        </div>

        <div className="grid grid-cols-3 gap-2 text-cardpara font-semibold">
          <p className="col-span-1 text-left">{subTreatmentNameTwo}</p>
          <p className="col-span-1 text-center">${usCostTwo}</p>
          <p className="col-span-1 text-right text-green-500">${cityCostTwo}</p>
        </div>
        <div className="grid  gap-2 text-cardpara font-semibold">
          <button
            className="btn bg-pc text-white rounded-full font-anchor font-medium py-[7px] px-[27px] w-full"
            onClick={handleContactUsClick}
          >
            Customize your savings
          </button>
        </div>
      </div>
    </div>
  );
};

export default SavingsMobile;
