import {
  FaStethoscope,
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
  FaHandHoldingMedical,
} from "react-icons/fa";
import treatmentimg from "../../../assets/images/Treatment.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import React, { useRef } from "react";
import { GrNext } from "react-icons/gr";
import treatmentColourMap from "../../modules/common/core/treatmentColourMap.json";
import { IoMdLock } from "react-icons/io";

const TreatmentsCard = ({
  facility,
  cardFor,
  isBeforeLogin,
  handleShowSignUpPopup,
}: any) => {
  const sliderRef = useRef<any>(null);
  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  const getTreatmentColour = (treatment: string) => {
    const colour = JSON.parse(JSON.stringify(treatmentColourMap))[treatment];

    return colour;
  };

  var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: <FaRegArrowAltCircleLeft />,
    nextArrow: <FaRegArrowAltCircleRight />,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };

  const handleLockBtnClick = () => {
    handleShowSignUpPopup();
  };
  return (
    <div id="treatments">
      <div className="mb-[30px] lg:mb-[52px] pb-[27px] lg:pb-[58px] border-b border-borderclr">
        <div className="flex items-center gap-[5px] pb-[16px]">
          <FaHandHoldingMedical className="text-iconclr text-h3font lg:text-[30px]" />
          <h2 className="text-sc text-h3font font-semibold lg:text-[20px] lg:font-bold">
            Treatments
          </h2>
        </div>

        {isBeforeLogin ? (
          <>
            <div>
              <div className="relative top-[300px] left-[80px] lg:top-[120px] lg:left-[350px] z-[5] flex-col items-center">
                <div
                  onClick={handleLockBtnClick}
                  className="flex flex-col items-center text-center w-[180px] lg:w-[200px]"
                >
                  <IoMdLock size={"37px"} className="text-cardpara mb-2" />
                  <p>Create a free account or login to view</p>
                </div>
              </div>
              <p
                className={`text-[19px] lg:text-pfont/[26px] text-cardpara mb-[10px] ${
                  isBeforeLogin ? "blur-text" : ""
                }`}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque
                velit laborum quod deserunt! Molestiae ad aliquam minus! Autem
                nesciunt accusamus fugit voluptatum, adipisci quibusdam a
                deserunt quod sapiente officiis sequi, alias ullam blanditiis.
                Tempore cumque iusto dicta perferendis accusantium ad rem
                asperiores! Libero culpa mollitia nobis alias maxime quos
                impedit aperiam soluta! Voluptatibus, odit fuga. Harum quaerat
                laborum reiciendis, doloribus minima nemo assumenda nisi, eum,
                magni praesentium dolor eaque tempora adipisci natus! Dolorem
                officia, nostrum dignissimos odit magni tempora repudiandae modi
                expedita nobis, porro voluptatibus non ipsam enim quas et eaque
                ipsum praesentium. Quod laborum hic atque debitis doloremque
                dicta.
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-wrap gap-[10px]">
              {facility?.treatments_offered.map((treatment: string) => {
                return (
                  <p
                    className={`text-[10px]  xl:text-anchortext font-medium px-[8px] py-[2px] ${getTreatmentColour(
                      treatment
                    )}`}
                    key={Math.random()}
                  >
                    {treatment}
                  </p>
                );
              })}
            </div>
          </>
        )}

        {/* <div className="treatment-slider">
          <Slider {...settings} ref={sliderRef}>
            {facility.treatments_offered.map((treatment: any) => {
              return (
                <>
                  <div>
                    <div className="hover:shadow-cardshadow cursor-pointer border border-borderclr rounded-[30px] bg-white relative pt-[50px] lg:pt-[130px] mb-[30px] lg:mb-[50px] treatment-slide">
                      <img
                        src={treatmentimg}
                        alt=""
                        className="w-[90%] pb-[23px] m-auto absolute left-0 right-0 top-[-110px]"
                      />
                      <div className="p-[30px] pt-[40px]">
                        <h2 className="text-extraclr3 text-[21px] font-semibold">
                          {treatment}
                        </h2>
                        <p className="text-underline text-h6font underline underline-offset-2 font-semibold text-cardpara">
                          Specialises in consectetu adipi
                        </p>
                        <p className="font-medium text-cardpara text-anchortext py-[9px]">
                          m ipsum dolor sit amet, consectetur adipiscing elit,
                          sed do eiusmod tempor incididunt ut labore et dolore
                          magna aliqua. Ut enim ad minim veniam, quis nostrud
                          exercitation ullamco laboris nisi ut aliquip ex ea
                          commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum
                        </p>
                        <a
                          href="#"
                          className="text-[19px] font-semibold text-cardpara underline underline-offset-2 flex items-center gap-[5px]"
                        >
                          Know more
                          <GrNext className="text-anchortext text-cardpara" />
                        </a>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div> */}

        {/* <div className="slider-arrows flex items-center gap-[25px] lg:gap-[45px] pl-[20px] justify-start">
          <div className="prev-arrow" onClick={handlePrevClick}>
            <FaRegArrowAltCircleLeft className="text-extraclr1 text-[38px] lg:text-[50px] cursor-pointer" />
          </div>
          <div className="next-arrow" onClick={handleNextClick}>
            <FaRegArrowAltCircleRight className="text-extraclr1 text-[38px] lg:text-[50px] cursor-pointer" />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default TreatmentsCard;
