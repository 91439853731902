import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";
import Header from "../shared/Header";
import Footer from "../shared/Footer";

export interface ILayout {
  isHeader: boolean;
  isFooter: boolean;
}
type LayoutContextProps = {
  layout: ILayout;
  setLayout: Dispatch<SetStateAction<ILayout>>;
};

const initLayoutContextPropsState: LayoutContextProps = {
  layout: { isFooter: true, isHeader: true },
  setLayout: () => {},
};
const LayoutContext = createContext<LayoutContextProps>(
  initLayoutContextPropsState
);

const useLayout = () => {
  return useContext(LayoutContext);
};

const LayoutProvider = ({ children }: any) => {
  const [layout, setLayout] = useState<ILayout>(
    initLayoutContextPropsState.layout
  );

  return (
    <LayoutContext.Provider value={{ layout, setLayout }}>
      {layout.isHeader && <Header></Header>}
      {children}
      {layout.isFooter && <Footer></Footer>}
    </LayoutContext.Provider>
  );
};

export { LayoutProvider, useLayout };
