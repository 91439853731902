import { useEffect, useState } from "react";
import { FaMountainSun } from "react-icons/fa6";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate, useParams } from "react-router-dom";
import { deConstructURLSegment } from "../../helpers/CVHelper";
import { CureValueService } from "../../helpers/ServiceWrapper";
import FacilityCompareCard from "../components/facility/FacilityCompareCard";
import FacilityCompareSlide from "../components/facility/FacilityCompareSlide";
import FavoritePopup, {
  FavoritePopupCloseEvent,
} from "../components/shared/FavoritePopup";
import { Facility, GetByNameRequest, getByName } from "../modules/facility";
import { getAllFavorites } from "../modules/favorite/core/FavoriteService";
import { useAuth } from "../modules/auth";
import {
  EmailShareButton,
  FacebookShareButton,
  WhatsappShareButton,
} from "react-share";
import { useEventEmmiter } from "../components/shared/eventEmmiter";
import { IoClose } from "react-icons/io5";
import { FiShare } from "react-icons/fi";

const FacilityCompare = () => {
  const { facilityCompareNames } = useParams();
  const [facilities, setFacilities] = useState<Facility[]>([]);
  const navigate = useNavigate();
  const [isFavoritePopupOpen, setIsFavoritePopupOpen] = useState(false);
  const [favoriteFacilities, setFavoriteFacilities] = useState<Facility[]>([]);
  const { currentUser } = useAuth();
  const [shareUrl, setShareUrl] = useState<any>(null);
  const eventEmmiter = useEventEmmiter();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isFixed, setIsFixed] = useState(false);

  const getMyFavorite = async (isOnLoad: boolean = false) => {
    const { data, error } = await CureValueService(getAllFavorites, null);
    if (data) {
      setFavoriteFacilities(data.allFavouriteFacilities);
    }
  };

  useEffect(() => {
    if (facilityCompareNames) {
      const textToCopy =
        process.env.REACT_APP_BASEURL + "/compare/" + facilityCompareNames;
      setShareUrl(textToCopy);
    }

    const facilityNamesWithCityCountry = facilityCompareNames?.split("-vs-");
    const facilities: GetByNameRequest[] = [];
    facilityNamesWithCityCountry?.forEach((nameWithCityAndCountry) => {
      const facilityNamesAndCityArray = nameWithCityAndCountry.split("-city-");
      const cityAndCountry = facilityNamesAndCityArray[1].split("-country-");
      facilities.push({
        facilityName: deConstructURLSegment(facilityNamesAndCityArray[0]),
        cityName: deConstructURLSegment(cityAndCountry[0]),
        countryName: deConstructURLSegment(cityAndCountry[1]),
      });
    });
    getFacilityDetailsByNames(facilities);
    if (currentUser) {
      getMyFavorite();
    }
  }, []);
  useEffect(() => {
    if (isOpenModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpenModal]);
  const getFacilityDetailsByNames = (payload: GetByNameRequest[]) => {
    const facilityList: Facility[] = [];
    payload.forEach(async (request) => {
      const { data, error } = await CureValueService(getByName, request);
      if (data) {
        facilityList.push(data);
      }
      if (facilityList.length == payload.length) {
        setFacilities(facilityList);
      }
    });
  };

  const handleonClose = (event: FavoritePopupCloseEvent | null) => {
    if (event && event.selectedFaciltiy) {
      setFacilities([...facilities, event.selectedFaciltiy]);
    }
    setIsFavoritePopupOpen(false);
  };
  const onFacilityCloseClicked = (facilityClosed: Facility) => {
    let newFacilityList = [];
    newFacilityList = facilities.filter(
      (facility: Facility) => facility._id != facilityClosed._id
    );
    setFacilities([...newFacilityList]);
  };
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        eventEmmiter.cvNotify({
          message: "Link copied to clipboard successfully",
          type: "INFO",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const toggleSharePopup = (e: any) => {
    e.preventDefault();
    setIsOpenModal(!isOpenModal);
  };
  return (
    <>
      {isFavoritePopupOpen && currentUser && (
        <FavoritePopup
          exsistingComparedEntity={facilities}
          typeOfFavorite="Facility"
          onClose={handleonClose}
          favoriteFacilities={favoriteFacilities}
          favoriteCities={[]}
        ></FavoritePopup>
      )}
      <div className="bg-[#F5F7FA] pt-[70px] lg:pt-[80px] pb-[70px]">
        <div className="lg:block lg:max-w-[1800px] m-auto px-[24px] pt-[30px] pb-[24px]">
          <div
            className={`flex items-center  pt-[10px] w-[100%] ${
              currentUser ? "justify-between" : "justify-end"
            }`}
          >
            {currentUser && (
              <div
                className="flex items-center gap-[4px]  cursor-pointer"
                onClick={() => {
                  navigate("/myfavorites");
                }}
              >
                <MdArrowBackIos className="text-extraclr3 text-[24px] lg:text-iconclr3 lg:text-[20px]" />
                <a className="text-pfont lg:text-[18px] font-medium text-sc font-sans whitespace-nowrap backbtn">
                  See all favourites
                </a>
              </div>
            )}
            <div className=" flex items-center gap-[22px]">
              <FiShare
                size={"30px"}
                className={`cursor-pointer ${
                  isFixed ? "text-white ease-in-out duration-300" : ""
                }`}
                onClick={(e) => {
                  toggleSharePopup(e);
                }}
              />

              {/* <FaBookmark className="cursor-pointer text-h4font lg:text-[30px]" /> */}
              {isOpenModal && (
                <div
                  className="z-[200] fixed top-0 left-0 w-full h-full flex justify-center lg:justify-end items-start bg-black bg-opacity-50"
                  onClick={(e) => {
                    toggleSharePopup(e);
                  }}
                >
                  <div className=" bg-white rounded-[24px] p-[20px] lg:py-[50px] lg:pl-[50px] w-[95%] lg:w-[30%] relative mt-[150px] lg:me-[20px] me-0">
                    <div className="absolute right-0 top-0 lg:right-[5%] lg:top-[5%]">
                      <span
                        className="close cursor-pointer"
                        onClick={(e) => {
                          toggleSharePopup(e);
                        }}
                      >
                        <IoClose size={"40px"} className="text-black" />
                      </span>
                    </div>
                    <div className="max-h-[80vh] lg:max-h-[70vh] overflow-y-auto">
                      <div className="lg:max-w-[90%] modal-hospital">
                        <h6 className=" text-[20px]  font-semibold text-cardpara text-center pt-[5px] pb-[25px]">
                          Share this facility comparison
                        </h6>
                        <div className="grid lg:grid-cols-2 items-start gap-[20px] lg:gap-[40px]">
                          <div onClick={copyToClipboard}>
                            <h6 className="capitalize text-anchortext font-semibold text-cardpara border border-gray-400 rounded-[10px] cursor-pointer text-center p-[5px] ">
                              Copy Link
                            </h6>
                          </div>
                          <div>
                            <h6 className="capitalize text-anchortext font-semibold text-cardpara border border-gray-400 rounded-[10px] cursor-pointer text-center p-[5px]">
                              <EmailShareButton
                                url={shareUrl}
                                subject="CureValue-compare facilities"
                              >
                                Email
                              </EmailShareButton>
                            </h6>
                          </div>
                          <div>
                            <h6 className="capitalize text-anchortext font-semibold text-cardpara border border-gray-400 rounded-[10px] cursor-pointer text-center p-[5px] ">
                              <FacebookShareButton url={shareUrl}>
                                Facebook
                              </FacebookShareButton>
                            </h6>
                          </div>
                          <div>
                            <h6 className="capitalize text-anchortext font-semibold text-cardpara border border-gray-400 rounded-[10px] cursor-pointer text-center p-[5px] ">
                              <WhatsappShareButton
                                url={shareUrl}
                                title="CureValue-compare facilities"
                              >
                                WhatsApp
                              </WhatsappShareButton>
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mt-[20px] overflow-y-auto">
            <h2 className="text-[24px] lg:text-[26px] font-semibold mb-[11px]">
              Compare facilities
            </h2>
            <div className="flex gap-[50px] mt-[80px] justify-start">
              {facilities.map((facility) => {
                return (
                  <FacilityCompareCard
                    key={facility._id}
                    facility={facility}
                    isComparePage={true}
                    isRemoveFavoriteEnabled={currentUser ? true : false}
                    onCloseClicked={(e) => {
                      onFacilityCloseClicked(facility);
                    }}
                  ></FacilityCompareCard>
                );
              })}
              {facilities.length != 4 && currentUser && (
                <div
                  onClick={() => {
                    setIsFavoritePopupOpen(true);
                  }}
                  className="flex flex-col items-center justify-center cursor-pointer border-[3px] border-borderclr hover:border-hoverclr rounded-[30px] px-[15px] pb-[19px] lg:px-[20px] lg:pb-[25px] bg-white relative destinationcard w-[300px] h-[350px] shrink-0"
                >
                  <div className="flex items-center justify-center border-[2px] border-solid border-pc rounded-full w-[50px] h-[50px]">
                    <FaMountainSun className="text-pc text-h2font" />
                  </div>
                  <div className="text-pfont font-medium pt-2">
                    Add destination
                  </div>
                </div>
              )}
            </div>

            <h2 className="text-[20px] lg:text-[20px] font-semibold mb-[11px] pt-[30px] flex items-center">
              Compare Details
              <IoIosInformationCircleOutline className="text-pc mx-[20px] text-h2font" />
            </h2>
            <div className="flex   gap-[50px] justify-start">
              {facilities.map((facility) => {
                return (
                  <FacilityCompareSlide
                    entity={facility}
                    key={Math.random()}
                  ></FacilityCompareSlide>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FacilityCompare;
