import { useEffect, useRef, useState } from "react";
import {
  FaRegArrowAltCircleLeft,
  FaRegArrowAltCircleRight,
} from "react-icons/fa";
import Slider from "react-slick";
import { CureValueService } from "../../../helpers/ServiceWrapper";
import {
  Facility,
  getTopRatesFacilities as getTopRatedFacilitiesService,
} from "../../modules/facility";
import CitySliderTitle from "../CitySliderTitle";
import Loader from "../shared/Loader";
import FacilityCard, { FacilityCardActionType } from "./FacilityCard";
import TreatmentSearchPopup from "../shared/TreatmentSearchPopup";
import { useTreatment } from "../../modules/treatment";
import { useNavigate } from "react-router-dom";
import { constructURLSegment } from "../../../helpers/CVHelper";
import { City } from "../../modules/city";

const settings = {
  arrows: true,
  dots: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerMode: false,
  initialSlide: 0,
  infinite: false,
  prevArrow: <FaRegArrowAltCircleLeft />,
  nextArrow: <FaRegArrowAltCircleRight />,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};
interface CityCarouselProps {
  contentId: string;
  handleShowSignUpPopup?: () => void;
}
const FacilityCarousel = ({
  contentId,
  handleShowSignUpPopup,
}: CityCarouselProps) => {
  const facilitySliderRef = useRef<any>(null);
  const [topFacilities, setTopFacilities] = useState<Facility[]>([]);
  const totalFacilityRecords = useRef(0);
  const [selectedFacility, setSelectedFacility] = useState<Facility>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [facilitySortBy, setFacilitySortBy] = useState<any>("CV Rating");
  const [isTreatmentModelOpen, setIsTreatmentModelOpen] = useState<any>(false);
  const { selectedTreatment, setSelectedTreatment } = useTreatment();
  const navigate = useNavigate();

  const sortingOptions = [
    {
      id: 1,
      sortBy: "CV Rating",
    },
    {
      id: 2,
      sortBy: "Distance",
    },
    {
      id: 3,
      sortBy: "Trust",
    },
    {
      id: 4,
      sortBy: "Quality",
    },
    // {
    //   id: 3,
    //   sortBy: "Cost",
    // },
  ];

  useEffect(() => {
    setTopFacilities([]);
    getTopRatedCities({ offset: 0, limit: 10, sort_by: facilitySortBy });
  }, [facilitySortBy]);
  const handlePrevClick = () => {
    if (facilitySliderRef.current) {
      facilitySliderRef.current.slickPrev();
    }
  };

  const handleNextClick = () => {
    if (facilitySliderRef.current) {
      facilitySliderRef.current.slickNext();
    }
  };
  const sliderChanged = (index: number) => {
    if (topFacilities.length - 3 == index) {
      getTopRatedCities(
        {
          offset: topFacilities.length + 1,
          limit: 10,
          sort_by: facilitySortBy,
        },
        false
      );
    }
  };

  const getTopRatedCities = async (
    payload: {
      offset: number;
      limit: number;
      sort_by: string;
    },
    isInitialLoad: boolean = true
  ) => {
    const { data, error } = await CureValueService(
      getTopRatedFacilitiesService,
      payload,
      { setIsLoading }
    );
    if (data) {
      totalFacilityRecords.current = data.totalCount;
      if (isInitialLoad) {
        setTopFacilities([...data.items]);
      } else {
        setTopFacilities([...topFacilities, ...data.items]);
      }
      // if (facilitySliderRef.current) {
      //   facilitySliderRef.current.slickPrev();
      // }
      setTimeout(() => {
        if (facilitySliderRef.current) {
          facilitySliderRef.current.slickPrev();
        }
      }, 1000);
    }
  };
  const handleCardClick = (
    action: FacilityCardActionType,
    facility: Facility
  ) => {
    setSelectedFacility(facility);
    setIsTreatmentModelOpen(true);
    // if (selectedTreatment) {
    //   navigate(
    //     `/location/${constructURLSegment(
    //       facility?.country_name ? facility.country_name : ""
    //     )}/${constructURLSegment(
    //       facility?.city_name ? facility.city_name : ""
    //     )}/${constructURLSegment(
    //       facility?.facility_name ? facility.facility_name : ""
    //     )}`
    //   );
    // } else {
    //   setIsTreatmentModelOpen(true);
    // }
  };
  const handleonClose = (selectedTreatmentFromPopup: any) => {
    if (selectedTreatmentFromPopup) {
      if (selectedFacility) {
        navigate(
          `/location/${constructURLSegment(
            selectedFacility?.country_name ? selectedFacility.country_name : ""
          )}/${constructURLSegment(
            selectedFacility?.city_name ? selectedFacility.city_name : ""
          )}/${constructURLSegment(
            selectedFacility?.facility_name
              ? selectedFacility.facility_name
              : ""
          )}?service=${constructURLSegment(
            selectedTreatmentFromPopup.treatment
          )}`
        );
      } else {
        navigate(
          `/citySelection?service=${constructURLSegment(
            selectedTreatmentFromPopup.treatment
          )}`
        );
      }
    }
    setIsTreatmentModelOpen(false);
  };

  const handleSelectedSort = (event: any) => {
    const sortName = event.target.value;
    setFacilitySortBy(sortName);
    // const sort = sortingOptions.find((sort: any) => sort.sortBy === sortName);
    // setSelectedSort(sort);
    // openFilter();
  };

  return (
    <>
      {isTreatmentModelOpen && (
        <TreatmentSearchPopup
          onClose={handleonClose}
          treatmentsToShow={
            selectedFacility?.treatments_offered
              ? selectedFacility.treatments_offered
              : undefined
          }
        ></TreatmentSearchPopup>
      )}
      <div className="flex flex-col lg:flex-row lg:items-center gap-[25px] lg:gap-[80px] min-h-fit lg:min-h-[690px]">
        <div className="w-[100%] lg:w-[40%]">
          <CitySliderTitle
            contentId={contentId}
            onClick={() => {
              setIsTreatmentModelOpen(true);
            }}
          />
          <div className="flex">
            <h2 className="pb-[10px] me-[15px] text-[16px] lg:text-[20px] font-semibold text-cardpara">
              Sort by
            </h2>
            <div className=" border-b border-borderclr mb-[21px] xl:w-[40%] lg:w-[50%] w-[40%]">
              {/* <h2 className="pb-[30px] text-[27px] font-semibold text-cardpara">
              Sort By
            </h2> */}
              <select
                className="font-semibold text-[16px] lg:text-[20px] ps-[10px] text-cardpara bg-transparent appearance-none cursor-pointer w-[100%] selectcity"
                style={{ outline: "none", border: "none" }}
                onChange={handleSelectedSort}
              >
                {sortingOptions.map((sort: any) => {
                  return (
                    <option key={sort.sortBy} value={sort.sortBy}>
                      {sort.sortBy}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="w-[100%] lg:w-[60%]">
          <div className="destination-slider">
            <Slider
              {...settings}
              ref={facilitySliderRef}
              afterChange={sliderChanged}
            >
              {topFacilities.map((facility: Facility) => {
                return (
                  <div key={facility._id}>
                    <FacilityCard
                      facility={facility}
                      onClick={(action) => {
                        handleCardClick(action, facility);
                      }}
                      handleShowSignUpPopup={handleShowSignUpPopup}
                    />
                  </div>
                );
              })}
              {isLoading && <Loader />}
            </Slider>
          </div>
          {topFacilities.length != 0 && (
            <div
              className={`slider-arrows flex items-center gap-[25px] 2xl:gap-[45px] pl-[20px] justify-start pt-[20px] 2xl:pt-[30px] ${
                isLoading ? "pointer-events-none" : "pointer-events-auto"
              }`}
            >
              <div className="prev-arrow" onClick={handlePrevClick}>
                <FaRegArrowAltCircleLeft
                  className={`${
                    isLoading ? "text-gray-400" : "text-cardpara"
                  } text-[38px] 2xl:text-[50px] cursor-pointer hover:text-hoverclr`}
                />
              </div>
              <div className="next-arrow" onClick={handleNextClick}>
                <FaRegArrowAltCircleRight
                  className={`${
                    isLoading ? "text-gray-400" : "text-cardpara"
                  } text-[38px] 2xl:text-[50px] cursor-pointer hover:text-hoverclr`}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default FacilityCarousel;
