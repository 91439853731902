import { useNavigate } from "react-router-dom";
import { useGetCMS } from "./cms/CMSContext";
const CitySliderTitle = (props: {
  contentId: string;
  onClick?: (event: any) => void;
}) => {
  const content = useGetCMS(props.contentId);
  const navigate = useNavigate();
  return (
    <div>
      <h2 className="text-[24px] lg:text-[36px] font-semibold mb-[11px] whitespace-nowrap">
        {content?.title}
      </h2>
      {/* <p className="text-anchortext lg:text-pfont mb-[15px] lg:mb-[8px]">
        {content?.subTitle}
      </p> */}
      {/* <button
        className="btn border border-pc bg-pc text-white rounded-full text-[17px] font-medium py-[10px]  px-[27px] hover:bg-white hover:text-pc"
        onClick={() => {
          if (props.onClick) {
            props.onClick("");
          } else {
            navigate(content?.ctaLink);
          }
        }}
      >
        {content?.ctaText}
      </button> */}
    </div>
  );
};

export default CitySliderTitle;
